import { Table } from "react-bootstrap";
import GenericModal from "../Dashboard-new/Modals/GenericModal";

const SerialNoList = ({ isModal, handleModalClose, data }) => {
  return (
    <>
      <GenericModal
        size="md"
        isModal={isModal}
        handleClose={handleModalClose}
        title="Serial No. List"
        body={AddSerialNoBody}
        data={data}
        modalInputflexBasis="100%"
        noBtn
      />
    </>
  );
};

export default SerialNoList;

function AddSerialNoBody(data) {
  // console.log(data);
  return (
    <>
      <Table>
        <thead>
          <tr className="text-center">
            <th>#</th>
            <th>Serial No.</th>
          </tr>
        </thead>
        <tbody>
          {data?.length > 0 ? (
            data.map((item, index) => {
              return (
                <tr key={index} className="stageAssets-row pRel text-center">
                  <td>{index + 1}</td>
                  <td>{item}</td>
                </tr>
              );
            })
          ) : (
            <tr className="text-center">
              <td colSpan={100}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}
