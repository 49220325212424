import React, { useEffect, useState } from 'react'
import Highcharts, { color } from "highcharts"
import { useDispatch, useSelector } from "react-redux";
import { analyticsData, getAnalytics } from '../../../state/redux/analytics/analyticsSlice';
import { goalAnalyticsData, getGoalAnalytics } from '../../../state/redux/goalAnalytics/goalAnalyticsSlice';
import GenericTable from "../../utils/GenericTable";
import { getAssetReport, selectAssetReportData } from "../../../state/redux/report/reportSlice";
import moment from "moment";
import { Form } from "react-bootstrap";
import CustomSelect from "../../utils/CustomSelect";
import * as JSC from "jscharting";

const Analytics = () => {
  const dispatch = useDispatch();
  let data = useSelector(analyticsData);
  let goalData = useSelector(goalAnalyticsData)
  const assetReport = useSelector(selectAssetReportData);
  const colorCodes = ['rgb(169, 209, 142)', 'rgb(255, 217, 102)', 'rgb(249, 123, 89)', 'rgb(250, 76, 93)', 'rgb(244, 176, 130)', 'rgb(255, 188, 117)', 'rgb(124, 181, 236)', 'rgb(64, 179, 162)', 'rgb(249, 123, 89)', 'rgb(255, 213, 142)', 'rgb(231, 66, 58)']
  useEffect(() => {
    dispatch(getGoalAnalytics())
    dispatch(getAnalytics(serachData));
    dispatch(getAssetReport(
      {
        downloadType: "json",
        limit: 10,
        lineId: "",
        page_no: 1,
        search: "",
        searchBy: ""
      }
    ))
  }, []);

  useEffect(() => {
    if (data) {
      const result = data && data.reduce((acc, curr) => {
        const next = curr.stages.map((stage) => {
          return stage.stageData?.avgTime ? Math.round(stage.stageData?.avgTime) : 0
        })
        acc.push(next);
        return acc
      }, []);
      const max_len = Math.max(0, ...result.map(_ => _.length)) || 0
      const result_chart = Array(max_len).fill(0)?.map((_, colIndex) => result.map(row => row[colIndex] || 0).reverse());

      let elemId = document.getElementById("container")
      if (elemId) {
        Highcharts.chart('container', {
          chart: {
            type: 'bar'
          },
          title: {
            text: ''
          },
          xAxis: {
            categories: data && data.map((item) => { return (item.line.name) }).reverse(),
            // categories: ['2','1'],
            title: {
              text: 'Lines'
            }
          },
          yAxis: {
            min: 0,
            title: {
              text: 'Time (mins)'
            }
          },
          legend: {
            reversed: true,
            enabled: false,
          },
          plotOptions: {
            series: {
              stacking: 'normal',
              dataLabels: {
                enabled: true
              }
            }
          },
          series:
            result_chart.map((item, ind) => {
              return (
                {
                  name: '',
                  data: item,
                  color: colorCodes[ind]
                }

              )
            })
        });
      }

    }


  }, [data])

  const reportData = assetReport && assetReport.map((obj, index) => {
    let tableData = [];
    let timeDiff = []

    for (let i = 0; i < obj.stages.length; i++) {
      var startDate = obj && obj.stages[i] && obj.stages[i].gatein ? obj.stages[i].gatein : ''
      var endDate = obj && obj.stages[i] && obj.stages[i].gateout ? obj.stages[i].gateout : ''

      const start = new Date(startDate).getTime();
      const end = new Date(endDate).getTime();

      const milliseconds = Math.abs(end - start).toString()

      const seconds = parseInt(milliseconds / 1000);
      const minutes = parseInt(seconds / 60);
      const hours = parseInt(minutes / 60);
      const days = parseInt(hours / 24);
      const time = (days === 0 || isNaN(days) ? '' : days + " days ") + ((hours % 24) === 0 || isNaN(hours % 24) ? '' : (hours % 24) + " hrs ") + ((minutes % 60) === 0 || isNaN(minutes % 60) ? '' : (minutes % 60) + " mins ");
      timeDiff.push(time)
    }

    if (index < 10) {
      tableData.push(
        index + 1,
        obj.assetName || '-',
        obj.lineName || "-",
        obj.status || "-",
        obj.mappedBy || "-",
        obj.mappedOn ? moment(obj.mappedOn).format("DD/MM/YYYY, h:mm a") : "-",

        obj.stages ? obj.stages.map((data, ind) => {
          return (
            <div className='pflexColumn'>
              <span className='TimeHeading'>{data.stage} - <span className='timeValue'>{timeDiff[ind] === '' ? 'NA' : timeDiff[ind]}</span></span>


            </div>
          )
        }) : ''
      );
    }
    else {
    }
    return tableData;



  });
  const [serachData, setSearcData] = useState({
    unit: "d",   //d for day and h for hours
    duration: "365"
  })

  const handleFormChange = (data) => {
    let temp = { ...serachData }
    temp.unit = data.value
    setSearcData(temp)
  }

  const handleFormSearch = (e) => {
    let temp = { ...serachData }
    temp.duration = e.target.value
    setSearcData(temp)
  }

  const applyFilter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(getAnalytics(serachData));
  }


  useEffect(() => {
    if (goalData) {
      for (let i = 0; i < goalData.length; i++) {
        for (let j = 0; j < goalData[i].stages.length; j++) {
          let remainingAsset = goalData[i].stages[j].stageData?.remainingGoalAssets
          let remainingHour = goalData[i].stages[j].stageData?.remainingWorkingHours < 0 ? 0 : goalData[i].stages[j].stageData?.remainingWorkingHours
          let remainingProductivity = (remainingAsset / remainingHour) === Infinity ? 0 : (remainingAsset / remainingHour)
          let tillDateProductivity = (goalData[i].stages[j].stageData?.assetCount) * (goalData[i].stages[j].stageData?.avgTime) / 60
          let totalProductivity = remainingProductivity
          JSC.chart(`container-${i}-${j}`, {
            debug: false,
            type: 'gauge ',
            legend_visible: true,
            // title: {
            //   label_text: 'ertyu',
            //   position: 'bottom'
            // },
            xAxis: {
              spacingPercentage: 0,
              orientation: 'opposite',
              line: { width: 2, color: '#d8d8d8' },
              defaultTick: {
                padding: 0,
                gridLine: { width: 0 },
                line: { length: 0 },
                label: { text: '' }
              },
              customTicks: [
                {
                  value: 4,
                  label_color: '#d81c36',
                  line_color: '#d81c36'

                }
              ]
            },
            yAxis: {
              line: { width: 2, color: '#d8d8d8' },
              defaultTick: {
                gridLine: { width: 0.5, dashStyle: 'dash' }
              },
              scale: { invert: true, range: [0, totalProductivity] }
            },
            series: [
              {
                type: 'column',
                angle: { start: 0, sweep: 360 },
                shape_innerSize: '20%',
                palette: 'default',
                name: '',
                points: [

                  // ["Previous Hour Average",goalData[i].stages[j].stageData && goalData[i].stages[j].stageData.prevAvg ? goalData[i].stages[j].stageData.prevAvg : 0],
                  // ["Per Hour Average",goalData[i].stages[j].stageData?.perHour],
                  // ["Target Average",goalData[i].stages[j].stageData?.targetAvg],
                  // ["Remaining Working Hours", goalData[i].stages[j].stageData?.remainingWorkingHours],


                  {
                    x: "Previous Date Productivity",
                    y: goalData[i].stages[j].stageData && goalData[i].stages[j].stageData.prevAvg ? goalData[i].stages[j].stageData.prevAvg : 0,
                    color:
                      (
                        ((goalData[i].stages[j].stageData && goalData[i].stages[j].stageData.prevAvg ? goalData[i].stages[j].stageData.prevAvg : 0) > (goalData[i].stages[j].stageData?.targetAvg)) && ((goalData[i].stages[j].stageData?.targetAvg) > (tillDateProductivity)) ? '#a9d18e' : '#fa4c5d'
                      )
                      ||
                      (
                        ((goalData[i].stages[j].stageData?.targetAvg) < (tillDateProductivity)) && ((goalData[i].stages[j].stageData?.targetAvg) < (goalData[i].stages[j].stageData && goalData[i].stages[j].stageData.prevAvg ? goalData[i].stages[j].stageData.prevAvg : 0)) ? '#a9d18e' : '#fa4c5d'
                      )

                  },
                  {
                    x: "Till Date Productivity",
                    y: tillDateProductivity,
                    color: (
                      ((goalData[i].stages[j].stageData?.targetAvg) < (tillDateProductivity)) && ((goalData[i].stages[j].stageData?.targetAvg) > (goalData[i].stages[j].stageData && goalData[i].stages[j].stageData.prevAvg ? goalData[i].stages[j].stageData.prevAvg : 0)) ? '#a9d18e' : '#fa4c5d'

                    )
                      ||
                      (
                        ((goalData[i].stages[j].stageData?.targetAvg) > (tillDateProductivity) && (goalData[i].stages[j].stageData?.targetAvg) > (goalData[i].stages[j].stageData && goalData[i].stages[j].stageData.prevAvg ? goalData[i].stages[j].stageData.prevAvg : 0)) ? '#fa4c5d' : '#a9d18e'
                      )
                  },
                  {
                    x: "Target Productivity",
                    y: goalData[i].stages[j].stageData?.targetAvg,
                    color: 'rgb(249, 123, 89);'
                  },
                  {
                    x: "Productivity To Be Achieved",
                    y: totalProductivity,
                    color: 'rgb(84, 79, 197)'
                  }
                ]
              },
            ],
            legend: {
              position: 'right'
            }
          },


          )
        }

      }
    }
  }, [goalData])





  return (
    <div className="adminDashboard outer-wrapper" style={{ paddingTop: '0.25em' }}>
      {goalData && goalData.length !== 0 ?
        <div className='goalDiv'>

          {goalData && goalData.map((item, index) => {

            return (
              <div className='goalMain'>

                <div className='goalTopRow'>
                  <div className='goalSno' >
                    {index + 1} .
                  </div>
                  <div className='goalLeft textCenter' style={{ width: '50%', border: 'none' }}>
                    <span className='goalHeading'>Line Name : </span>
                    <span className='goalVal1'>{item.line && item.line.name ? item.line.name : '-'}</span>
                  </div>
                  <div className='goalRight textCenter'>
                    <span className='goalHeading'>Details </span>
                    {/* <span className='goalVal'>{item.goalName ? item.goalName : '-'}</span> */}
                  </div>
                </div>



                {item && item.stages.map((data, ind) => {
                  let remainingGoalAssets = data && data.stageData && data.stageData.remainingGoalAssets ? data.stageData.remainingGoalAssets : ''
                  let remainingGoalHours = data && data.stageData && data.stageData.remainingWorkingHours ? data.stageData.remainingWorkingHours < 0 ? 0 : data.stageData.remainingWorkingHours : ''
                  let assetCount = data && data.stageData && data.stageData.assetCount ? data.stageData.assetCount : ''
                  let avgTime = data && data.stageData && data.stageData.avgTime ? data.stageData.avgTime : ''
                  let tillDateProductivity = (assetCount * avgTime) / 60

                  {/* let remainingHour = goalData[i].stages[j].stageData?.remainingWorkingHours < 0 ? 0 : goalData[i].stages[j].stageData?.remainingWorkingHours */ }
                  return (
                    <div className='goalRow'>
                      <div className='goalSno' style={{ borderLeft: '1px solid silver' }}>
                        <span className='blacSpan'>{data.name ? data.name : -''}</span>
                      </div>

                      <div className='goalLeft'
                        style={{ width: '50%', display: 'flex', alignItems: 'center', borderLeft: '1px solid silver' }}
                        id={`container-${index}-${ind}`}>
                      </div>

                      <div className='goalRight' style={{ borderLeft: '1px solid silver', borderRight: '1px solid silver' }}>
                        <div className='goalBox'>
                          <div className='goalBoxRow'>
                            <span className='goalSpan' >Total Assets :</span>
                            <span className='goalVal'>{data.stageData && data.stageData.totalAssets ? data.stageData.totalAssets : '-'}</span>


                          </div>
                          <div className='goalBoxRow'>
                            <span className='goalSpan' >Remaining Assets :</span>
                            <span className='goalVal'>{remainingGoalAssets}</span>
                          </div>
                          <div className='goalBoxRow'>
                            <span className='goalSpan' >Remaining Goal Hours :</span>
                            <span className='goalVal'>{remainingGoalHours}</span>
                          </div>
                          <div className='goalBoxRow'>
                            <span className='goalSpan' >Productivity To Be Achieved :</span>

                            <span className='goalVal'>{(remainingGoalAssets / remainingGoalHours) === Infinity ? 0 : (remainingGoalAssets / remainingGoalHours).toFixed(3)}</span>
                          </div>
                          <div className='goalBoxRow'>
                            <span className='goalSpan' >Target Productivity :</span>
                            <span className='goalVal'>{data.stageData && data.stageData.targetAvg ? data.stageData.targetAvg.toFixed(3) : '-'}</span>
                          </div>
                          <div className='goalBoxRow'>
                            <span className='goalSpan' > Till Date Productivity :</span>
                            <span className='goalVal'>{(tillDateProductivity).toFixed(3)}</span>
                          </div>
                          <div className='goalBoxRow'>
                            <span className='goalSpan' > Previous Date Productivity :</span>
                            <span className='goalVal'>{data.stageData && data.stageData.prevAvg ? data.stageData.prevAvg.toFixed(3) : '-'}</span>

                          </div>




                        </div>
                      </div>
                    </div>
                  )
                })}





              </div>
            )
          })}


        </div>
        : ''}


      {data && data.length ?
        <>
          <Form
            className="filterForm "
            // onSubmit={handleSubAssetFilterFormSubmit}
            style={{ margin: '1em 1em 1em 1em', border: 'none' }}
          >
            <Form.Group className="filterForm__input-wrapper">
              <Form.Label>Enter Search</Form.Label>
              <Form.Control
                placeholder="type to search..."
                aria-label="Search by master asset"
                aria-describedby="basic-addon2"
                type="number"
                // value={formData.search}
                name="search"
                onChange={(e) => handleFormSearch(e)}
              />
            </Form.Group>
            <Form.Group className="" controlId="formTagType">
              <Form.Label className="">Search By</Form.Label>
              <CustomSelect
                className="modal__form__select"
                options={[
                  {
                    value: "",
                    label: "--select--",
                  },
                  {
                    value: "d",
                    label: "Days",
                  },
                  {
                    value: "h",
                    label: "Hours",
                  },
                ]}
                name="searchBy"
                // value={{
                //   label:
                //     formData.searchBy === "name"
                //       ? "Master Asset"
                //       : formData.searchBy === "description"
                //         ? "Description"
                //         : "--select--",
                // }}
                onChange={handleFormChange}
              />
            </Form.Group>

            <button className="filterForm__submit-btn" onClick={applyFilter}>Apply</button>
          </Form>

          <div id="container" > </div>
        </>
        : ''}

      <GenericTable
        headings={[
          "S.No",
          "Master Asset",
          "Line",
          "Status",
          "Mapped By",
          "Mapped On",
          "Stage Time Difference",
        ]}
        data={reportData}
      />
    </div>

  )
}

export default Analytics
