import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../services/requests";
import { showToasts } from "../../../views/utils/toasts";

export const getLinesData = createAsyncThunk(
  "adminDashboard/getLinesData",
  async () => {
    const res = await post("/lines/get-linesdata", { preserveNull: false });
    return res;
  }
);
export const getStageData = createAsyncThunk(
  "adminDashboard/getStageData",
  async (data) => {
    const body = {
      lineId: data,
    };
    const res = await post("/lines/get-line", body);
    return res;
  }
);
export const getStageAssets = createAsyncThunk(
  "adminDashboard/getStageAssets",
  async (data) => {
    const body = {
      limit: 1000,
      page_no: 1,
      stageId: data.stageId,
      parentId: data.parentId,
      status: data.status,
    };
    const res = await post("/lines/get-stagedata", body);

    return res;
  }
);
export const getStageAssets1 = createAsyncThunk(
  "adminDashboard/getStageAssets1",
  async (data) => {
    const body = {
      limit: 1000,
      page_no: 1,
      stageId: data.stageId,
      parentId: data.parentId,
      status: data.status,
    };
    const res = await post("/lines/get-stagedata", body);

    return res;
  }
);
export const getStageAssetsCurrent = createAsyncThunk(
  "adminDashboard/getStageAssetscurrent",
  async (data) => {
    const body = {
      limit: 1000,
      page_no: 1,
      stageId: data.stageId,
      parentId: data.parentId,
      status: data.status,
    };
    const res = await post("/lines/get-stagedata", body);

    return res;
  }
);
export const getTemplateDetail = createAsyncThunk(
  "adminDashboard/getTemplateDetail",
  async (data) => {
    const body = {
      id: data,
    };
    const res = await post("/formbuilder/template_detail", body);

    return res;
  }
);
export const saveTemplateData = createAsyncThunk(
  "adminDashboard/saveTemplateData",
  async (data, { dispatch }) => {
    const body = {
      template_id: data.template_id,
      assetId: data.assetId,
      stageId: data.stageId,
      data: data.data,
    };
    const res = await post("/formbuilder/save_template_data", body);
    if (!res.error) dispatch(getLinesData());

    return res;
  }
);

export const adminDashboardSlice = createSlice({
  name: "adminDashboard",
  initialState: {
    linesData: { data: [], loading: false, error: "" },
    stageData: [],
    stageAssets: [],
    stageAssetsMissed: [],
    stageAssetsCurrent: [],
    templateDetail: {},
    saveTemplateData: "",
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getLinesData.fulfilled, (state, action) => {
      state.linesData.data = action.payload.data || [];
    });
    builder.addCase(getStageData.fulfilled, (state, action) => {
      state.stageData = action.payload.data || [];
    });
    builder.addCase(getStageAssets.fulfilled, (state, action) => {
      state.stageAssets = action.payload.data || [];
    });
    builder.addCase(getStageAssets1.fulfilled, (state, action) => {
      state.stageAssetsMissed = action.payload.data || [];
    });
    builder.addCase(getStageAssetsCurrent.fulfilled, (state, action) => {
      state.stageAssetsCurrent = action.payload.data || [];
    });
    builder.addCase(getTemplateDetail.fulfilled, (state, action) => {
      state.templateDetail = action.payload.data || {};
    });
    builder.addCase(saveTemplateData.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
  },
});

export const selectLinesData = (state) => state.adminDashboard.linesData.data;
export const selectStageAssets = (state) => state.adminDashboard.stageAssets;
export const missedStageAssets = (state) => state.adminDashboard.stageAssetsMissed;
export const currentStageCurrent = (state) => state.adminDashboard.stageAssetsCurrent;
export const selectStageData = (state) => state.adminDashboard.stageData;

export const selectTemplateDetail = (state) =>
  state.adminDashboard.templateDetail;

export default adminDashboardSlice.reducer;
