/**
 *    SPDX-License-Identifier: Apache-2.0
 */

import { configureStore } from "@reduxjs/toolkit";
import linesReducer from "./redux/lines/linesSlice";
import subLinesReducer from "./redux/subLines/subLinesSlice";
import stagesReducer from "./redux/stages/stagesSlice";
import lineManagerReducer from "./redux/lineManager/lineManagerSlice";
import assetSettingReducer from "./redux/assetSetting/assetSettingSlice";
import assetAllocationReducer from "./redux/assetAllocation/assetAllocationSlice";
import stageManagerReducer from "./redux/stageManager/stageManagerSlice";
import adminDashboardReducer from "./redux/adminDashboard/adminDashboardSlice";
import authReducer from "./redux/auth/authSlice";
import templateSettingReducer from "./redux/templateSetting/templateSettingSlice";
import reportReducer from "./redux/report/reportSlice";
import assetManagementReducer from "./redux/assetManagement/assetManagementSlice";
import assetMasterManagementReducer from "./redux/assetMasterManagement/assetMasterManagementSlice";
import analyticsReducer from "./redux/analytics/analyticsSlice"
import goalDataReducer from "./redux/goalAnalytics/goalAnalyticsSlice"
export default configureStore({
  reducer: {
    auth: authReducer,
    adminDashboard: adminDashboardReducer,
    lineManager: lineManagerReducer,
    stageManager: stageManagerReducer,
    assetSetting: assetSettingReducer,
    assetAllocation: assetAllocationReducer,
    lines: linesReducer,
    subLines: subLinesReducer,
    stages: stagesReducer,
    templateSetting: templateSettingReducer,
    report: reportReducer,
    assetManagement: assetManagementReducer,
    analytics : analyticsReducer,
    goalAnalytics : goalDataReducer

    // assetMasterManagement: assetMasterManagementReducer

  },
});
