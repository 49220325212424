import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import CustomSelect from "../../utils/CustomSelect";
import ShowHidePassword from "../../utils/ShowHidePassword";

const schema = z.object({
  userType: z
    .object({
      label: z.string(),
      value: z.string(),
    })
    .or(z.string().min(1, { message: "This field is required" })),
  firstName: z
    .string()
    .min(3, { message: "Must be at least 3 characters long" })
    .refine((value) => /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/i.test(value), {
      message: "Only alpha-numeric characters and underscore  are allowed",
    })
    .refine((value) => /^[^\s]/.test(value), {
      message: "Remove blank spaces at the start",
    })
    .refine((value) => /[^\s]$/.test(value), {
      message: "Remove blank spaces at the end",
    }),
  purchase: z.string(),
  capitalizedOn: z.string(),
});

const UserManagement = () => {
  return (
    <>
      <div className="userManagement outer-wrapper">
        <div className="userManagement-header">
          <h2 className="">User Management</h2>
          <div className="user-type">
            <Form.Group className="input-group " controlId="formUserType">
              <Form.Label className=""> User Type :</Form.Label>
              <CustomSelect
                // isValid={data.isLineSelectValid}
                required
                className="modal__form__select"
                options={[
                  {
                    value: "",
                    label: "--select line--",
                    isDisabled: true,
                  },
                  ...userTypes,
                ]}
                name="userType"
                // defaultValue={{ label: data.lineId }}
                // onChange={fns.onChange}
              />
            </Form.Group>
          </div>
        </div>
        <div className="user-form-container">
          <Form
            // noValidate
            // validated={data.isFormValidated}
            className="userManagement__form"
            // onSubmit={fns.onSubmit}
          >
            <div className="user-form-inputs">
              <Form.Group className="input-group " controlId="formFirstName">
                <Form.Label className="required">First Name :</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter name"
                  minLength="60"
                  name="firstName"
                />
                <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="input-group " controlId="formLastName">
                <Form.Label className="required">Last Name :</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter name"
                  minLength="60"
                  name="lastName"
                />
                <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="input-group " controlId="formUserName">
                <Form.Label className="required">Username :</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter name"
                  minLength="60"
                  name="userName"
                />
                <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="input-group pRel" controlId="formPassword">
                <Form.Label className="required">Password :</Form.Label>
                <ShowHidePassword
                  // value={data.password}
                  name="password"
                  // onChange={fns.onChange}
                  placeholder="Enter password"
                  minLength="8"
                  top="8px"
                />
              </Form.Group>
              <Form.Group
                className="input-group pRel"
                controlId="formConfirmPassword"
              >
                <Form.Label className="required">Confirm Password :</Form.Label>
                <ShowHidePassword
                  // value={data.confirmPassword}
                  name="confirmPassword"
                  // onChange={fns.onChange}
                  placeholder="confirm password"
                  minLength="30"
                  top="8px"
                />
              </Form.Group>
              <Form.Group className="input-group " controlId="formEmail">
                <Form.Label className="required">Email :</Form.Label>
                <Form.Control
                  required
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  minLength="60"
                  // value={data.email}
                  // onChange={fns.onChange}
                />
                <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="input-group " controlId="formMobile">
                <Form.Label className="required">Mobile No :</Form.Label>
                <Form.Control
                  required
                  type="tel"
                  placeholder="Enter email"
                  name="mobile"
                  minLength="60"
                  // value={data.email}
                  // onChange={fns.onChange}
                />
                <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="input-group " controlId="formEmpCode">
                <Form.Label className="required">Employee Code :</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter name"
                  minLength="60"
                  name="employeeCode"
                />
                <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="input-group " controlId="formSiteAddress">
                <Form.Label className="required">Site Address :</Form.Label>
                <Form.Control
                  required
                  type="text"
                  placeholder="Enter name"
                  minLength="60"
                  name="siteAddress"
                />
                <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="input-group " controlId="formPlant">
                <Form.Label className="required"> Plant :</Form.Label>
                <CustomSelect
                  // isValid={data.isLineSelectValid}
                  required
                  className="modal__form__select"
                  options={[
                    {
                      value: "",
                      label: "--select line--",
                      isDisabled: true,
                    },
                    //   ...data.lineOptions,
                  ]}
                  name="lineId"
                  // defaultValue={{ label: data.lineId }}
                  // onChange={fns.onChange}
                />
              </Form.Group>
            </div>

            <div className="line-break"></div>

            <div className="user-permissions">
              <div className="user-permissions__header">
                <h6 className="clr-accent">Select Privileges :</h6>
                <Form.Check type="checkbox" label="Select All" />
              </div>
              <div className="user-permissions__body">
                {userPermissions.map(({ label }, index) => {
                  return (
                    <Form.Check key={index} type="checkbox" label={label} />
                  );
                })}
              </div>
            </div>

            <div className="line-break"></div>

            <div className="btn-wrapper">
              <button className="form-submit-btn">Save</button>
              <button className="form-cancel-btn">Cancel</button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};
export default UserManagement;

var userTypes = [
  {
    label: "Asset Manager",
    value: "assetManager",
  },
  {
    label: "Line Manager",
    value: "lineManager",
  },
  {
    label: "Stage Manager",
    value: "stageManager",
  },
];
var userPermissions = [
  {
    label: "User Registration",
  },
  {
    label: "Add Assets",
  },
  {
    label: "Update Assets",
  },
  {
    label: "Remove Assets",
  },
  {
    label: "Transfer Assets",
  },
  {
    label: "Reports",
  },
  {
    label: "Data Import/Export",
  },
  {
    label: "Database/Table",
  },
  {
    label: "Scan",
  },
  {
    label: "Asset Scrapping",
  },
];
