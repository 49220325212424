import { Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { useDispatch, useSelector } from "react-redux";
import { useController } from "react-hook-form";

import {
  addAssetMaster,
  updateAssetMaster,
  selectAssetFormTitle,
  selectAssetMasterData,
  selectUpdateAssetId,
  setIsForm,
  setUpdateAssetId,
} from "../../../../../../state/redux/assetManagement/assetManagementSlice";
import CustomSelect from "../../../../../utils/CustomSelect";
import GenericSelect from "../../../../../utils/GenericSelect";
import {
  getMaster,
  selectMasterData,
} from "../../../../../../state/redux/assetManagement/assetManagementSlice";
import { useEffect } from "react";
import moment from "moment/moment";

const schema = z.object({
  assetType: z
    .object({
      value: z.string(),
    })
    .or(z.string().min(1, { message: "This field is required" })),
  assetPrimaryCode: z
    .string()
    .min(3, { message: "Must be at least 3 characters long" })
    .refine((value) => /^[^\s]/.test(value), {
      message: "Remove blank spaces at the start",
    })
    .refine((value) => /[^\s]$/.test(value), {
      message: "Remove blank spaces at the end",
    })
    .refine((value) => /^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/i.test(value), {
      message: "Only alpha-numeric characters and underscore  are allowed",
    }),
  purchase: z.string(),

  capitalizedOn: z.string(),
});

const AssetForm = () => {
  const dispatch = useDispatch();
  const formTitle = useSelector(selectAssetFormTitle);
  const masterData = useSelector(selectMasterData);
  const assetsData = useSelector(selectAssetMasterData);
  const updateAssetId = useSelector(selectUpdateAssetId);
  const updateAssetObj = updateAssetId
    ? assetsData.filter((obj) => obj.asset_Id === updateAssetId)[0]
    : null;
  // const {register: register, handleSubmit: handleAddSubmit} = useForm();

  // const handleAdd = (data)=>{
  //   console.log(data);

  //  }
  // function handleCancel() {
  //   dispatch(setIsForm(false));
  // }

  const masterDropdownData = masterData.map((obj, index) => {
    return {
      _id: obj._id,
      label: obj.value,
      value: obj.value,
      type: obj.type,
    };
  });

  useEffect(() => {
    dispatch(getMaster());
  }, []);

  var assetTypeOptions = [
    {
      label: "Assets Computer Software",
      value: "assets_computer_software",
    },
    {
      label: "Assets Plants & Equipment",
      value: "assets_plants_equipment",
    },
    {
      label: "Assets Vehicle",
      value: "assets_vehicle",
    },
  ];

  const values = null; //get api data here
  const defaultValues = {
    assetType: updateAssetObj
      ? { label: updateAssetObj.asset_Type, value: updateAssetObj.asset_Type }
      : "",
    assetPrimaryCode: updateAssetObj ? updateAssetObj.asset_Primary_Code : "",
    assetTagId: updateAssetObj ? updateAssetObj.asset_Id : "",
    financeAsset: updateAssetObj ? updateAssetObj.asset_Finance : "",
    sbu: updateAssetObj ? updateAssetObj.sbu : "",
    assetClass1: updateAssetObj ? updateAssetObj.asset_Class_1 : "",
    assetClass2: updateAssetObj ? updateAssetObj.asset_Class_2 : "",
    assetSubClass: updateAssetObj ? updateAssetObj.asset_Sub_Class : "",
    department: updateAssetObj
      ? { label: updateAssetObj.department, value: updateAssetObj.department }
      : "",
    plant: updateAssetObj
      ? { label: updateAssetObj.plant, value: updateAssetObj.plant }
      : "",
    make_oem: updateAssetObj ? updateAssetObj.oem : "",
    model: updateAssetObj ? updateAssetObj.model : "",
    deviceNumber: updateAssetObj ? updateAssetObj.device_Number : "",
    status: updateAssetObj ? updateAssetObj.status : "",
    location: updateAssetObj
      ? { label: updateAssetObj.location, value: updateAssetObj.location }
      : "",
    vendor: updateAssetObj ? updateAssetObj.vendor : "",
    quantity: updateAssetObj ? updateAssetObj.quantity : 0,
    itAssetCode: updateAssetObj ? updateAssetObj.asset_It_Code : "",
    costCenter: updateAssetObj ? updateAssetObj.cost_Centre : "",
    userName: updateAssetObj ? updateAssetObj.userName : "",
    poNumber: updateAssetObj ? updateAssetObj.po_Number : "",
    description: updateAssetObj ? updateAssetObj.description : "",
    assetStatus: updateAssetObj
      ? {
          label: updateAssetObj.asset_Status,
          value: updateAssetObj.asset_Status,
        }
      : "",
    purchase: updateAssetObj
      ? moment(updateAssetObj.purchase_Date).format("yyyy-MM-DD")
      : "",
    capitalizedOn: updateAssetObj
      ? moment(updateAssetObj.capitalized_Date).format("yyyy-MM-DD")
      : "",
  };

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues,
    // values,
    resolver: zodResolver(schema),
  });
  // 155
  // console.log("errors", errors);
  // console.log("getValues", getValues());

  function handleCancel() {
    dispatch(setIsForm(false));
    dispatch(setUpdateAssetId(null));
  }
  function handleAssetFormSubmit(formValues) {
    // console.log(getValues());
    if (updateAssetId) {
      dispatch(updateAssetMaster(getValues(), { dispatch }));
    } else {
      dispatch(addAssetMaster(getValues(), { dispatch }));
    }
  }

  return (
    <div className="assetForm">
      <div className="assetForm-header">
        <h2 className="clr-accent a-self-center ">{formTitle}</h2>
        <div className="asset-type">
          <Form.Group className="input-group " controlId="formAssetType">
            <Form.Label className="required"> Asset Type :</Form.Label>

            <Controller
              name="assetType"
              render={({ field }) => {
                return (
                  <GenericSelect
                    className="modal__form__select"
                    field={field}
                    options={masterDropdownData.filter(
                      (v) => v.type == "asset_type"
                    )}
                    defaultValue={"equipement"}
                  />
                );
              }}
              control={control}
            />
            {errors.assetType ? (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                {errors.assetType.message}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
          <Form.Group className="input-group " controlId="formAssetCode">
            <Form.Label className="required">Asset Primary Code :</Form.Label>
            <Controller
              name="assetPrimaryCode"
              render={({ field }) => {
                return (
                  <Form.Control
                    type="text"
                    placeholder="asset primary code"
                    {...field}
                  />
                );
              }}
              control={control}
            />
            {errors.assetPrimaryCode ? (
              <Form.Control.Feedback
                type="invalid"
                style={{ display: "block" }}
              >
                {errors.assetPrimaryCode.message}
              </Form.Control.Feedback>
            ) : null}
          </Form.Group>
        </div>
      </div>
      <div className="asset-form-container">
        <Form
          className="assetManagement__form"
          onSubmit={handleSubmit(handleAssetFormSubmit)}
        >
          <div className="asset-form-inputs">
            <Form.Group className="input-group " controlId="formAssetTagId">
              <Form.Label className="required">Assets Tag ID :</Form.Label>
              <Controller
                name="assetTagId"
                render={({ field }) => {
                  return (
                    <Form.Control
                      type="text"
                      placeholder="Asset Tag ID"
                      {...field}
                    />
                  );
                }}
                control={control}
              />
              {errors.assetTagId ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.assetTagId.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formFinanceAsset">
              <Form.Label className="required">Finance Asset :</Form.Label>
              <Controller
                name="financeAsset"
                render={({ field }) => {
                  return (
                    <Form.Control
                      type="text"
                      placeholder="Financial Asset"
                      {...field}
                    />
                  );
                }}
                control={control}
              />
              {errors.financeAsset ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.financeAsset.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formSBU">
              <Form.Label className="required">SBU :</Form.Label>
              <Controller
                name="sbu"
                render={({ field }) => {
                  return (
                    <Form.Control type="text" placeholder="SBU" {...field} />
                  );
                }}
                control={control}
              />
              {errors.sbu ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.sbu.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formAssetClass1">
              <Form.Label className="required">Assets Class 1 :</Form.Label>
              <Controller
                name="assetClass1"
                render={({ field }) => {
                  return (
                    <Form.Control
                      type="text"
                      placeholder="Asset Class 1"
                      {...field}
                    />
                  );
                }}
                control={control}
              />
              {errors.assetClass1 ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.assetClass1.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formAssetClass2">
              <Form.Label className="required">Assets Class 2 :</Form.Label>
              <Controller
                name="assetClass2"
                render={({ field }) => {
                  return (
                    <Form.Control
                      type="text"
                      placeholder="Asset Class 2"
                      {...field}
                    />
                  );
                }}
                control={control}
              />
              {errors.assetClass2 ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.assetClass2.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formAssetSubClass">
              <Form.Label className="required">Assets Sub Class :</Form.Label>
              <Controller
                name="assetSubClass"
                render={({ field }) => {
                  return (
                    <Form.Control
                      type="text"
                      placeholder="Asset Sub-class"
                      {...field}
                    />
                  );
                }}
                control={control}
              />
              {errors.assetSubClass ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.assetSubClass.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formDept">
              <Form.Label className="required"> Department :</Form.Label>
              <Controller
                name="department"
                render={({ field }) => {
                  return (
                    <GenericSelect
                      className="modal__form__select"
                      field={field}
                      options={masterDropdownData.filter(
                        (v) => v.type == "department"
                      )}
                    />
                  );
                }}
                control={control}
              />
              {errors.department ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.department.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formPlant">
              <Form.Label className="required"> Plant :</Form.Label>
              <Controller
                name="plant"
                render={({ field }) => {
                  return (
                    <GenericSelect
                      className="modal__form__select"
                      field={field}
                      options={masterDropdownData.filter(
                        (v) => v.type == "plant"
                      )}
                    />
                  );
                }}
                control={control}
              />
              {errors.plant ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.plant.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formMakeOem">
              <Form.Label className="required">Make/OEM :</Form.Label>
              <Controller
                name="make_oem"
                render={({ field }) => {
                  return (
                    <Form.Control
                      type="text"
                      placeholder="Make/OEM"
                      {...field}
                    />
                  );
                }}
                control={control}
              />
              {errors.make_oem ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.make_oem.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formModel">
              <Form.Label className="required">Model :</Form.Label>
              <Controller
                name="model"
                render={({ field }) => {
                  return (
                    <Form.Control type="text" placeholder="Model" {...field} />
                  );
                }}
                control={control}
              />
              {errors.model ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.model.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formDeviceNo">
              <Form.Label className="required">Device Serial No :</Form.Label>
              <Controller
                name="deviceNumber"
                render={({ field }) => {
                  return (
                    <Form.Control
                      type="text"
                      placeholder="Device Serial No"
                      {...field}
                    />
                  );
                }}
                control={control}
              />
              {errors.deviceNo ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.deviceNo.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formStatus">
              <Form.Label className="required">Status :</Form.Label>
              <Controller
                name="status"
                render={({ field }) => {
                  return (
                    <Form.Control type="text" placeholder="Status" {...field} />
                  );
                }}
                control={control}
              />
              {errors.status ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.status.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formLocation">
              <Form.Label className="required"> Location :</Form.Label>
              <Controller
                name="location"
                render={({ field }) => {
                  return (
                    <GenericSelect
                      className="modal__form__select"
                      field={field}
                      options={masterDropdownData.filter(
                        (v) => v.type == "location"
                      )}
                    />
                  );
                }}
                control={control}
              />
              {errors.location ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.location.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formVendor">
              <Form.Label className="required">Vendor :</Form.Label>
              <Controller
                name="vendor"
                render={({ field }) => {
                  return (
                    <Form.Control type="text" placeholder="Vendor" {...field} />
                  );
                }}
                control={control}
              />
              {errors.vendor ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.vendor.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formQuantity">
              <Form.Label className="required">Quantity :</Form.Label>
              <Controller
                name="quantity"
                render={({ field }) => {
                  return (
                    <Form.Control
                      type="text"
                      placeholder="Quantity"
                      {...field}
                    />
                  );
                }}
                control={control}
              />
              {errors.quantity ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.quantity.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formITAssetCode">
              <Form.Label className="required">IT Asset Code :</Form.Label>
              <Controller
                name="itAssetCode"
                render={({ field }) => {
                  return (
                    <Form.Control
                      type="text"
                      placeholder="IT Asset Code"
                      {...field}
                    />
                  );
                }}
                control={control}
              />
              {errors.itAssetCode ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.itAssetCode.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formCostCenter">
              <Form.Label className="required">Cost Center :</Form.Label>
              <Controller
                name="costCenter"
                render={({ field }) => {
                  return (
                    <Form.Control
                      type="text"
                      placeholder="Cost Center"
                      {...field}
                    />
                  );
                }}
                control={control}
              />
              {errors.costCenter ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.costCenter.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formUserName">
              <Form.Label className="required">Username :</Form.Label>
              <Controller
                name="userName"
                render={({ field }) => {
                  return (
                    <Form.Control
                      type="text"
                      placeholder="Username"
                      {...field}
                    />
                  );
                }}
                control={control}
              />
              {errors.username ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.username.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formPONumber">
              <Form.Label className="required">PO Number :</Form.Label>
              <Controller
                name="poNumber"
                render={({ field }) => {
                  return (
                    <Form.Control
                      type="text"
                      placeholder="PO Number"
                      {...field}
                    />
                  );
                }}
                control={control}
              />
              {errors.poNumber ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.poNumber.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formDescription">
              <Form.Label className="required">Description :</Form.Label>
              <Controller
                name="description"
                render={({ field }) => {
                  return (
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      {...field}
                    />
                  );
                }}
                control={control}
              />
              {errors.description ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.description.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formAssetStatus">
              <Form.Label className="required">Asset Status :</Form.Label>
              <Controller
                name="assetStatus"
                render={({ field }) => {
                  return (
                    <GenericSelect
                      className="modal__form__select"
                      field={field}
                      options={masterDropdownData.filter(
                        (v) => v.type == "asset_status"
                      )}
                    />
                  );
                }}
                control={control}
              />
              {errors.assetStatus ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.assetStatus.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </div>

          <div className="line-break"></div>

          <div className="asset-form-inputs-snd">
            <Form.Group className="input-group " controlId="formPurchase">
              <Form.Label className="required">
                Month & Year Of Purchase :
              </Form.Label>
              <Controller
                name="purchase"
                render={({ field }) => {
                  return <Form.Control type="date" {...field} />;
                }}
                control={control}
              />
              {errors.purchase ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.purchase.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
            <Form.Group className="input-group " controlId="formCapitalized">
              <Form.Label className="required">Capitalized On :</Form.Label>
              <Controller
                name="capitalizedOn"
                render={({ field }) => {
                  return <Form.Control type="date" {...field} />;
                }}
                control={control}
              />
              {errors.capitalizedOn ? (
                <Form.Control.Feedback
                  type="invalid"
                  style={{ display: "block" }}
                >
                  {errors.capitalizedOn.message}
                </Form.Control.Feedback>
              ) : null}
            </Form.Group>
          </div>

          <div className="line-break"></div>

          <div className="btn-wrapper">
            <button className="form-submit-btn" type="submit">
              Save
            </button>
            <button
              type="button"
              className="form-cancel-btn"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};
export default AssetForm;

var assetTypeOptions = [
  { value: "", label: "--select asset type--", isDisabled: true },
  { label: "Assets Computer Software", value: "assets_computer_software" },
  { label: "Assets Plants & Equipment", value: "assets_plants_equipment" },
  { label: "Assets Vehicle", value: "assets_vehicle" },
];

var deptOptions = [
  { label: "--select--", value: "", isDisabled: true },
  {
    label: "Department A",
    value: "Department A",
  },
];
var plantOptions = [
  { label: "--select--", value: "", isDisabled: true },
  {
    label: "Plant A",
    value: "Plant A",
  },
];

var locationOptions = [
  { label: "--select--", value: "", isDisabled: true },
  {
    label: "Jaipur",
    value: "jaipur",
  },
];

var assetStatusOptions = [
  { label: "--select--", value: "", isDisabled: true },
  {
    label: "some status",
    value: "dummy",
  },
];
// for updating the fields either propndrill or get values form index passed in mapping{}
