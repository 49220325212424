import { useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getTags } from "../../../../../../../state/redux/assetSetting/assetSettingSlice";
import { initFetchParams } from "../../../../../../../state/redux/util";
import CustomSelect from "../../../../../../utils/CustomSelect";

const initialFormData = {
  search: "",
  searchBy: "",
  status: "",
  tagType: "",
};
const TagFilter = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialFormData);
  const handleFormChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;
    setFormData((fd) => ({ ...fd, [name]: value }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log(filterData.search);
    dispatch(getTags({ ...initFetchParams, ...formData }));
  };
  return (
    <>
      <Form className="filterForm " onSubmit={handleFormSubmit}>
        <Form.Group className="" controlId="formTagType">
          <Form.Label className="">Search By</Form.Label>
          <CustomSelect
            className="modal__form__select"
            options={[
              {
                value: "",
                label: "--select--",
              },
              {
                value: "tagNo",
                label: "Tag Name",
              },
              {
                value: "tagId",
                label: "Tag ID",
              },
            ]}
            name="searchBy"
            // defaultValue={data.lineId}
            onChange={handleFormChange}
          />
        </Form.Group>
        <Form.Group className="filterForm__input-wrapper">
          <Form.Label>Enter Search</Form.Label>
          <Form.Control
            placeholder="type to search..."
            aria-label="Search by master asset"
            aria-describedby="basic-addon2"
            type="text"
            value={formData.search}
            name="search"
            onChange={handleFormChange}
          />
        </Form.Group>
        <Form.Group className="" controlId="formTagType">
          <Form.Label className="">Filter by Type</Form.Label>
          <CustomSelect
            className="modal__form__select"
            options={[
              {
                value: "",
                label: "--select line--",
              },
              {
                value: "Active",
                label: "Active RFID",
              },
              {
                value: "Passive",
                label: "Passive RFID",
              },
              {
                value: "Barcode",
                label: "Barcode",
              }
            ]}
            name="tagType"
            onChange={handleFormChange}
          />
        </Form.Group>
        <Form.Group className="" controlId="formTagStatus">
          <Form.Label className="">Filter by Status</Form.Label>
          <CustomSelect
            className="modal__form__select"
            options={[
              {
                value: "",
                label: "--select line--",
              },
              {
                value: "Allocated",
                label: "Allocated",
              },
              {
                value: "Pending",
                label: "Pending",
              },
            ]}
            name="status"
            onChange={handleFormChange}
          />
        </Form.Group>
        <button className="filterForm__submit-btn">Apply</button>
      </Form>
    </>
  );
};

export default TagFilter;
