import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";
import { AiFillPlusSquare } from "react-icons/ai";

import { validate_form } from "../../../../../../../services/validation";
import { addTag } from "../../../../../../../state/redux/assetSetting/assetSettingSlice";

import GenericModal from "../../../../../Modals/GenericModal";
import CustomSelect from "../../../../../../utils/CustomSelect";

const initialTagFormData = {
  name: "",
  id: "",
  tagType: "",
  isFormValidated: false,
  isTagTypeSelectValid: false,
  errors: {
    name: "",
    id: "",
  },
};

const TagPanel = ({ isAddTag }) => {
  const dispatch = useDispatch();
  const [tagFormData, setTagFormData] = useState(initialTagFormData);

  const [isTagModal, setIsTagModal] = useState(false);
  const handleModalClose = () => {
    setTagFormData(initialTagFormData);
    setIsTagModal(false);
  };
  const handleTagDataChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;
    const tempErrors = { ...tagFormData.errors };
    const errors = validate_form({
      tempErrors,
      name,
      value,
    });
    if (name === "tagType") {
      setTagFormData((td) => ({
        ...td,
        isTagTypeSelectValid: td.isFormValidated ? true : null,
      }));
    }
    setTagFormData((td) => ({
      ...td,
      [name]: value,
      errors,
    }));
  };
  const handleTagFormSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      try {
        dispatch(addTag(tagFormData));
        handleModalClose();
      } catch (err) {}
    } else
      setTagFormData((td) => ({
        ...td,
        isFormValidated: true,
        isTagTypeSelectValid: !!td.tagType,
      }));
  };

  return (
    <>
      {isAddTag ? (
        <button className="panel-btn" onClick={() => setIsTagModal(true)}>
          Add Tag
          <AiFillPlusSquare className="icons panel__icon" />
        </button>
      ) : null}

      {isTagModal ? (
        <GenericModal
          size="md"
          isModal={isTagModal}
          handleClose={handleModalClose}
          title="Add New Tag"
          body={addTagBody}
          data={tagFormData}
          modalInputflexBasis="50%"
          fns={{
            onChange: handleTagDataChange,
            onSubmit: handleTagFormSubmit,
          }}
        />
      ) : null}
    </>
  );
};

export default TagPanel;

function addTagBody(data, fns) {
  return (
    <Form
      className="modal__form"
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      onSubmit={fns.onSubmit}
    >
      <Form.Group className="mb-3" controlId="formTagType">
        <Form.Label className="required"> Select Tag Type</Form.Label>
        <CustomSelect
          isValid={data.isTagTypeSelectValid}
          required
          className="modal__form__select"
          options={[
            {
              value: "",
              label: "--select line--",
              isDisabled: true,
            },
            {
              value: "Active",
              label: "Active RFID",
            },
            {
              value: "Passive",
              label: "Passive RFID",
            },
            {
              value: "Barcode",
              label: "Barcode",
            },
            // {
            //   value: "NFC",
            //   label: "NFC",
            // },
          ]}
          name="tagType"
          // defaultValue={data.lineId}
          onChange={fns.onChange}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formName">
        <Form.Label className="required">Enter Tag Display Name</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter tag display name"
          name="name"
          value={data.name}
          onChange={fns.onChange}
        />
        <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        {data.errors.name ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.name}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please enter tag name!
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formId">
        <Form.Label className="required">Enter Tag ID</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter tag id"
          name="id"
          minLength="60"
          value={data.id}
          onChange={fns.onChange}
        />
        <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        {data.errors.id ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.id}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please enter tag ID
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Form>
  );
}
