import { useSelector } from "react-redux";
import moment from "moment/moment";
import {
  AiFillEye,
  AiFillEdit,
  AiFillDelete,
  AiFillPlusSquare,
} from "react-icons/ai";

import GenericTable from "../../../../utils/GenericTable";
import {
  selectStageManagers,
  getStageManagers,
  selectStageManagersCount,
  selectIsStageManPagination,
} from "../../../../../state/redux/stageManager/stageManagerSlice";
import CustomPagination from "../../../../utils/CustomPagination";

const StageManagerTable = () => {
  const stageManagers = useSelector(selectStageManagers);
  const stageManagerCount = useSelector(selectStageManagersCount);
  const isPagination = useSelector(selectIsStageManPagination);

  const stageManagerTableData = stageManagers.map((obj, index) => {
    let objArr = [];
    objArr.push(
      index + 1,
      obj.name,
      obj.lines[0].lineType === "line" ? obj.lines[0].name : "NA", //line name
      obj.lines[1].lineType === "subLine" ? obj.lines[1].name : "NA", // subline name
      obj.lines[2]?.lineType === "stage"
        ? obj.lines[2].name
        : obj.lines[1].lineType === "stage"
        ? obj.lines[1].name
        : "NA", //stage name
      obj.email,
      obj.status,
      moment(obj.addedOn).format("DD/MM/YYYY, h:mm a")
      // [
      //   <div className="action-icons">
      //     <AiFillEye className="view-icon icons" />
      //     {/* <AiFillEdit
      //           className="edit-icon icons"
      //           id={obj._id}
      //           onClick={handleEdit}
      //         /> */}
      //     <AiFillDelete className="delete-icon icons" />
      //   </div>,
      // ]
    );
    return objArr;
  });
  return (
    <>
      <GenericTable
        headings={[
          "S.No",
          "Name",
          "Line",
          "SubLine",
          "Stage",
          "Email",
          "Status",
          "Added On",
          // "Action",
        ]}
        data={stageManagerTableData}
      />
      <CustomPagination
        totalCount={stageManagerCount}
        isPagination={isPagination}
        fetchData={getStageManagers}
      />
    </>
  );
};
export default StageManagerTable;
