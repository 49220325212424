import { Table } from "react-bootstrap";

const GenericTable = ({ headings, data }) => {
  return (
    <div className="generic-table-wrapper ">
      <Table className="generic-table">
        <thead className="bg-accent">
          <tr>
            {headings.map((heading, index) => (
              <th key={index}>{heading}</th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-gray-100">
          {data.length > 0 ? (
            data.map((row, rIndex) => {
              return (
                <tr key={rIndex}>
                  {row.map((cell, c_index) => (
                    <td
                      key={c_index}
                      className={`${
                        c_index === row.length - 1 ? "action" : ""
                      }`}
                    >
                      {cell}
                    </td>
                  ))}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={100}>No Data Found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default GenericTable;
