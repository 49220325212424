import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { BiReset, BiSearch } from "react-icons/bi";
import { getStageManagers } from "../../../state/redux/stageManager/stageManagerSlice";

import { getUserData } from "../../../services/functions";
import { initFetchParams } from "../../../state/redux/util";
import history from "../../../services/history";

import StageManagerPanel from "./components/StageManagerPanel/index";
import StageManagerTable from "./components/StageManagerTable/index";

const StageManager = () => {
  const dispatch = useDispatch();
  const { permissions } = getUserData();
  useEffect(() => {
    const fetchData = async () => {
      dispatch(getStageManagers(initFetchParams));
    };
    if (permissions.addStageManager) fetchData();
    else history.push("/dashboard");
  }, []);


  return (
    <>

      <div className="stage-manager outer-wrapper">
       
        <StageManagerPanel isAddUser={permissions.addUser} />
        <StageManagerTable />
      </div>
    </>
  );
};

export default StageManager;
