import { useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { AiFillPlusSquare } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";

import { getOptions } from "../../../../../../../services/functions";
import { validate_form } from "../../../../../../../services/validation";

import { addDevice } from "../../../../../../../state/redux/assetSetting/assetSettingSlice";
import {
  getLines,
  selectLines,
} from "../../../../../../../state/redux/lines/linesSlice";
import { getStages } from "../../../../../../../state/redux/stages/stagesSlice";
import { getSubLines } from "../../../../../../../state/redux/subLines/subLinesSlice";

import CustomSelect from "../../../../../../utils/CustomSelect";
import GenericModal from "../../../../../Modals/GenericModal";

const initialDeviceFormData = {
  lineId: "",
  subLineId: "",
  stageId: "",
  deviceType: "RFID",
  id: "",
  lineOptions: [],
  subLineOptions: [],
  stageOptions: [],
  isFormValidated: false,
  isLineSelectValid: false,
  isSubLineSelectValid: false,
  isStageSelectValid: false,
  isSubLineStage: "no",
  errors: {
    id: "",
  },
};
const DevicePanel = ({ isAddDevice, setTab }) => {
  const dispatch = useDispatch();
  const lines = useSelector(selectLines);

  const [deviceFormData, setDeviceFormData] = useState(initialDeviceFormData);
  const [isDeviceModal, setIsDeviceModal] = useState(false);

  const lineOptions = useMemo(() => getOptions(lines));
  const handleModalOpen = () => {
    dispatch(getLines()).then((res) => {
      setOptions(res.payload.data, "lineOptions");
    });
    setIsDeviceModal(true);
  };

  const handleModalClose = () => {
    resetSubLineOptions();
    resetStageOptions();

    setDeviceFormData({ ...initialDeviceFormData, lineOptions });
    setIsDeviceModal(false);
  };

  const handleDeviceDataChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;
    const tempErrors = { ...deviceFormData.errors };
    const errors = validate_form({
      tempErrors,
      name,
      value,
    });

    if (name === "isSubLineStage") {
      if (deviceFormData.lineId) {
        if (value === "yes") {
          getLineChildren(deviceFormData.lineId, "subLine");
        } else {
          getLineChildren(deviceFormData.lineId, "stage");
        }
      }
      setDeviceFormData((dd) => ({
        ...dd,
        isSubLineSelectValid: false,
        isStageSelectValid: false,
      }));
    } else if (name === "lineId") {
      setDeviceFormData((dd) => ({
        ...dd,
        isLineSelectValid: dd.isFormValidated ? true : null,
      }));
    } else if (name === "subLineId") {
      setDeviceFormData((dd) => ({
        ...dd,
        isSubLineSelectValid: dd.isFormValidated ? true : null,
      }));
    } else if (name === "stageId") {
      setDeviceFormData((dd) => ({
        ...dd,
        isStageSelectValid: dd.isFormValidated ? true : null,
      }));
    }
    // else if (name === "deviceType") {
    //   setDeviceFormData((dd) => ({
    //     ...dd,
    //     isStageSelectValid: dd.isFormValidated ? true : null,
    //   }));
    // }

    setDeviceFormData((dd) => ({
      ...dd,
      [name]: value,
      errors,
    }));
  };
  const handleDeviceFormSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    console.log(deviceFormData,form.checkValidity());
    if (form.checkValidity() === true) {
      try {
        dispatch(addDevice(deviceFormData));
        handleModalClose();
        setTab("device");
      } catch (err) {}
    } else
      setDeviceFormData((dd) => ({
        ...dd,
        isFormValidated: true,
        isLineSelectValid: !!dd.lineId,
        isSubLineSelectValid: !!dd.subLineId,
        isStageSelectValid: !!dd.stageId,
      }));
  };

  //get sublines by line id
  const getSubLineByLineId = (value) => {
    resetSubLineOptions();
    resetStageOptions();
    if (deviceFormData.isSubLineStage === "yes")
      getLineChildren(value, "subLine");
    if (deviceFormData.isSubLineStage === "no") getLineChildren(value, "stage");
  };
  //get stages by subline id
  const getStagesBySubLineId = (value) => {
    resetStageOptions();
    dispatch(getStages(value)).then((res) => {
      setOptions(res.payload.data, "stageOptions");
    });
  };

  // fetch children by lineId based on lineType
  function getLineChildren(id, type) {
    resetSubLineOptions();
    resetStageOptions();
    if (type === "stage")
      dispatch(getStages(id)).then((res) => {
        setOptions(res.payload.data, "stageOptions");
      });
    if (type === "subLine")
      dispatch(getSubLines({ id })).then((res) => {
        setOptions(res.payload.data, "subLineOptions");
      });
  }

  function setOptions(data, optionName) {
    let options = [];
    // console.log(optionName);
    if (data) options = getOptions(data);
    // else options = [{ label: "--no data found--" }];

    setDeviceFormData((dd) => ({
      ...dd,
      [optionName]: options,
    }));
  }
  function resetSubLineOptions() {
    setDeviceFormData((dd) => ({ ...dd, subLineOptions: [] }));
  }
  function resetStageOptions() {
    setDeviceFormData((dd) => ({ ...dd, stageOptions: [] }));
  }
  return (
    <>
      {isAddDevice ? (
        <button className="panel-btn" onClick={handleModalOpen}>
          Add Device
          <AiFillPlusSquare className="icons panel__icon" />
        </button>
      ) : null}
      {isDeviceModal ? (
        <GenericModal
          size="md"
          isModal={isDeviceModal}
          handleClose={handleModalClose}
          title="Add New Device"
          body={addDeviceBody}
          data={deviceFormData}
          modalInputflexBasis="100%"
          fns={{
            onChange: handleDeviceDataChange,
            onSubmit: handleDeviceFormSubmit,
            getSubLineByLineId,
            getStagesBySubLineId,
          }}
        />
      ) : null}
    </>
  );
};

export default DevicePanel;

function addDeviceBody(data, fns) {
  const currentStageOption = data.stageOptions.filter(
    (stgOpt) => stgOpt.value === data.stageId
  )[0] || { label: "--select--" };
  const currentSubLineOption = data.subLineOptions.filter(
    (sbLOpt) => sbLOpt.value === data.subLineId
  )[0] || { label: "--select--" };
  // console.log("currentStageOption", currentStageOption);
  // console.log("currentSubLineOption", currentSubLineOption);
  // console.log("data", data);
  return (
    <Form
      className="modal__form"
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      onSubmit={fns.onSubmit}
    >
      <Form.Group className="mb-3" controlId="formLine">
        <Form.Label className="required"> Select Line</Form.Label>
        <CustomSelect
          isValid={data.isLineSelectValid}
          required
          className="modal__form__select"
          options={[
            {
              value: "",
              label: "--select line--",
              isDisabled: true,
            },
            ...data.lineOptions,
          ]}
          formatOptionLabel={({ value, label }) => (
            <div onClick={() => fns.getSubLineByLineId(value)}>
              <div>{label}</div>
            </div>
          )}
          name="lineId"
          // defaultValue={data.lineId}
          onChange={fns.onChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="required">
          Do you want to assign to subline stages?
        </Form.Label>
        <div className="modal-form__radio-btns">
          <Form.Check
            type="radio"
            id="radio_one"
            label="Yes"
            value="yes"
            name="isSubLineStage"
            onChange={fns.onChange}
            checked={data.isSubLineStage === "yes"}
          />
          <Form.Check
            type="radio"
            id="radio_two"
            label="No"
            value="no"
            name="isSubLineStage"
            onChange={fns.onChange}
            checked={data.isSubLineStage === "no"}
          />
        </div>
      </Form.Group>
      {data.isSubLineStage === "yes" ? (
        <>
          <Form.Group className="mb-3" controlId="formSubLine">
            <Form.Label className="required"> Select Sub-Line</Form.Label>
            <CustomSelect
              isValid={data.isSubLineSelectValid}
              required
              className="modal__form__select"
              options={[
                {
                  value: "",
                  label: "--select line--",
                  isDisabled: true,
                },
                ...data.subLineOptions,
              ]}
              formatOptionLabel={({ value, label }) => (
                <div onClick={() => fns.getStagesBySubLineId(value)}>
                  <div>{label}</div>
                </div>
              )}
              name="subLineId"
              value={currentSubLineOption}
              // defaultValue={data.subLineId}
              onChange={fns.onChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formStage">
            <Form.Label className="required"> Select Stage</Form.Label>
            <CustomSelect
              isValid={data.isStageSelectValid}
              required
              className="modal__form__select"
              options={[
                {
                  value: "",
                  label: "--select line--",
                  isDisabled: true,
                },
                ...data.stageOptions,
              ]}
              name="stageId"
              value={currentStageOption}
              // defaultValue={data.stageId}
              onChange={fns.onChange}
            />
          </Form.Group>
        </>
      ) : data.isSubLineStage === "no" ? (
        <Form.Group className="mb-3" controlId="formStage">
          <Form.Label className="required"> Select Stage</Form.Label>
          <CustomSelect
            isValid={data.isStageSelectValid}
            required
            className="modal__form__select"
            options={[
              {
                value: "",
                label: "--select line--",
                isDisabled: true,
              },
              ...data.stageOptions,
            ]}
            name="stageId"
            value={currentStageOption}
            // defaultValue={data.stageId}
            onChange={fns.onChange}
          />
        </Form.Group>
      ) : null}
      <Form.Group className="mb-3" controlId="deviceType">
          <Form.Label> Select Device Type</Form.Label>
          <CustomSelect
            isValid={true}
            // required
            className="modal__form__select"
            options={[
              {
                value: "",
                label: "--select device type--",
                isDisabled: true,
              },
              {
                value: "RFID",
                label: "RFID Reader",
                isDisabled: false
              },
              {
                value: "Barcode",
                label: "Barcode Reader",
                isDisabled: false
              }
            ]}
            name="deviceType"
            // value={data.deviceType}
            defaultValue={data.deviceType}
            onChange={fns.onChange}
          />
      </Form.Group>
      {
        data.deviceType=='RFID'?
        <Form.Group className="mb-3" controlId="formId">
          <Form.Label className="required">Enter Device ID</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Enter Device ID"
            name="id"
            minLength="60"
            value={data.id}
            onChange={fns.onChange}
          />
          <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
          {data.errors.id ? (
            <Form.Control.Feedback type="invalid">
              {data.errors.id}
            </Form.Control.Feedback>
          ) : (
            <Form.Control.Feedback type="invalid">
              Please enter device ID
            </Form.Control.Feedback>
          )}
        </Form.Group>
        :null
      }
    </Form>
  );
}
