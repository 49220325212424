import { nanoid } from "nanoid";
import Tile from "./components/tile";

const Main = () => {
  return (
    <main className="asset-dashboard-wrapper">
      {data.map((tile) => (
        <Tile key={tile.id} name={tile.name} value={tile.value} id={tile.id} />
      ))}
    </main>
  );
};
export default Main;

var data = [
  { id: nanoid(), name: "On-Time Trips", value: 50 },
  { id: nanoid(), name: "User Count", value: 10 },
  { id: nanoid(), name: "Asset Count by Aging", value: 25 },
  { id: nanoid(), name: "Asset Plant & Equipment", value: 80 },
  { id: nanoid(), name: "Asset Furnitures & Fixtures", value: 64 },
  { id: nanoid(), name: "Asset Vehicles", value: 56 },
  { id: nanoid(), name: "Asset Office Equipment", value: 70 },
  { id: nanoid(), name: "Asset Computers & Hardware", value: 80 },
  { id: nanoid(), name: "Asset Computer Software", value: 88 },
  { id: nanoid(), name: "Asset Leasehold Land", value: 30 },
  { id: nanoid(), name: "Factory Building", value: 40 },
  { id: nanoid(), name: "Notification: Spoc-Generated-IT assets", value: 40 },
];
