import { CgTag, CgUserList, CgUserRemove } from "react-icons/cg";
import { HiDocumentReport } from "react-icons/hi"; 
import { MdManageAccounts, MdSpaceDashboard } from "react-icons/md";
import { SiPivotaltracker , SiGoogleanalytics } from "react-icons/si";
import { TbAsset, TbReportSearch, TbTemplate } from "react-icons/tb";
import { TiFlowChildren } from "react-icons/ti";
import { getUserData } from "../../services/functions";
import Analytics from "../Dashboard-new/Analytics";
import UserDashboard from "../Dashboard-new/UserDashboard";
import LineManager from "../Dashboard-new/LineManager";
import StageManager from "../Dashboard-new/StageManager";
import FlowSetting from "../Dashboard-new/FlowSetting";
import AssetSetting from "../Dashboard-new/AssetSetting";
import AssetAllocation from "../Dashboard-new/AssetAllocation";
import AssetReport from "../Dashboard-new/AssetReport";
import TemplateSettings from "../Dashboard-new/TemplateSettings";
import UserManagement from "../Dashboard-new/UserManagement";
import AssetManagementDashboard from "../Dashboard-new/AssetManagement/Dashboard";
import Inventory from "../Dashboard-new/AssetManagement/Inventory";
import AllMaster from "../Dashboard-new/AssetManagement/All-Master";
import AssetReports from "../Dashboard-new/AssetManagement/Reports";

export function GetMenuItems() {
  const { permissions , userType} = getUserData();
  return [
    
    {
      url: "/dashboard",
      Icon: SiGoogleanalytics,
      title: "Dashboard",
      permission: userType == 'StageManager' ? false : true,
      Component: userType == 'StageManager' ? UserDashboard : Analytics
    },
    {
      url: userType == 'StageManager' ? "/dashboard" : "/dashboard/line-management",
      Icon: MdSpaceDashboard,
      title: userType == 'StageManager' ?  "Dashboard" :"Line Management" ,
      permission: true,
      Component: UserDashboard,
      // obj : '{obj}'
    },
    {
      url: "/dashboard/line-manager",
      Icon: CgUserList,
      title: "Line User",
      permission: permissions.addLineManager,
      Component: LineManager,
    },
    {
      url: "/dashboard/stage-manager",
      Icon: CgUserRemove,
      title: "Stage User",
      permission: permissions.addStageManager,
      Component: StageManager,
    },
    {
      url: "/dashboard/flow-setting",
      Icon: TiFlowChildren,
      title: "Flow Setting",
      permission: permissions.getLines,
      Component: FlowSetting,
    },
    {
      url: "/dashboard/asset-setting",
      Icon: TbAsset,
      title: "Asset Settings",
      permission: permissions.getTags && permissions.getDevices,
      Component: AssetSetting,
    },
    {
      url: "/dashboard/asset-allocation",
      Icon: CgTag,
      title: "Asset Allocation",
      permission: permissions.getAssets,
      Component: AssetAllocation,
    },
    {
      url: "/dashboard/reports",
      Icon: TbReportSearch,
      title: "Report",
      permission: permissions.getReports,
      Component: AssetReport,
    },
    {
      url: "/dashboard/template-settings",
      Icon: TbTemplate,
      title: "Template Settings",
      permission: permissions.getTemplates,
      Component: TemplateSettings,
    },
    // {
    //   url: "/dashboard/user-management",
    //   Icon: MdManageAccounts,
    //   title: "User Management",
    //   permission: true,
    //   Component: UserManagement,
    // },
    // {
    //   url: "/dashboard/asset-management",
    //   Icon: HiDocumentReport,
    //   title: "Asset Management",
    //   permission: true,
    //   Component: AssetManagementDashboard,
    //   children: [
    //     {
    //       url: "/dashboard/asset-management",
    //       title: "Dashboard",
    //       Component: AssetManagementDashboard,
    //     },
    //     {
    //       url: "/dashboard/asset-management/assets",
    //       title: "Assets",
    //       Component: Inventory,
    //     },
    //     {
    //       url: "/dashboard/asset-management/all-master",
    //       title: " All Master",
    //       Component: AllMaster,
    //     },
    //     {
    //       url: "/dashboard/asset-management/reports",
    //       title: "Reports",
    //       Component: AssetReports,
    //     },
    //   ],
    // },
    {
      url: "https://lynkid.io/signin",
      Icon: SiPivotaltracker,
      title: "Zone Tracking",
      permission: false,
    },
  ];
}
