import { toast } from "react-toastify";

export const showToasts = (payload, messages) => {
  // console.log(messages.successMessage);
  // console.log(payload, messages);
  let message;
  if (payload.error) {
    message = messages?.errorMessage || payload.message;
    // console.log(message);
    toast.error(message, {
      position: "bottom-center",
      autoClose: 3000,
    });
  } else {
    message = messages?.successMessage || payload.message;
    toast.success(message, {
      position: "bottom-center",
      autoClose: 3000,
    });
  }
};
