import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { post } from "./../../services/requests";
import {
  AiFillPlusCircle,
  AiOutlineClose,
  AiOutlineCheck,
} from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { getUserData } from "../../services/functions";
import {
  getStageAssets,
  getStageAssets1,
  getStageAssetsCurrent,
  currentStageCurrent,
  getStageData,
  missedStageAssets,
  selectStageAssets,
  selectStageData,
} from "../../state/redux/adminDashboard/adminDashboardSlice";
import {
  getAccessoriesStageStatus,
  getAssetAccessories,
  scanAccessories,
  selectAccessoriesStageStatus,
  selectAssetAccessories,
} from "../../state/redux/assetAllocation/assetAllocationSlice";
import { showToasts } from "../utils/toasts";
import GenericModal from "./Modals/GenericModal";
import { BiReset, BiSearch } from "react-icons/bi";
import { io } from 'socket.io-client';
import { socketDomain } from "./../../services/requests";

const StageManagerDashboard = ({ handleAddTemplateForm }) => {
  const { userId , userType , orgId , lineId } = getUserData();
  const dispatch = useDispatch();
  const stageData = useSelector(selectStageData)[0];
  const stageAssets = useSelector(selectStageAssets);
  const missedStageAssetData = useSelector(missedStageAssets);
  const currentStageAssetData = useSelector(currentStageCurrent);
  const accessorySerialNos = useSelector(selectAccessoriesStageStatus);
  const { userLineId: stageId } = getUserData();
  const [assetId, setAssetId] = useState("");
  const [isStageAssetModal, setIsStageAssetModal] = useState(false);
  const [code, setCode] = useState("")
  const scanRef = useRef(false)
  const [completedModal, setCompletedModal] = useState(false)
  const [missedModal, setMissedModal] = useState(false)

  
  useEffect(() => {
    let data = {
      userId : userId,
      userType :  userType,
      orgId : orgId,
      lineId : lineId,
      deviceId : (new Date().getUTCMilliseconds())
  
    }
    const socket = io.connect(socketDomain , { transports: ['websocket'] })
    socket.on('connect', function () {
      console.log('connect',socket);
      socket.emit('user_login', data);
    });

    socket.on('stage_changed', function (msg) {
      console.log("msg",msg)
      dispatch(getStageData(stageId));
      dispatch(getStageAssetsCurrent({ stageId, status: "current" }));
      dispatch(getStageAssets({ stageId, status: "completed" }));
      dispatch(getStageAssets1({ stageId, status: "missed" }));
    });
    return ()=>{
      if(socket){
        socket.disconnect()
      }
    }
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getStageData(stageId));
      dispatch(getStageAssetsCurrent({ stageId, status: "current" }));
      dispatch(getStageAssets({ stageId, status: "completed" }));
      dispatch(getStageAssets1({ stageId, status: "missed" }));
    };

    fetchData();

    initiateBarcodeScanner()

    return () => {

      // document.removeEventListener('keydown')
    }
  }, []);

  const initiateBarcodeScanner = () => {
    let barcode = ''
    let interval;
    document.addEventListener('keydown', (evt) => {
      if (interval) {
        clearInterval(interval)
      }
      if (evt.code == 'Enter') {
        if (barcode) {
          handleBarcode(barcode)
        }
        barcode = ''
        return
      }
      if (evt.key != 'Shift') {
        barcode += evt.key + ""
      }
      interval = setInterval(() => {
        barcode = ''
      }, 20)
    })

    function handleBarcode(barcode) {
      // let isComponent=false
      // if(scanRef.current && accessorySerialNos){
      //   let index=accessorySerialNos.findIndex(el=>el.serialNo==barcode)
      //   if(index>-1){
      //     isComponent=true
      //   }
      // }
      if (scanRef.current) {
        setCode(barcode)
      }
      else {
        post("/devices/add-log", { tagId: barcode, stageId })
      }
    }
  }

  const handleGetAssetAcc = (assetId) => {
    dispatch(getAccessoriesStageStatus({ assetId, stageId })).then((res) => {
      setCode("")
      setIsStageAssetModal(true);
      // scanRef.current = true;
      setAssetId(assetId);
    });
  };
  const handleScanSubmit = (body) => {
    dispatch(scanAccessories(body));
    setIsStageAssetModal(false);
    scanRef.current = false;
  };

  const focusInput = () => {
    scanRef.current = true
  }

  const handleClose = () => {
    setIsStageAssetModal(false)
    scanRef.current = false;
  }

  const toggleModal = (param) => {
    if (param == 'completed') {
      setCompletedModal(!completedModal)
    }
    else {
      setMissedModal(!missedModal)
    }
  }
  const fetchData = () => {
    dispatch(getStageData(stageId));
    dispatch(getStageAssets({ stageId, status: "current" }));
    dispatch(getStageAssets({ stageId, status: "completed" }));
    dispatch(getStageAssets1({ stageId, status: "missed" }));
  }
  return (
    <>
      <div className="stageDashboard-container">
        <span
          className="pAbs adminDashboard__refreshBtn"
          onClick={(e) => {
            e.stopPropagation();
            fetchData();
          }}
          title="refresh data"
        >
          <BiReset className="adminDashboard__refreshBtn-icon" />
        </span>
        <div className="stageDashboard-info">
          <div className="stageDashboard-heading">
            Stage: <span>{stageData?.name}</span>
          </div>
          <div className="stageDashboard-stats">
            <div className="stageDashboard-stats__completed">
              Completed: <span>{stageAssets.length}</span>
              <button className="pButton" onClick={() => toggleModal('completed')}>View Assets</button>
            </div>
            {/* <div className="stageDashboard-stats__pending">
              Pending: <span>{stageData?.stageData?.pending}</span>
            </div> */}
            <div className="stageDashboard-stats__missed">
              Missed: <span>{missedStageAssetData.length}</span>
              <button className="pButton" onClick={() => toggleModal('missed')}>View Assets</button>
            </div>
          </div>
        </div>
        <div className="stageDashboard-assetList">
          <h3>Asset List</h3>
          <Table className="stageDashboard-table">
            <thead className="bg-accent">
              <tr>
                <th>Name</th>
                <th>Description</th>
                <th>Previous Stage</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentStageAssetData?.length > 0 ? (
                currentStageAssetData.map((asset, index) => {
                  return (
                    <tr
                      key={index}
                      className="stageAssets-row pRel"
                      data-assetid={asset._id}
                    >
                      <td>
                        <span
                          style={{
                            color: "#ff7200",
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => handleGetAssetAcc(asset._id)}
                        >
                          {asset.name}
                        </span>
                      </td>
                      <td>{asset.description}</td>
                      <td>
                        {asset.previousStage ? (
                          <span className="stageAssets__prevStage-passed">
                            Passed
                          </span>
                        ) : (
                          <span className="stageAssets__prevStage-missed">
                            Missed
                          </span>
                        )}
                      </td>
                      <td>
                        {asset.dataSubmitted ? (
                          <span className="stageAssets__status-completed">
                            Completed
                          </span>
                        ) : (
                          <span className="stageAssets__status-pending">
                            Pending
                            {/* <AiFillPlusCircle
                              className="icons addForm-icon"
                              onClick={handleAddTemplateForm}
                            /> */}
                          </span>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={100}>No Assets Found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        {isStageAssetModal ? (
          <GenericModal
            size="lg"
            isModal={isStageAssetModal}
            handleClose={() => handleClose()}
            title="Asset List"
            body={AddStageAssetBody}
            data={{ assetId, serialData: accessorySerialNos, stageId, focusInput, code }}
            modalInputflexBasis="30%"
            fns={{
              // onChange: handleStageCardFormChange,
              onSubmit: handleScanSubmit,
              // handleAddTemplateForm,
            }}
            noBtn
          />
        ) : null}

        {completedModal ? (
          <GenericModal
            size="lg"
            isModal={completedModal}
            handleClose={() => setCompletedModal(false)}
            title="Asset List"
            body={CompletedModalBody}
            data={{ data: stageAssets }}
            modalInputflexBasis="30%"
            // fns={{
            //   onChange: handleStageCardFormChange,
            //   onSubmit: () => {},
            //   handleAddTemplateForm,
            // }}
            noBtn
          />
        ) : null}

        {missedModal ? (
          <GenericModal
            size="lg"
            isModal={missedModal}
            handleClose={() => setMissedModal(false)}
            title="Asset List"
            body={MissedModalBody}
            data={{ data: missedStageAssetData }}
            modalInputflexBasis="30%"
            noBtn
          />
        ) : null}
      </div>
    </>
  );
};
export default StageManagerDashboard;


function AddStageAssetBody({ serialData, assetId, stageId, focusInput, code }, fns) {
  // const [code, setCode] = useState("");
  // const inputRef = useRef(null);
  const [data, setData] = useState(serialData);
  useEffect(() => {
    setData(serialData);
  }, [serialData]);

  // console.log(data, assetId, stageId);

  useEffect(() => {
    // setIsScan(true);
    // focusInput();
  }, []);
  // console.log(code);
  useEffect(() => {
    // const fullCode = setTimeout(() => {
    // console.log({code});
    //if the scanned serial no is in our list then show a tick, otherwise show a toast that says wrong serial no
    if (code) {
      if (data.find((obj) => obj.serialNo === code)) {
        //show tick
        // console.log(code, data);
        setData((d) => {
          const newData = d.map((obj, index) => {
            if (obj.serialNo === code) {
              return {
                ...obj,
                status: true,
                scanTime: new Date(),
              };
            }
            return obj;
          });
          return newData;
        });
      } else {
        // showToasts({ error: true }, { errorMessage: "Wrong Serial No!" });
        post("/devices/add-log", { tagId: code, stageId })
      }
    }
    // setCode("");
    // }, 500);
    // return () => clearTimeout(fullCode);
  }, [code]);

  const handleScanSubmit = () => {
    /**
      * {
        "stageId": "63e4853260cfc8b8c4eadbd1",
      "assetId": "63e4d83559b7f1903c639fda",
      "accessories" : [
      {
        "serailNo" : "102",
      "status" : true
                    },
      {
        "serailNo" : "201",
      "status" : false
                    }
      ]
    // ,"accessories": [
    //     {
        //         "id": "637b62c3875b6f250be7ad35",
        //         "quantity": 2,
        //         "remarks" : "test",
        //         "serialNo" : ["102","201"]
        //     }
        // ]
      }
      */
    const body = {
      stageId,
      assetId,
      accessories: data,
    };
    fns.onSubmit(body);

    // console.log(body);
  };
  // console.log(data);
  return (
    <>
      {/* <input
        type="text"
        ref={inputRef}
        style={{ opacity: "0", position: "absolute" }}
        onChange={(e) => setCode(e.target.value)}
        value={code}
      /> */}
      <button type="button" className="scanAccessory-btn2" onClick={focusInput}>
        Start Scanning
      </button>
      <Table className="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Component</th>
            <th>Unique ID</th>
            <th>Status</th>
            <th>Scan Time</th>
          </tr>
        </thead>
        <tbody>
          {data.length ? (
            data.map((item, index) => {
              // console.log(item.status);
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.accessoryName}</td>
                  <td>{item.serialNo}</td>
                  <td>
                    <span className="accessory_status">
                      {item.status ? (
                        <AiOutlineCheck className="accessory_status-icon scan_green" />
                      ) : (
                        <AiOutlineClose className="accessory_status-icon scan_red" />
                      )}
                    </span>
                  </td>
                  <td>
                    {item.scanTime
                      ? moment(item.scanTime).format("DD/MM/YYYY, h:mm:ss a")
                      : "NA"}
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td
                colSpan={100}
                style={{ textAlign: "center", fontSize: "1rem" }}
              >
                No Data Available
              </td>
            </tr>
          )}
        </tbody>
      </Table>
      <div className="flex-jc-center">
        <Button
          className="accessory_scan_submit_btn"
          onClick={handleScanSubmit}
        >
          Submit
        </Button>
      </div>
    </>
  );
}

function CompletedModalBody({ data }) {

  const [dataForStatusCompleted, setDataForCompleted] = useState(data);

  useEffect(() => {
    setDataForCompleted(data);
  }, [data]);

  return (
    <>
      <Table className="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Master Asset</th>
            <th>Description</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {dataForStatusCompleted.length ? (
            dataForStatusCompleted.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td style={{ color: 'green', fontWeight: '800' }}>Completed</td>

                </tr>
              )
            })
          )
            : (
              <tr>
                <td
                  colSpan={100}
                  style={{ textAlign: "center", fontSize: "1rem" }}
                >
                  No Data Available
                </td>
              </tr>
            )
          }




        </tbody>
      </Table>

    </>
  );
}

function MissedModalBody({ data }) {

  const [dataForStatusCompleted, setDataForCompleted] = useState(data);

  useEffect(() => {
    setDataForCompleted(data);
  }, [data]);

  return (
    <>
      <Table className="mt-4">
        <thead>
          <tr>
            <th>#</th>
            <th>Master Asset</th>
            <th>Description</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>

          {dataForStatusCompleted.length ? (
            dataForStatusCompleted.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{item.name}</td>
                  <td>{item.description}</td>
                  <td style={{ color: 'red', fontWeight: '800' }}>Missed</td>

                </tr>
              )
            })
          )
            : (
              <tr>
                <td
                  colSpan={100}
                  style={{ textAlign: "center", fontSize: "1rem" }}
                >
                  No Data Available
                </td>
              </tr>
            )
          }




        </tbody>
      </Table>

    </>
  );
}


