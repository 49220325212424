import { useState, useEffect, useMemo } from "react";
import { Form, InputGroup, Table } from "react-bootstrap";
import {
  AiFillEye,
  AiFillProfile,
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineSearch,
} from "react-icons/ai";
import { GrCatalog } from "react-icons/gr";
import GenericModal from "./Modals/GenericModal";
import GenericTable from "../utils/GenericTable";
import { getUserData, formatDates, getOptions } from "../../services/functions";
import history from "../../services/history";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssetReport,
  getAssetReportBase64,
  getSerialReportBase64,
  selectAssetReportCount,
  selectAssetReportData,
  selectIsAssetReportPagination,
} from "../../state/redux/report/reportSlice";
import moment from "moment";
import { initFetchParams } from "../../state/redux/util";
import CustomPagination from "../utils/CustomPagination";
import CustomSelect from "../utils/CustomSelect";
import { getLines, selectLines } from "../../state/redux/lines/linesSlice";
import { getSubLines } from "../../state/redux/subLines/subLinesSlice";
import {
  searchBySerialNo,
  selectAccessoryHistorySerialNo,
  selectAccessoryHistorySerialNoCount,
  selectIsAccessoryHistorySerialNoPagination,
} from "../../state/redux/assetAllocation/assetAllocationSlice";

const initialFilterFormData = {
  searchBy: "",
  search: "",
  lineId: "",
  startDate: "",
  endDate: "",
};
const AssetReport = () => {
  const dispatch = useDispatch();
  const assetReport = useSelector(selectAssetReportData);
  const assetReportCount = useSelector(selectAssetReportCount);
  const isAssetReportPagination = useSelector(selectIsAssetReportPagination);
  const lines = useSelector(selectLines);

  const serialReport = useSelector(selectAccessoryHistorySerialNo);
  const serialReportCount = useSelector(selectAccessoryHistorySerialNoCount);
  const isSerialReportPagination = useSelector(
    selectIsAccessoryHistorySerialNoPagination
  );

  const [viewAccessoriesData, setViewAccessoriesData] = useState([]);
  const [viewStageLogsData, setViewStageLogsData] = useState([]);

  const [isViewAssetAccessoriesModal, setIsViewAssetAccessoriesModal] =
    useState(false);
  const [isViewStageLogModal, setIsViewStageLogModal] = useState(false);

  const { permissions } = getUserData();
  const lineOptions = useMemo(() => getOptions(lines), [lines]);

  const [filterFormData, setFilterFormData] = useState(initialFilterFormData);

  const handleViewAccessories = (id) => {
    const viewAccessories = assetReport.filter((_, index) => index === id)[0]
      .accessories;
    const viewAccessoriesTableData = viewAccessories.map((obj, index) => {
      let objArr = [];
      objArr.push(index + 1, obj.accessoryName || "NA", obj.quantity || "NA");
      return objArr;
    });

    setViewAccessoriesData(viewAccessoriesTableData || []);
    setIsViewAssetAccessoriesModal(true);
  };
  const handleViewStageLog = (id) => {
    const stagesLog = assetReport.filter((_, index) => index === id)[0].stages;
    // console.log(stagesLog);
    const stagesLogTableData = stagesLog.map((obj, index) => {
      let objArr = [];
      objArr.push(
        index + 1,
        obj.stage || "NA",
        obj.gatein ? moment(obj.gatein).format("DD/MM/YYYY, h:mm:ss a") : "NA",
        obj.gateout ? moment(obj.gateout).format("DD/MM/YYYY, h:mm:ss a") : "NA"
      );
      return objArr;
    });
    // console.log(stagesLogTableData);
    setViewStageLogsData(stagesLogTableData || []);
    setIsViewStageLogModal(true);
  };
  const action = (id) => {
    return (
      <div className="reportAction action-icons">
        <span onClick={() => handleViewAccessories(id)} title="view components">
          <AiFillEye className="view-icon icons" />
        </span>
        <span onClick={() => handleViewStageLog(id)}>
          <AiFillProfile className="view-icon icons" title="view stage log" />
        </span>
      </div>
    );
  };

  let assetReportTableData;
  let assetReportTableHeadings;

  if (filterFormData.searchBy === "serialNo") {
    assetReportTableData = serialReport.map((obj, index) => {
      let objArr = [];
      const currAcc =
        obj.accessories.find((acc) => acc.serialNo === filterFormData.search) ||
        {};
      // console.log(currAcc);
      objArr.push(
        index + 1,
        obj.assetName || "NA",
        obj.stage?.name || "NA",
        currAcc.accessoryName || "NA",
        [
          <span className="accessory_status" key="_status_">
            {currAcc.status ? (
              <AiOutlineCheck className="accessory_status-icon scan_green" />
            ) : (
              <AiOutlineClose className="accessory_status-icon scan_red" />
            )}
          </span>,
        ],
        moment(currAcc.scanTime).format("DD/MM/YYYY, h:mm a") || "NA"
      );
      return objArr;
    });
    assetReportTableHeadings = [
      "S.No",
      "Master Asset",
      "Stage",
      "Component",
      "Status",
      "Time",
    ];
  } else {
    let gateDiff = []
    let timeDiff = []
    assetReportTableData = assetReport.map((obj, index) => {
      let objArr = [];
      gateDiff.push(
        {
          first: '',
          last: ''
        }
      )

      for (let j = 0; j < obj.stages.length; j++) {
        if (obj.stages[j].gatein !== '') {
          gateDiff[index].first = obj.stages[j].gatein
          break
        }
      }

      for (let k = obj && obj.stages.length - 1; k >= 0; k--) {
        if (obj.stages[k].gateout !== '') {
          gateDiff[index].last = obj.stages[k].gateout
          break
        }
      }
      var startDate = gateDiff && gateDiff[index].first ? gateDiff[index].first : ''
      var endDate = gateDiff && gateDiff[index].last ? gateDiff[index].last : ''
      const start = new Date(startDate).getTime();
      const end = new Date(endDate).getTime();
      const milliseconds = Math.abs(end - start).toString()
      const seconds = parseInt(milliseconds / 1000);
      const minutes = parseInt(seconds / 60);
      const hours = parseInt(minutes / 60);
      const days = parseInt(hours / 24);
      const time = (days === 0 || isNaN(days) ? '' : days  + " days ")  + ((hours % 24) === 0 || isNaN(hours % 24) ? '' : (hours % 24) + " hrs ") +  ((minutes % 60) === 0 || isNaN(minutes % 60) ? '' : (minutes % 60) + " mins ");
      timeDiff.push(time)
      objArr.push(
        index + 1,
        obj.assetName || "NA",
        obj.lineName || "NA",
        obj.status || "NA",
        obj.mappedBy || "NA",
        obj.mappedOn ? moment(obj.mappedOn).format("DD/MM/YYYY, h:mm a") : "NA",
       <span style={{textDecoration:'none'}} className='timeValue'>{timeDiff[index] === '' ? 'NA':timeDiff[index] }</span>,
        action(index)
      );
      return objArr;
    });
    assetReportTableHeadings = [
      "S.No",
      "Master Asset",
      "Line",
      "Status",
      "Mapped By",
      "Mapped On",
      "Time Spent",
      "Action",
    ];
  }
  // let Dummy = [
  //   {
  //     "assetId": "647f097daf1891483c68c33a",
  //     "assetName": "IWS Asset 24",
  //     "lineName": "IWS Line B",
  //     "mappedBy": "",
  //     "mappedOn": "",
  //     "accessories": [],
  //     "status": "Passed",
  //     "stages": [
  //       {
  //         "stage": "IWS Stage 21",
  //         "gatein": "",
  //         "gateout": ""
  //       },
  //       {
  //         "stage": "IWS Stage 22",
  //         "gatein": "2023-05-12T09:59:30.266Z",
  //         "gateout": ""
  //       },
  //       {
  //         "stage": "IWS Stage 22",
  //         "gatein": "2023-05-12T09:59:30.888Z",
  //         "gateout": "2023-05-12T10:00:13.000"
  //       }
  //     ]
  //   },
  //   {
  //     "assetId": "647f097daf1891483c68c33a",
  //     "assetName": "IWS Asset 24",
  //     "lineName": "IWS Line B",
  //     "mappedBy": "",
  //     "mappedOn": "",
  //     "accessories": [],
  //     "status": "Passed",
  //     "stages": [
  //       {
  //         "stage": "IWS Stage 21",
  //         "gatein": "2023-05-12T10:38:19.036Z",
  //         "gateout": "2023-05-12T10:38:29.500Z"
  //       },
  //       {
  //         "stage": "IWS Stage 22",
  //         "gatein": "2023-05-12T10:38:33.466Z",
  //         "gateout": "2023-05-12T10:49:28.338Z"
  //       }
  //     ]
  //   }
  // ]
  // useEffect(() => {
  //   let gateIn = []
  //     for (let i=0; i < Dummy.length; i++){
  //       gateIn.push(
  //         {
  //           first:'',
  //           last:''
  //         }
  //       )
  //       for(let j=0; j < Dummy[i].stages.length; j++){

  //         if(Dummy[i].stages[j].gatein !== ''){
  //           gateIn[i].first = Dummy[i].stages[j].gatein

  //           break
  //         }
  //       }

  //       for(let k= Dummy[i].stages.length - 1; k >= 0; k--){

  //         if(Dummy[i].stages[k].gatein !== ''){
  //           gateIn[i].last = Dummy[i].stages[k].gateout

  //           break
  //         }
  //       }
  //       console.log('gateIn',gateIn);
  //     }

  // }, [])



  const handleFilterFormDataChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    let value = actionMeta ? e.value : e.target.value;
    // console.log(name, value);
    setFilterFormData((ffd) => ({ ...ffd, [name]: value }));
  };

  const handleFilterFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = { ...formatDates(filterFormData), ...initFetchParams };

    if (body.searchBy === "serialNo") {
      dispatch(searchBySerialNo({ ...initFetchParams, serialNo: body.search }));
    } else {
      dispatch(getAssetReport(body));
    }
    // console.log(body);
  };
  const handleReportDownload = () => {
    if (filterFormData.searchBy === "serialNo") {
      dispatch(getSerialReportBase64(formatDates(filterFormData)));
    } else {
      dispatch(getAssetReportBase64(formatDates(filterFormData)));
    }
  };
  const getSubLineByLineId = async (value) => {
    dispatch(getSubLines({ id: value })).then((res) => { });
  };
  useEffect(() => {
    dispatch(getAssetReport(initFetchParams));
    dispatch(getLines());
    if (!permissions.getReports) history.push("/dashboard");
  }, []);
  return (
    <>
      <div className="reports outer-wrapper">
        <div className="reportPanel">
          <div className="reportPanel__heading">
            <h2>Reports</h2>
          </div>
          <Form className="reportFilterForm " onSubmit={handleFilterFormSubmit}>
            <div className="reportFilterInputWrapper reportFilterForm-top">
              <Form.Group className="" controlId="formLine">
                <Form.Label className=""> Select Line</Form.Label>
                <CustomSelect
                  className="modal__form__select"
                  options={[
                    {
                      value: "",
                      label: "--select line--",
                    },
                    ...lineOptions,
                  ]}
                  formatOptionLabel={({ value, label }) => (
                    <div onClick={() => getSubLineByLineId(value)}>
                      <div>{label}</div>
                    </div>
                  )}
                  name="lineId"
                  onChange={handleFilterFormDataChange}
                />
              </Form.Group>
              <Form.Group className="" controlId="formSubLine">
                <Form.Label className=""> Select Sub-Line</Form.Label>
                <CustomSelect
                  className="modal__form__select"
                  options={[
                    {
                      value: "",
                      label: "--select line--",
                    },
                    // ...filterFormData.subLineOptions,
                  ]}
                  name="lineId"
                  onChange={handleFilterFormDataChange}
                />
              </Form.Group>
              <Form.Group className="" controlId="formStartDate">
                <Form.Label className="">From</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="startDate"
                  value={filterFormData.startDate}
                  onChange={handleFilterFormDataChange}
                />
              </Form.Group>
              <Form.Group className="" controlId="formEndDate">
                <Form.Label className="">To</Form.Label>
                <Form.Control
                  type="datetime-local"
                  name="endDate"
                  value={filterFormData.endDate}
                  onChange={handleFilterFormDataChange}
                />
              </Form.Group>
            </div>
            <div className=" reportFilterInputWrapper reportFilterForm-bottom">
              <Form.Group className="" controlId="formSearchBy">
                <Form.Label className="">Search By</Form.Label>
                <CustomSelect
                  className="modal__form__select"
                  options={[
                    {
                      value: "",
                      label: "--select--",
                    },
                    {
                      value: "asset",
                      label: "Master Asset",
                    },
                    {
                      value: "component",
                      label: "Component",
                    },
                    {
                      value: "serialNo",
                      label: "Serial No.",
                    },
                  ]}
                  name="searchBy"
                  onChange={handleFilterFormDataChange}
                />
              </Form.Group>
              <Form.Group className="filterForm__input-wrapper">
                <Form.Label className="">Enter Search</Form.Label>
                <Form.Control
                  placeholder="type to search..."
                  type="text"
                  value={filterFormData.search}
                  name="search"
                  onChange={handleFilterFormDataChange}
                />
              </Form.Group>
              <button type="submit" className="filterForm__submit-btn">
                Apply Filters
              </button>
              <button
                type="button"
                className="downloadBtn"
                onClick={handleReportDownload}
              >
                Download Report
              </button>
            </div>
          </Form>
        </div>

        {isViewAssetAccessoriesModal ? (
          <GenericModal
            size="lg"
            isModal={isViewAssetAccessoriesModal}
            handleClose={() => setIsViewAssetAccessoriesModal(false)}
            title="Components"
            body={addViewAccessoriesBody}
            data={viewAccessoriesData}
            modalInputflexBasis="30%"
            noBtn
          />
        ) : isViewStageLogModal ? (
          <GenericModal
            size="lg"
            isModal={isViewStageLogModal}
            handleClose={() => setIsViewStageLogModal(false)}
            title="Stage Log"
            body={addViewStageLogsBody}
            data={viewStageLogsData}
            modalInputflexBasis="30%"
            noBtn
          />
        ) : null}

        <GenericTable
          headings={assetReportTableHeadings}
          data={assetReportTableData}
        />

        {filterFormData.searchBy === "serialNo" ? (
          <CustomPagination
            totalCount={serialReportCount}
            isPagination={isSerialReportPagination}
            fetchData={searchBySerialNo}
            extraParams={{ serialNo: filterFormData.search }}
          />
        ) : (
          <CustomPagination
            totalCount={assetReportCount}
            isPagination={isAssetReportPagination}
            fetchData={getAssetReport}
          />
        )}
      </div>
    </>
  );
};

export default AssetReport;

function addViewAccessoriesBody(data) {
  // console.log(data);
  return (
    <GenericTable headings={["S.No", "Component", "Quantity"]} data={data} />
  );
}
function addViewStageLogsBody(data) {
  // console.log(data);
  return <GenericTable headings={["S.No", "Stage", "In", "Out"]} data={data} />;
}
