import { useState, useEffect, useMemo } from "react";
import { Form } from "react-bootstrap";
import { AiFillPlusSquare } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";

import { getSubLines } from "../../../../../state/redux/subLines/subLinesSlice";
import { getStages } from "../../../../../state/redux/stages/stagesSlice";
import { addStageManager } from "../../../../../state/redux/stageManager/stageManagerSlice";
import {
  getLines,
  selectLines,
} from "../../../../../state/redux/lines/linesSlice";

import { getOptions } from "../../../../../services/functions";
import { validate_form } from "../../../../../services/validation";

import GenericModal from "../../../Modals/GenericModal";
import CustomSelect from "../../../../utils/CustomSelect";
import ShowHidePassword from "../../../../utils/ShowHidePassword";

const initialFormData = {
  name: "",
  email: "",
  lineId: "",
  subLineId: "",
  stageId: "",
  password: "",
  confirmPassword: "",
  status: "",
  description: "",
  lineOptions: [],
  subLineOptions: [],
  stageOptions: [],
  isFormValidated: false,
  isLineSelectValid: false,
  isSubLineSelectValid: false,
  isStageSelectValid: false,
  isStatusSelectValid: false,
  isSubLineStage: "no",
  errors: {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  },
};
const StageManagerPanel = ({ isAddUser }) => {
  const dispatch = useDispatch();
  const lines = useSelector(selectLines);
  const lineOptions = useMemo(() => getOptions(lines));

  const [formData, setFormData] = useState(initialFormData);
  const [isModal, setIsModal] = useState(false);

  const handleModalOpen = () => {
    dispatch(getLines());
    setIsModal(true);
  };
  const handleModalClose = () => {
    resetStageOptions();
    resetSubLineOptions();

    setFormData({
      ...initialFormData,
      lineOptions,
    });
    setIsModal(false);
  };

  const handleFormDataChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;
    const tempErrors = { ...formData.errors };
    const errors = validate_form({
      tempErrors,
      name,
      value,
      curr_pass: formData.password,
    });

    //set select validations
    if (name === "isSubLineStage") {
      if (formData.lineId) {
        if (value === "yes") {
          getLineChildren(formData.lineId, "subLine");
        } else {
          getLineChildren(formData.lineId, "stage");
        }
      }
      setFormData((fd) => ({
        ...fd,
        isSubLineSelectValid: false,
        isStageSelectValid: false,
      }));
    } else if (name === "lineId") {
      setFormData((fd) => ({
        ...fd,
        isLineSelectValid: fd.isFormValidated ? true : null,
      }));
    } else if (name === "subLineId") {
      setFormData((fd) => ({
        ...fd,
        isSubLineSelectValid: fd.isFormValidated ? true : null,
      }));
    } else if (name === "stageId") {
      setFormData((fd) => ({
        ...fd,
        isStageSelectValid: fd.isFormValidated ? true : null,
      }));
    } else if (name === "status") {
      setFormData((fd) => ({
        ...fd,
        isStatusSelectValid: fd.isFormValidated ? true : null,
      }));
    }

    setFormData((oldData) => ({ ...oldData, [name]: value, errors }));
  };
  const handleEdit = (e) => {
    console.log("handleEdit", e.target.id);
  };

  //handle add stage manager
  const handleFormSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === true) {
      try {
        dispatch(addStageManager(formData));
        handleModalClose();
      } catch (err) {}
    } else
      setFormData((fd) => ({
        ...fd,
        isFormValidated: true,
        isLineSelectValid: !!fd.lineId,
        isSubLineSelectValid: !!fd.subLineId,
        isStageSelectValid: !!fd.stageId,
        isStatusSelectValid: !!fd.status,
      }));
  };

  // fetch children by lineId based on lineType
  const getLineChildren = async (id, type) => {
    resetSubLineOptions();
    resetStageOptions();

    if (type === "stage")
      dispatch(getStages(id)).then((res) => {
        setOptions(res.payload.data, "stageOptions");
      });
    if (type === "subLine")
      dispatch(getSubLines({ id })).then((res) => {
        setOptions(res.payload.data, "subLineOptions");
      });
  };
  //get sublines by line id
  const getSubLineByLineId = async (value) => {
    if (formData.isSubLineStage === "yes") getLineChildren(value, "subLine");
    if (formData.isSubLineStage === "no") getLineChildren(value, "stage");
  };

  //get stages by subline id
  const getStagesBySubLineId = async (value) => {
    resetStageOptions();
    dispatch(getStages(value)).then((res) => {
      setOptions(res.payload.data, "stageOptions");
    });
  };

  useEffect(() => {
    setFormData((fd) => ({ ...fd, lineOptions }));
  }, [lines]);

  function setOptions(data, optionName) {
    let options = [];
    // console.log(optionName);
    if (data) options = getOptions(data);
    // else options = [{ label: "--no data found--" }];

    setFormData((oldData) => ({
      ...oldData,
      [optionName]: options,
    }));
  }
  function resetSubLineOptions() {
    // console.log("reset sublines");
    setFormData((fd) => ({ ...fd, subLineOptions: [] }));
  }
  function resetStageOptions() {
    // console.log("reset stages");

    setFormData((fd) => ({ ...fd, stageOptions: [] }));
  }

  return (
    <>
      <div className="panel">
        <div className="panel__heading">
          <h2>Stage Manager</h2>
        </div>
        <div className="panel__items">
          {isAddUser ? (
            <button
              className="panel-btn stage-manager__add-btn"
              onClick={handleModalOpen}
            >
              Add Manager
              <AiFillPlusSquare className="icons panel__icon" />
            </button>
          ) : null}
        </div>
      </div>
      {isModal ? (
        <GenericModal
          size="lg"
          isModal={isModal}
          handleClose={handleModalClose}
          title="Add New Stage Manager"
          body={addStageManagerBody}
          data={formData}
          fns={{
            onChange: handleFormDataChange,
            onSubmit: handleFormSubmit,
            getSubLineByLineId,
            getStagesBySubLineId,
          }}
        />
      ) : null}
    </>
  );
};

export default StageManagerPanel;

function addStageManagerBody(data, fns) {
  // console.log("data", data);

  const currentStageOption = data.stageOptions.filter(
    (stgOpt) => stgOpt.value === data.stageId
  )[0] || { label: "--select--" };
  const currentSubLineOption = data.subLineOptions.filter(
    (sbLOpt) => sbLOpt.value === data.subLineId
  )[0] || { label: "--select--" };
  // console.log(data);
  return (
    <Form
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      className="modal__form"
      onSubmit={fns.onSubmit}
    >
      <Form.Group className="mb-3" controlId="formName">
        <Form.Label className="required">Name</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter name"
          name="name"
          value={data.name}
          onChange={fns.onChange}
        />
        <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        {data.errors.name ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.name}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please enter name
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Label className="required">Email</Form.Label>
        <Form.Control
          required
          type="email"
          placeholder="Enter email"
          name="email"
          value={data.email}
          onChange={fns.onChange}
        />
        <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        {data.errors.email ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.email}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please enter email
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formLine">
        <Form.Label className="required"> Select Line</Form.Label>
        <CustomSelect
          isValid={data.isLineSelectValid}
          required
          className="modal__form__select"
          options={[
            {
              value: "",
              label: "--select line--",
              isDisabled: true,
            },
            ...data.lineOptions,
          ]}
          formatOptionLabel={({ value, label }) => (
            <div onClick={() => fns.getSubLineByLineId(value)}>
              <div>{label}</div>
            </div>
          )}
          name="lineId"
          // defaultValue={data.lineId}
          onChange={fns.onChange}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label className="required">
          Do you want to assign to subline stages?
        </Form.Label>
        <div className="modal-form__radio-btns">
          <Form.Check
            type="radio"
            id="radio_one"
            label="Yes"
            value="yes"
            name="isSubLineStage"
            onChange={fns.onChange}
            checked={data.isSubLineStage === "yes"}
          />
          <Form.Check
            type="radio"
            id="radio_two"
            label="No"
            value="no"
            name="isSubLineStage"
            onChange={fns.onChange}
            checked={data.isSubLineStage === "no"}
          />
        </div>
      </Form.Group>
      {data.isSubLineStage === "yes" ? (
        <>
          <Form.Group className="mb-3" controlId="formSubLine">
            <Form.Label className="required"> Select Sub-Line</Form.Label>
            <CustomSelect
              isValid={data.isSubLineSelectValid}
              required
              className="modal__form__select"
              options={[
                {
                  value: "",
                  label: "--select line--",
                  isDisabled: true,
                },
                ...data.subLineOptions,
              ]}
              formatOptionLabel={({ value, label }) => (
                <div onClick={() => fns.getStagesBySubLineId(value)}>
                  <div>{label}</div>
                </div>
              )}
              value={currentSubLineOption}
              name="subLineId"
              // defaultValue={data.subLineId}
              onChange={fns.onChange}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formStage">
            <Form.Label className="required"> Select Stage</Form.Label>
            <CustomSelect
              isValid={data.isStageSelectValid}
              required
              className="modal__form__select"
              options={[
                {
                  value: "",
                  label: "--select line--",
                  isDisabled: true,
                },
                ...data.stageOptions,
              ]}
              name="stageId"
              value={currentStageOption}
              // defaultValue={data.stageId}
              onChange={fns.onChange}
            />
          </Form.Group>
        </>
      ) : data.isSubLineStage === "no" ? (
        <Form.Group className="mb-3" controlId="formStage">
          <Form.Label className="required"> Select Stage</Form.Label>
          <CustomSelect
            isValid={data.isStageSelectValid}
            required
            className="modal__form__select"
            options={[
              {
                value: "",
                label: "--select line--",
                isDisabled: true,
              },
              ...data.stageOptions,
            ]}
            name="stageId"
            value={currentStageOption}
            // defaultValue={data.stageId}
            onChange={fns.onChange}
          />
        </Form.Group>
      ) : null}

      <Form.Group className="mb-3 pRel" controlId="formPassword">
        <Form.Label className="required">Password</Form.Label>
        <ShowHidePassword
          value={data.password}
          name="password"
          onChange={fns.onChange}
          placeholder="Enter password"
          minLength="8"
        />
        {data.errors.password ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.password}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please enter password
          </Form.Control.Feedback>
        )}
        {data.errors.password === "" && (
          <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3 pRel" controlId="formConfirmPassword">
        <Form.Label className="required">Confirm Password</Form.Label>
        <ShowHidePassword
          value={data.confirmPassword}
          name="confirmPassword"
          onChange={fns.onChange}
          placeholder="confirm password"
          minLength="30"
        />

        <Form.Control.Feedback>Good to go!</Form.Control.Feedback>

        {data.errors.confirmPassword ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.confirmPassword}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please confirm the entered password
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formStatus">
        <Form.Label className="required">Status</Form.Label>
        <CustomSelect
          isValid={data.isStatusSelectValid}
          required
          className="modal__form__select"
          options={[
            {
              value: "",
              label: "--select status--",
              isDisabled: true,
            },
            {
              value: "active",
              label: "Active",
            },
            {
              value: "inActive",
              label: "InActive",
            },
          ]}
          name="status"
          // defaultValue={data.status}
          onChange={fns.onChange}
        />
      </Form.Group>

      <Form.Group
        className="mb-3 addLineManager__desc"
        controlId="formDescription"
      >
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Add a description here"
          style={{ height: "100px" }}
          name="description"
          value={data.description}
          onChange={fns.onChange}
        />
      </Form.Group>
    </Form>
  );
}
