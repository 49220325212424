import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../services/requests";
import { showToasts } from "../../../views/utils/toasts";
import { initFetchParams } from "../util";

export const getAssetMaster = createAsyncThunk(
  "assetMaster/getAssetMaster",
  async (data) => {
    const body = {};
    const res = await post("/assetmaster/get-asset-master", data);
    // console.log(res);
    return res;
  }
);

export const getAssetMasterReport = createAsyncThunk(
  "assetMaster/getAssetMasterReport",
  async (data) => {
    const body = {};
    const res = await post("/assetmaster/get-asset-master", data);
    // console.log(res);
    return res;
  }
);

export const getAssetMasterReportBase64 = createAsyncThunk(
  "assetMaster/getAssetMasterBase64",
  async (data) => {
    const body = {
      ...data,
      downloadType: "excel",
    };

    const res = await post("/assetmaster/get-asset-master", body);
    // console.log(res);

    return res;
  }
);

export const addAssetMaster = createAsyncThunk(
  "assetMaster/addAssetMaster",
  async (data, { dispatch }) => {
    const body = {
      asset_Type: data.assetType.value,
      asset_Primary_Code: data.assetPrimaryCode,
      asset_Id: data.assetTagId,
      asset_Finance: data.financeAsset,
      sbu: data.sbu,
      asset_Class_1: data.assetClass1,
      asset_Class_2: data.assetClass2,
      asset_Sub_Class: data.assetSubClass,
      department: data.department.value,
      plant: data.plant.value,
      oem: data.make_oem,
      model: data.model,
      device_Number: data.deviceNumber,
      status: data.status,
      location: data.location.value,
      vendor: data.vendor,
      quantity: data.quantity,
      asset_It_Code: data.itAssetCode,
      cost_Centre: data.costCenter,
      userName: data.userName,
      description: data.description,
      po_Number: data.poNumber,
      asset_Status: data.assetStatus.value,
      purchase_Date: data.purchase,
      capitalized_Date: data.capitalizedOn,
    };
    const res = await post("/assetmaster/add-asset-master", body);
    if (!res.error) {
      dispatch(getAssetMaster(initFetchParams));
      dispatch(setIsForm(false));
      dispatch(setUpdateAssetId(null));
    }
    return res;
  }
);

export const updateAssetMaster = createAsyncThunk(
  "assetMaster/updateAssetMaster",
  async (data, { dispatch }) => {
    const body = {
      asset_Type: data.assetType.value,
      asset_Primary_Code: data.assetPrimaryCode,
      asset_Id: data.assetTagId,
      asset_Finance: data.financeAsset,
      sbu: data.sbu,
      asset_Class_1: data.assetClass1,
      asset_Class_2: data.assetClass2,
      asset_Sub_Class: data.assetSubClass,
      department: data.department.value,
      plant: data.plant.value,
      oem: data.make_oem,
      model: data.model,
      device_Number: data.deviceNumber,
      status: data.status,
      location: data.location.value,
      vendor: data.vendor,
      quantity: data.quantity,
      asset_It_Code: data.itAssetCode,
      cost_Centre: data.costCenter,
      userName: data.userName,
      description: data.description,
      po_Number: data.poNumber,
      asset_Status: data.assetStatus.value,
      purchase_Date: data.purchase,
      capitalized_Date: data.capitalizedOn,
    };
    const res = await post("/assetmaster/update-asset-master", body);
    if (!res.error) {
      dispatch(getAssetMaster(initFetchParams));
      dispatch(setIsForm(false));
      dispatch(setUpdateAssetId(null));
    }
    return res;
  }
);

export const deleteAssetMaster = createAsyncThunk(
  "assetMaster/deleteAssetMaster",
  async (data) => {
    const body = {
      asset_Id: data.assetId,
    };
    const res = await post("/assetmaster/delete-asset-master", body);
    // console.log(res);
    return res;
  }
);

export const transferAssetMaster = createAsyncThunk(
  "assetMaster/transferAssetMaster",
  async (data) => {
    const body = {
      asset_Id: data.assetId,
      location: data.location,
    };
    const res = await post("/assetmaster/transfer-asset-master", body);
    // console.log(res);
    return res;
  }
);

///////////////////////

export const getMaster = createAsyncThunk(
  "assetMaster/getMaster",
  async (data) => {
    const body = {};
    const res = await post("/assetmaster/get-master", data);
    // console.log(res);
    return res;
  }
);

export const addMaster = createAsyncThunk(
  "assetMaster/addMaster",
  async (data, { dispatch }) => {
    const body = {
      value: data.name,
      type: data.type,
    };
    const res = await post("/assetmaster/add-master", body);

    if (!res.error) {
      dispatch(getMaster(initFetchParams));
    }

    return res;
  }
);

export const deleteMaster = createAsyncThunk(
  "assetMaster/deleteMaster",
  async (data, { dispatch }) => {
    const body = {
      _id: data._id,
    };
    const res = await post("/assetmaster/delete-master", body);

    // if (!res.error) {
    //   dispatch(getMaster(initFetchParams));
    // }

    return res;
  }
);

export const updateMaster = createAsyncThunk(
  "assetMaster/updateMaster",
  async (data, { dispatch }) => {
    const body = {
      _id: data._id,
      value: data.value,
    };
    const res = await post("/assetmaster/update-master", body);

    if (!res.error) {
      dispatch(getMaster(initFetchParams));
    }

    return res;
  }
);

export const assetManagementSlice = createSlice({
  name: "assetManagement",
  initialState: {
    assets: {
      data: [],
      loading: false,
      error: "",
      totalCount: 0,
      isPagination: false,
      currAsset: {},
      formTitle: "",
      isForm: false,
      updateAssetId: null,
      master: [],
    },
    report: {
      data: [],
      totalCount: 0,
      isPagination: false,
      reportBase64: "",
    },
  },

  reducers: {
    setIsForm(state, action) {
      state.assets.isForm = action.payload;
    },
    setFormTitle(state, action) {
      state.assets.formTitle = action.payload;
    },
    setUpdateAssetId(state, action) {
      state.assets.updateAssetId = action.payload;
    },
  },

  extraReducers(builder) {
    // Asset Cases
    builder.addCase(getAssetMaster.fulfilled, (state, action) => {
      state.assets.data = action.payload.data || [];
      state.assets.totalCount = Number(action.payload.data.length);
      state.assets.isPagination = action.payload.data.length > 10;
      if (action.payload.error) {
        showToasts(action.payload);
      }
    });
    builder.addCase(getAssetMasterReport.fulfilled, (state, action) => {
      state.report.data = action.payload.data || [];
      state.report.totalCount = Number(action.payload.data.length);
      state.report.isPagination = action.payload.data.length > 10;
      if (action.payload.error) {
        showToasts(action.payload);
      }
    });
    builder.addCase(getAssetMasterReportBase64.fulfilled, (state, action) => {
      state.report.reportBase64 = action.payload.data || [];
      if (action.payload.error) {
        showToasts(action.payload);
      } else {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${action.payload.data}`;
        const downloadLink = document.createElement("a");
        // console.log(linkSource);
        downloadLink.href = linkSource;
        downloadLink.target = "_blank";
        downloadLink.click();

        showToasts(action.payload, {
          successMessage: "Report Downloaded Successfully!",
        });
      }
    });
    builder.addCase(addAssetMaster.fulfilled, (state, action) => {
      showToasts(action.payload);
    });

    builder.addCase(updateAssetMaster.fulfilled, (state, action) => {
      showToasts(action.payload);
    });

    builder.addCase(deleteAssetMaster.fulfilled, (state, action) => {
      showToasts(action.payload);
    });

    // Master Cases
    builder.addCase(getMaster.fulfilled, (state, action) => {
      state.assets.master = action.payload.data || [];
      if (action.payload.error) {
        showToasts(action.payload);
      }
    });
    builder.addCase(addMaster.fulfilled, (state, action) => {
      showToasts(action.payload);
    });

    builder.addCase(deleteMaster.fulfilled, (state, action) => {
      showToasts(action.payload);
    });

    builder.addCase(updateMaster.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
    //
  },
});

export const { setIsForm, setFormTitle, setUpdateAssetId } =
  assetManagementSlice.actions;

// export const selectAssets = (state) => state.assetAllocation.assets.data;
export const selectIsAssetForm = (state) => state.assetManagement.assets.isForm;
export const selectUpdateAssetId = (state) =>
  state.assetManagement.assets.updateAssetId;
export const selectAssetFormTitle = (state) =>
  state.assetManagement.assets.formTitle;

export const selectAssetMasterData = (state) =>
  state.assetManagement.assets.data;
export const selectMasterData = (state) => state.assetManagement.assets.master;

export const selectAssetMasterReportData = (state) =>
  state.assetManagement.report.data;

export default assetManagementSlice.reducer;
