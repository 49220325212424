import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import $ from "jquery";
import Header from "../../views/Dashboard/Header";
import Sidebar from "../../views/Dashboard/Sidebar";
import CustomBreadcrumb from "./../../views/Dashboard/CustomBreadcrumb";
import logo from "./../../static/images/logo3.png";
import "./../../static/css/dashboard.css";
import { GetMenuItems } from "../../views/Dashboard/menuItems";

const Dashboard = () => {
  const menuItems = GetMenuItems();

  const [theme, setTheme] = useState("light");
  function toggleTheme(themeAttr) {
    setTheme((oldTheme) => (oldTheme === "light" ? "dark" : "light"));
    document.documentElement.setAttribute("data-theme", themeAttr);
  }
  useEffect(() => {
    $("[data-toggle='dropdown']").on("click", function (e) {
      e.stopPropagation();
      let target = $(this).data("target");
      $(target).parent().toggleClass("open");
    });

    setTimeout(() => {
      $(".custom-modal-overlay").height("100vh");
    }, 1000);

    $("[data-toggle!='dropdown']").on("click", function () {
      $(".btn-group,.media").removeClass("open");
    });

    $("#menuToggle").on("click", function () {
      $(".sidebar").toggleClass("open");
    });
  }, []);
  return (
    <div>
      <Header image={logo} toggleTheme={toggleTheme} theme={theme} />
      <div className="main-content">
        <Sidebar image={logo} />
        <div className="content-body">
          <CustomBreadcrumb />
          <Switch>
            {menuItems.map((item, index) => {
              if (item.children) {
                return <ChildRoutes items={item.children} key={item.title} />;
              }
              return (
                <Route
                  path={item.url}
                  exact
                  component={item.Component}
                  key={item.title}
                  obj={item.obj}
                />
              );
            })}
            <Redirect to="/dashboard" />
          </Switch>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;

function ChildRoutes({ items }) {
  // console.log(items);
  return (
    <>
      {items.map((child, indx) => {
        return (
          <Route
            path={child.url}
            exact
            component={child.Component}
            key={child.title}
          />
        );
      })}
    </>
  );
}
