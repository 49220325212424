import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getDevices } from "../../../../../state/redux/assetSetting/assetSettingSlice";
import { initFetchParams } from "../../../../../state/redux/util";

import DeviceFilter from "./components/DeviceFilter/index";
import DeviceTable from "./components/DeviceTable/index";

const DeviceContent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDevices(initFetchParams));
  }, []);
  return (
    <>
      <DeviceFilter />
      <DeviceTable />
    </>
  );
};

export default DeviceContent;
