import FixRequiredSelect from "./FixRequiredSelect";
import BaseSelect from "react-select";

const CustomSelect = (props) => {
  const { isValid } = props;
  // console.log("isValid", isValid);
  const genericSelectStyles = {
    control: (styles, state) => {
      return {
        ...styles,
        backgroundColor: "transparent",
        borderColor:
          isValid === true
            ? "#28a745"
            : isValid === false
            ? "#dc3545"
            : "var(--clr--accent)",
        boxShadow:
          state.isFocused && isValid
            ? "0 0 3px #28a745"
            : state.isFocused && !isValid
            ? "0 0 3px #dc3545"
            : "none",
        cursor: "pointer",
        ":focus": {
          ...styles[":focus"],
          //   borderColor: "var(--clr--accent)",
          boxShadow: "0 0 3px var(--clr--accent)",
        },
        ":active": {
          ...styles[":active"],
          //   borderColor: "var(--clr--accent)",
          boxShadow: "0 0 3px var(--clr--accent)",
        },

        ":hover": {
          ...styles[":hover"],
          borderColor:
            isValid === true
              ? "#28a745"
              : isValid === false
              ? "#dc3545"
              : "var(--clr--accent)",
        },
      };
    },
    option: (styles, { isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "var(--clr--accent)"
          : isFocused
          ? "var(--clr--accent)"
          : undefined,
        color: isDisabled
          ? "var(--gray-300)"
          : isSelected
          ? "var(--gray-800)"
          : isFocused
          ? "var(--gray-100)"
          : undefined,
        cursor: isDisabled ? "not-allowed" : "default",
        ":hover": {
          ...styles[":hover"],
          color: !isDisabled ? "var(--gray-100)" : "var(--gray-300)",
        },
        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "var(--clr--accent)"
              : undefined
            : undefined,
        },
      };
    },
  };

  return (
    <FixRequiredSelect
      {...props}
      styles={genericSelectStyles}
      SelectComponent={BaseSelect}
      options={props.options || []}
      maxMenuHeight={200}
    />
  );
};

export default CustomSelect;
