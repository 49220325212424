import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../services/requests";
import { showToasts } from "../../../views/utils/toasts";
import { initFetchParams } from "../util";

export const getTemplates = createAsyncThunk(
  "templateSetting/getTemplates",
  async (data) => {
    const body = {
      page_no: data.pageNum,
      limit: data.limit,
    };
    const res = await post("/formbuilder/template_list", body);
    return res;
  }
);

export const saveTemplate = createAsyncThunk(
  "templateSetting/addTemplate",
  async (data, { dispatch }) => {
    const res = await post("/formbuilder/save_template", data);
    if (!res.error) dispatch(getTemplates(initFetchParams));
    return res;
  }
);

export const templateSettingSlice = createSlice({
  name: "templateSetting",
  initialState: {
    templates: { data: [], isPagination: false, totalCount: 0 },
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getTemplates.fulfilled, (state, action) => {
      state.templates.data = action.payload.data || [];
      state.templates.totalCount = Number(action.payload.count_all);
      state.templates.isPagination = action.payload.count_all > 10;
    });
    builder.addCase(saveTemplate.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
  },
});

export const selectTemplateList = (state) =>
  state.templateSetting.templates.data;
export const selectTemplateListCount = (state) =>
  state.templateSetting.templates.totalCount;
export const selectIsTemplateListPagination = (state) =>
  state.templateSetting.templates.isPagination;

export default templateSettingSlice.reducer;
