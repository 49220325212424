import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../services/requests";
import { showToasts } from "../../../views/utils/toasts";
import { initFetchParams } from "../util";

export const addLineManager = createAsyncThunk(
  "lineManager/addLineManager",
  async (data, { dispatch }) => {
    const body = {
      name: data.name,
      email: data.email,
      password: data.password,
      confirmPassword: data.confirmPassword,
      userType: "lineManager",
      lineId: data.lineId,
    };

    const res = await post("/user/add-user", body);
    // console.log("stage", state);
    if (!res.error) dispatch(getLineManagers(initFetchParams));

    return res;
  }
);
export const getLineManagers = createAsyncThunk(
  "lineManager/getLineManagers",
  async (data) => {
    const body = {
      userType: "lineManager",
      page_no: data.pageNum,
      limit: data.limit,
    };
    // console.log("body", body);
    const res = await post("/user/get-users", body);
    return res;
  }
);
 
export const lineManagerSlice = createSlice({
  name: "lineManager",
  initialState: {
    lineManagersData: [],
    totalCount: 0,
    isPagination: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(addLineManager.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
    builder.addCase(getLineManagers.fulfilled, (state, action) => {
      state.lineManagersData = action.payload.data || [];
      state.totalCount = Number(action.payload.count_all);
      state.isPagination = action.payload.count_all > 10;
    });
  },
});

export const selectLineManagers = (state) => state.lineManager.lineManagersData;
export const selectLineManagersCount = (state) => state.lineManager.totalCount;
export const selectIsLineManPagination = (state) =>
  state.lineManager.isPagination;

export default lineManagerSlice.reducer;
