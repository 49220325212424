import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  getDevices,
  getTags,
} from "../../../state/redux/assetSetting/assetSettingSlice";

import { initFetchParams } from "../../../state/redux/util";
import { getUserData } from "../../../services/functions";
import history from "../../../services/history";

import TagPanel from "./components/TagContent/components/TagPanel";
import DevicePanel from "./components/DeviceContent/components/DevicePanel";
import TagContent from "./components/TagContent/index";
import DeviceContent from "./components/DeviceContent/index";

const AssetSetting = () => {
  const dispatch = useDispatch();
  const { permissions } = getUserData();

  const [tab, setTab] = useState("tag");

  useEffect(() => {
    const fetchData = async () => {
      dispatch(getTags(initFetchParams));
      dispatch(getDevices(initFetchParams));
    };
    if (permissions.getTags && permissions.getDevices) fetchData();
    else history.push("/dashboard");
  }, []);

  return (
    <>
      <div className="asset-settings outer-wrapper">
        <div className="panel mb-3">
          <div className="panel__heading">
            <h2>Asset Settings</h2>
          </div>
          <div className="panel_secondary">
            <div
              className={` panel-btn-secondary ${
                tab === "tag" ? "bg-accent" : "bg-accent-outline"
              } w-5`}
              onClick={() => setTab("tag")}
            >
              Tag
            </div>
            <div
              className={`  panel-btn-secondary ${
                tab === "device" ? "bg-accent" : "bg-accent-outline"
              } w-5`}
              onClick={() => setTab("device")}
            >
              Device
            </div>
          </div>

          <div className="panel__items">
            <TagPanel isAddTag={permissions.addTag} />
            <DevicePanel isAddDevice={permissions.addDevice} setTab={setTab} />
          </div>
        </div>

        {tab === "tag" ? (
          <TagContent />
        ) : tab === "device" ? (
          <DeviceContent />
        ) : null}
      </div>
    </>
  );
};

export default AssetSetting;
