import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import $ from "jquery";
import { BsFillMoonStarsFill, BsFillSunFill } from "react-icons/bs";
import jwt_decode from "jwt-decode";
import history from "./../../services/history";
import logo from "./../../static/images/logo.png";
import PasswordModal from "./Modal/ChangePassword";
import { logout } from "../../state/redux/auth/authSlice";
import "../../static/css/toggleSwitch.css";
import { getUserData } from "../../services/functions";
const Header = ({ theme, toggleTheme, image }) => {
  const dispatch = useDispatch();
  const { userName, userEmail, userType } = getUserData();
  const changeRoute = () => {
    history.push("/dashboard/userProfile");
  };
  const _openModal = (id) => {
    $(".custom-modal-overlay").addClass("custom-modal-is-visible");
    $(`#${id}`).addClass("custom-modal-is-visible");
  };
  const _closeModal = () => {
    $(".custom-modal-overlay").removeClass("custom-modal-is-visible");
    $(".custom-modal").removeClass("custom-modal-is-visible");
  };
  function _logout() {
    dispatch(logout());
  }
  return (
    <header>
      <div className="headerpanel">
        <div className="logopanel">
          <h2>
            <Link to="/dashboard">
              <img src={logo} alt="logo" />
            </Link>
          </h2>
        </div>

        <div className="headerbar flex-jc-end aItemsCenter gap-one">
          <a
            id="menuToggle"
            className="menutoggle"
            href="abcd"
            onClick={(event) => event.preventDefault()}
          >
            <i className="fa fa-bars"></i>
          </a>

          {theme === "light" ? (
            <span className="theme-toggle" onClick={() => toggleTheme("dark")}>
              <BsFillSunFill className="sun-icon" />
            </span>
          ) : (
            <span className="theme-toggle" onClick={() => toggleTheme("light")}>
              <BsFillMoonStarsFill className="moon-icon" />
            </span>
          )}

          <div className="header-right">
            <ul className="headermenu">
              <li>
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-logged"
                    data-toggle="dropdown"
                    data-target="#headdp"
                    aria-expanded="false"
                  >
                    <img src={image} alt="" />
                    <div className="udeta">
                      <span className="n1">{userName}</span>
                      <span className="n2">{userEmail}</span>
                      <span className="n3">{userType}</span>
                    </div>
                    <span className="caret"></span>
                  </button>
                  <div className="user-dropmenu" id="headdp">
                    <ul>
                      <li onClick={changeRoute}>
                        <i className="fa fa-user" aria-hidden="true"></i>{" "}
                        Profile
                      </li>
                      <li
                        onClick={() => {
                          _openModal("changePasswordModal");
                        }}
                      >
                        <i className="fa fa-key" aria-hidden="true"></i> Change
                        Password
                      </li>
                      <li>
                        <i className="fa fa-cog" aria-hidden="true"></i> Setting
                      </li>
                      <li onClick={_logout}>
                        <i className="fa fa-sign-out" aria-hidden="true"></i>
                        Logout
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <PasswordModal closeModal={_closeModal} />
    </header>
  );
};

export default Header;
