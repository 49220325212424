import React from "react";
import { Link, useLocation } from "react-router-dom";

function CustomBreadcrumb() {
  const location = useLocation();
  let breadcrumbs = `${location.pathname}`.split("/");
  breadcrumbs.splice(0, 1);

  function _goTo(index) {
    let str = "";
    for (let j = 0; j <= index; j++) {
      str += `/${breadcrumbs[j]}`;
    }
    return str;
  }
  return (
    <>
      <ol className="cust-breadcrumb">
        {breadcrumbs.map((item, index) => {
          return (
            <li
              key={index}
              className={breadcrumbs.length - 1 === index ? "active" : ""}
            >
              <Link to={_goTo(index)}>
                <i className={index === 0 ? "fa fa-home mr5" : ""}></i>{" "}
                {item.replace(/-/g, " ").toUpperCase()}
              </Link>
            </li>
          );
        })}
      </ol>
    </>
  );
}
export default CustomBreadcrumb;
