import { useRef, useState } from "react";
import { Button, Form, Modal, Table } from "react-bootstrap";
import {
  AiFillEye,
  AiFillEdit,
  AiOutlineLink,
  AiFillPlusSquare,
  AiFillDelete,
  AiOutlineClear,
  AiFillCloseCircle,
} from "react-icons/ai";
import { FaLink, FaPlus, FaPlusCircle, FaUnlink } from "react-icons/fa";
import GenericModal from ".././Modals/GenericModal";
import GenericTable from "../../utils/GenericTable";
import { useSelector, useDispatch } from "react-redux";
import {
  addAccessory,
  addAsset,
  allocateTag,
  assignAccessory,
  deallocateTag,
  getAccessories,
  getAccessoryHistory,
  getAllAccessories,
  getAssetAccessories,
  getAssets,
  getAvailableTags,
  resetAsset,
  selectAccessories,
  selectAccessoryCount,
  selectAccessoryHistory,
  selectAccessoryHistoryCount,
  selectAllAccessories,
  selectAssetAccessories,
  selectAssetAccessoryCount,
  selectAssetCount,
  selectAssets,
  selectAvailableTags,
  selectIsAccessoryHistoryPagination,
  selectIsAccessoryPagination,
  selectIsAssetAccessoryPagination,
  selectIsAssetPagination,
  transferAccessory,
} from "../../../state/redux/assetAllocation/assetAllocationSlice";
import { useEffect } from "react";
import moment from "moment";
import {
  getTags,
  selectTags,
} from "../../../state/redux/assetSetting/assetSettingSlice";
import CustomSelect from "../../utils/CustomSelect";
import history from "../../../services/history";
import { validate_form } from "../../../services/validation";
import { initFetchParams } from "../../../state/redux/util";
import CustomPagination from "../../utils/CustomPagination";
import {
  getOptions,
  getUserData,
  setOptions,
} from "../../../services/functions";
import { useMemo } from "react";
import { toast } from "react-toastify";
import SerialNoList from "../../utils/SerialNoList";

const initialSubAssetFilterData = {
  search: "",
  searchBy: "",
};

const AssetAllocation = () => {
  const dispatch = useDispatch();

  const accessories = useSelector(selectAccessories);
  const accessoryCount = useSelector(selectAccessoryCount);
  const isAccessoryPagination = useSelector(selectIsAccessoryPagination);

  const accessoryHistory = useSelector(selectAccessoryHistory);
  const accessoryHistoryCount = useSelector(selectAccessoryHistoryCount);
  const isAccessoryHistoryPagination = useSelector(
    selectIsAccessoryHistoryPagination
  );

  const { permissions } = getUserData();

  const [tab, setTab] = useState("assets");

  const [isViewAssetAccessories, setIsViewAssetAccessories] = useState(null);
  const [isViewLogs, setIsViewLogs] = useState(null);

  const [isViewSerialNo, setIsViewSerialNo] = useState(false);
  const serialNos = useRef(null);

  const [subAssetFilterData, setSubAssetFilterData] = useState(
    initialSubAssetFilterData
  );

  const handleViewLogs = (id) => {
    // setCurrentAccessoriesView((old) => ({ ...old, [id]: old[id] ? "" : id }));
    dispatch(getAccessoryHistory({ ...initFetchParams, id }));
    setTab("");
    setIsViewLogs(id);
  };

  function findSerialNos(index) {
    // console.log();
    serialNos.current = accessoryHistory.find(
      (_, indx) => index === indx
    ).serialNo;
    // setSerialNos(accessoryHistory.find((_, indx) => index === indx).serialNo);
    setIsViewSerialNo(true);
  }
  // console.log(serialNos);
  const logsTableData = accessoryHistory.map((obj, index) => {
    // console.log(obj);
    let objArr = [];
    objArr.push(
      index + 1,
      obj.accessoryName,
      [
        <div className="previousAssetDetails pRel">
          <div className="previousAssetName font-weight-bold">
            {obj.oldAssetName || "NA"}
          </div>
          <div className="previousAsset-qty ">
            <span>Previous Qty: </span>
            <span
              className="prevQty font-weight-bold"
              style={{
                cursor: "pointer",
                display: "inline-flex",
                gap: "0.25em",
                alignItems: "center",
              }}
            >
              {obj.type === "reset"
                ? obj.quantity
                : obj.type === "new"
                ? "NA"
                : obj.oldAssetQuantity
                ? obj.oldAssetQuantity
                : "NA"}
            </span>
          </div>
          <div className="previousAsset-qtyUpdated ">
            <span>Updated Qty: </span>
            <span className="updatedQty font-weight-bold">
              {obj.type === "reset"
                ? "NA"
                : obj.type === "new"
                ? "NA"
                : obj.oldAssetQuantity
                ? obj.oldAssetQuantity - obj.quantity
                : "NA"}
            </span>
          </div>
          {obj.type === "reset" || obj.type === "transfer" ? (
            <AiFillEye
              className="view-icon icons serialNo_eye"
              title="view components"
              onClick={() => findSerialNos(index)}
            />
          ) : null}
        </div>,
      ],
      [
        <div className="nextAssetDetails pRel">
          <div className="nextAssetName font-weight-bold ">
            {obj.newAssetName || "NA"}
          </div>
          <div className="nextAsset-qty ">
            <span>Previous Qty: </span>
            <span className="prevQty font-weight-bold">
              {obj.type === "reset"
                ? "NA"
                : obj.type === "new"
                ? "NA"
                : obj.newAssetQuantity
                ? obj.newAssetQuantity
                : "NA"}
            </span>
          </div>
          <div className="nextAsset-qtyUpdated ">
            <span>Updated Qty: </span>
            <span className="updatedQty font-weight-bold">
              {obj.type === "reset"
                ? "NA"
                : obj.type === "new"
                ? obj.quantity
                : obj.newAssetQuantity
                ? obj.newAssetQuantity + obj.quantity
                : "NA"}
            </span>
          </div>
          {obj.type === "new" || obj.type === "transfer" ? (
            <AiFillEye
              className="view-icon icons serialNo_eye"
              title="view components"
              onClick={() => findSerialNos(index)}
            />
          ) : null}
        </div>,
      ],
      [<div className="userName font-weight-bold">{obj.name}</div>],
      [
        <div className="addedOn">
          {moment(obj.addedOn).format("DD/MM/YYYY, h:mm a")}
        </div>,
      ]
    );
    return objArr;
  });

  const accessoryTableData = accessories.map((obj, index) => {
    let objArr = [];
    objArr.push(
      index + 1,
      obj.name,
      obj.description || "NA",
      obj.userName || "NA",
      moment(obj.addedOn).format("DD/MM/YYYY, h:mm a"),
      [
        <div className="action-icons">
          <button
            className="accessoryTransfer-btn"
            onClick={() => handleViewLogs(obj._id)}
          >
            View Logs
          </button>
        </div>,
      ]
    );
    return objArr;
  });

  //filter

  const handleSubAssetFilterFormChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;
    setSubAssetFilterData((safd) => ({ ...safd, [name]: value }));
  };
  const handleSubAssetFilterFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(getAccessories({ ...initFetchParams, ...subAssetFilterData }));
  };

  // assets on component load
  useEffect(() => {
    const fetchData = async () => {
      dispatch(getAssets(initFetchParams));
      dispatch(getAccessories(initFetchParams));
      dispatch(getAllAccessories());
      // dispatch(getAvailableTags());
    };
    if (permissions.getAssets) fetchData();
    else history.push("/dashboard");
  }, []);

  const handleSerialNoModalClose = () => {
    serialNos.current = null;
    setIsViewSerialNo(false);
  };
  return (
    <>
      <div className="asset-settings outer-wrapper">
        <div className="panel mb-3">
          <div className="panel__heading">
            <h2>Master Asset Allocation</h2>
          </div>
          {isViewAssetAccessories ? (
            <button
              className="assetAccessories-backBtn"
              onClick={() => {
                setIsViewAssetAccessories(false);
                setTab("assets");
              }}
            >
              Back
            </button>
          ) : (
            <>
              <div className="panel_secondary">
                <div
                  className={` panel-btn-secondary ${
                    tab === "assets" ? "bg-accent" : "bg-accent-outline"
                  } w-10`}
                  onClick={() => {
                    setTab("assets");
                    // setSubAssetFilterData(initialSubAssetFilterData);
                  }}
                >
                  Master Assets
                </div>
                <div
                  className={`  panel-btn-secondary ${
                    tab === "subAssets" ? "bg-accent" : "bg-accent-outline"
                  } w-10`}
                  onClick={() => {
                    setTab("subAssets");
                    // setAssetFilterData(initialAssetFilterData);
                  }}
                >
                  Components
                </div>
              </div>
              <div className="panel__items">
                <AssetPanel isAddAsset={permissions.addAsset} />
                <AccessoryPanel isAddAccessory={permissions.addAccessory} />
              </div>
            </>
          )}
        </div>
        {tab === "subAssets" ? (
          <Form
            className="filterForm "
            onSubmit={handleSubAssetFilterFormSubmit}
          >
            <Form.Group className="" controlId="formDeviceSearchType">
              <Form.Label className="">Search By</Form.Label>
              <CustomSelect
                className="modal__form__select"
                options={[
                  {
                    value: "",
                    label: "--select--",
                  },
                  {
                    value: "name",
                    label: "Component",
                  },
                  {
                    value: "description",
                    label: "Description",
                  },
                ]}
                name="searchBy"
                value={{
                  label:
                    subAssetFilterData.searchBy === "name"
                      ? "Component"
                      : subAssetFilterData.searchBy === "description"
                      ? "Description"
                      : "--select--",
                }}
                onChange={handleSubAssetFilterFormChange}
              />
            </Form.Group>
            <Form.Group className="filterForm__input-wrapper">
              <Form.Label>Enter Search</Form.Label>
              <Form.Control
                placeholder="type to search..."
                type="text"
                value={subAssetFilterData.search}
                name="search"
                onChange={handleSubAssetFilterFormChange}
              />
            </Form.Group>

            <button className="filterForm__submit-btn">Apply</button>
          </Form>
        ) : null}

        {tab === "assets" ? (
          <>
            <AssetContent
              isViewAssetAccessories={isViewAssetAccessories}
              setIsViewAssetAccessories={setIsViewAssetAccessories}
            />
          </>
        ) : tab === "subAssets" ? (
          <>
            <GenericTable
              headings={[
                "S.No",
                "Component Name",
                "Description",
                "Added By",
                "Added On",
                "Action",
              ]}
              data={accessoryTableData}
            />
            <CustomPagination
              totalCount={accessoryCount}
              isPagination={isAccessoryPagination}
              fetchData={getAccessories}
              extraParams={subAssetFilterData}
            />
          </>
        ) : isViewLogs ? (
          <div className="accessoryLogsWrapper">
            <button
              className="accessoryLogs-backBtn"
              onClick={() => {
                setIsViewLogs(false);
                setTab("subAssets");
              }}
            >
              Back
            </button>
            <GenericTable
              headings={[
                "S.No",
                "Component Name",
                "From",
                "To",
                "Transferred By",
                "Transferred On",
              ]}
              data={logsTableData}
            />
            <CustomPagination
              totalCount={accessoryHistoryCount}
              isPagination={isAccessoryHistoryPagination}
              fetchData={getAccessoryHistory}
              id={isViewLogs}
            />
          </div>
        ) : null}
        {isViewAssetAccessories ? <AssetAccessoryContent /> : null}

        {isViewSerialNo ? (
          <SerialNoList
            isModal={isViewSerialNo}
            handleModalClose={handleSerialNoModalClose}
            data={serialNos.current}
          />
        ) : null}
      </div>
    </>
  );
};

export default AssetAllocation;

//Asset Content
const initialFilterFormData = {
  search: "",
  searchBy: "",
};
const AssetContent = ({
  isViewAssetAccessories,
  setIsViewAssetAccessories,
}) => {
  const dispatch = useDispatch();
  const assets = useSelector(selectAssets);

  const [isAllocatePassiveTagModal, setIsAllocatePassiveTagModal] =
    useState(false);
  const [isAllocateBarcodeModal, setIsAllocateBarcodeModal] =
    useState(false);
  const [isAllocateActiveTagModal, setIsAllocateActiveTagModal] =
    useState(false);
  const [isAssignAccessoryModal, setIsAssignAccessoryModal] = useState(false);

  const [assetId, setAssetId] = useState("");

  const [filterFormData, setFilterFormData] = useState(initialFilterFormData);

  const permissions = getUserData(
    localStorage.getItem("secretkey")
  ).permissions;

  const handleAllocateTag = (e, tagType) => {
    const id =
      e.target.closest(".passiveTag-action")?.dataset?.assetid ||
      e.target.closest(".activeTag-action")?.dataset?.assetid ||
      e.target.closest(".barcode-action")?.dataset?.assetid;

    setAssetId(id);
    //   setAssetData((oldData) => ({ ...oldData, assetId }));
    // fetchAndSetTagOptions();

    // console.log(assetId, tagType);
    if (tagType === "Passive") setIsAllocatePassiveTagModal(true);
    if (tagType === "Active") setIsAllocateActiveTagModal(true);
    if (tagType === "Barcode") setIsAllocateBarcodeModal(true);
  };
  const handleDeallocateTag = (e, tagType) => {
    const assetId =
      e.target.closest(".passiveTag-action")?.dataset?.assetid ||
      e.target.closest(".activeTag-action")?.dataset?.assetid ||
      e.target.closest(".barcode-action")?.dataset?.assetid;

    dispatch(deallocateTag({ assetId, tagType }));
  };
  const handleViewAccessories = (id) => {
    dispatch(getAssetAccessories({ ...initFetchParams, id }));
    // setTab("");
    setIsViewAssetAccessories(id);
  };
  const handleAssignAccessoryModalOpen = (id) => {
    setAssetId(id);
    setIsAssignAccessoryModal(true);
  };
  const handleResetAsset = (assetId) => {
    dispatch(resetAsset(assetId));
  };
  const handleRemoveAccessoryModalOpen = (assetId) => {
    const Confirm = ({ closeToast, toastProps }) => {
      return (
        <div className="confirmation-toast">
          Are you sure you want to remove all components from this master asset?
          <button
            className="confirmation-toast-btn toast-confirm"
            onClick={() => {
              handleResetAsset(assetId);
              closeToast();
            }}
          >
            Yes, remove all components.
          </button>
          <button
            onClick={closeToast}
            className="confirmation-toast-btn toast-cancel"
          >
            No, go back.
          </button>
        </div>
      );
    };
    toast.warn(Confirm);
  };

  const assetTableData = assets.map((obj, index) => {
    let objArr = [];
    let id = obj._id;
    let dataKey = "assetid";
    let currentStatus = obj.status;
    objArr.push(
      index + 1,
      obj.name,
      obj.description || "NA",
      [
        <div
          className="activeTag-action d-flex space-between "
          data-assetid={obj._id}
        >
          <span className="mAuto"> {obj.activeTagNo || "NA"}</span>

          {obj.activeTagNo ? (
            <FaLink
              className="deallocate-icon icons "
              onClick={(e) => handleDeallocateTag(e, "Active")}
            />
          ) : (
            <FaLink
              className="allocate-icon icons"
              onClick={(e) => handleAllocateTag(e, "Active")}
            />
          )}
        </div>,
      ],
      [
        <div
          className="passiveTag-action d-flex space-between"
          data-assetid={obj._id}
        >
          <span className="mAuto">
            {obj.status === "Pending" ? "NA" : obj.tagNo}
          </span>

          {permissions.allocateTag &&
            permissions.deallocateTag &&
            (obj.status === "Allocated" ? (
              <FaLink
                className="deallocate-icon icons"
                onClick={(e) => handleDeallocateTag(e, "Passive")}
              />
            ) : (
              <FaLink
                className="allocate-icon icons"
                onClick={(e) => handleAllocateTag(e, "Passive")}
              />
            ))}
        </div>,
      ],
      [
        <div
          className="barcode-action d-flex space-between "
          data-assetid={obj._id}
        >
          <span className="mAuto"> {obj.barcodeNo || "NA"}</span>

          {obj.barcodeNo ? (
            <FaLink
              className="deallocate-icon icons "
              onClick={(e) => handleDeallocateTag(e, "Barcode")}
            />
          ) : (
            <FaLink
              className="allocate-icon icons"
              onClick={(e) => handleAllocateTag(e, "Barcode")}
            />
          )}
        </div>,
      ],
      obj.status,
      moment(obj.addedOn).format("DD/MM/YYYY, h:mm a"),
      // action(handleAssetEdit, handleAssetDelete, id, dataKey, currentStatus),
      [
        <div className="action-icons" data-assetid={obj._id}>
          <span
            onClick={() => handleViewAccessories(obj._id)}
            title="view components"
          >
            <AiFillEye className="view-icon icons" />
          </span>
          <span
            onClick={() => handleAssignAccessoryModalOpen(obj._id)}
            title="assign component"
          >
            <FaPlusCircle className="view-icon icons" />
          </span>
          <span
            onClick={() => handleRemoveAccessoryModalOpen(obj._id)}
            title="remove all components"
          >
            <AiOutlineClear className="view-icon icons" />
          </span>
        </div>,
      ]
    );
    return objArr;
  });

  return (
    <>
      {isViewAssetAccessories ? null : (
        <>
          <AssetFilter
            formData={filterFormData}
            setFormData={setFilterFormData}
          />
          <AssetTable
            assetTableData={assetTableData}
            filterData={filterFormData}
          />
        </>
      )}
      {isAllocateActiveTagModal ? (
        <ActiveTagModal
          assetId={assetId}
          isModal={isAllocateActiveTagModal}
          setIsModal={setIsAllocateActiveTagModal}
        />
      ) : null}

      {isAllocatePassiveTagModal ? (
        <PassiveTagModal
          assetId={assetId}
          isModal={isAllocatePassiveTagModal}
          setIsModal={setIsAllocatePassiveTagModal}
        />
      ) : null}

      {isAllocateBarcodeModal ? (
        <BarcodeModal
          assetId={assetId}
          isModal={isAllocateBarcodeModal}
          setIsModal={setIsAllocateBarcodeModal}
        />
      ) : null}

      {isAssignAccessoryModal ? (
        <AssignAccessoryModal
          assetId={assetId}
          isModal={isAssignAccessoryModal}
          setIsModal={setIsAssignAccessoryModal}
        />
      ) : null}
    </>
  );
};

//Asset Accessory Content

const initialTransferAccessoryData = {
  assetId: "",
  targetAssetId: "",
  accessoryId: "",
  qty: "",
  isFormValidated: false,
  isTargetSelectValid: false,
  targetAssetOptions: [],
  errors: {
    qty: "",
  },
};
const AssetAccessoryContent = () => {
  const assets = useSelector(selectAssets);
  const assetAccessories = useSelector(selectAssetAccessories);
  const [isViewSerialNo, setIsViewSerialNo] = useState(false);
  const serialNos = useRef(null);
  const [transferAccessoryData, setTransferAccessoryData] = useState(
    initialTransferAccessoryData
  );
  const [isTransferAccessoryModal, setIsTransferAccessoryModal] =
    useState(false);

  const handleTransferAccessoryModalOpen = (
    assetId,
    accessoryId,
    qty,
    currentAssetName
  ) => {
    const targetAssetOptions = getOptions(assets).filter(
      (option) => option.label !== currentAssetName
    );

    setTransferAccessoryData((td) => ({
      ...td,
      assetId,
      accessoryId,
      maxQty: qty,
      targetAssetOptions,
    }));
    setIsTransferAccessoryModal(true);
  };
  const resetTransferAccessoryFormData = () => {
    setTransferAccessoryData(initialTransferAccessoryData);
  };

  function findSerialNos(index) {
    // console.log(assetAccessories);
    serialNos.current = assetAccessories.find(
      (_, indx) => index === indx
    ).serialNo;
    // console.log(serialNos);
    setIsViewSerialNo(true);
  }
  const handleSerialNoModalClose = () => {
    serialNos.current = null;
    setIsViewSerialNo(false);
  };
  const assetAccessoriesTableData = assetAccessories.map((obj, index) => {
    let objArr = [];
    objArr.push(
      index + 1,
      [
        <div
          key={`comp-${index}`}
          style={{
            display: "inline-grid",
            gridTemplateColumns: "1fr 3em",
            gridTemplateRows: "auto",
            overflowWrap: "anywhere",
            gap: "0.25em",
            alignItems: "center",
            width: "100%",
          }}
        >
          {obj.accessoryName}
          <AiFillEye
            className="view-icon icons"
            title="view components"
            onClick={() => findSerialNos(index)}
            style={{ justifySelf: "end" }}
          />
        </div>,
      ],
      obj.description || "NA",
      obj.quantity,
      [
        <div className="action-icons">
          <button
            className="accessoryTransfer-btn"
            onClick={() =>
              handleTransferAccessoryModalOpen(
                obj.assetId,
                obj.accessoryId,
                obj.quantity,
                obj.assetName
              )
            }
          >
            Transfer
          </button>

          {/* <AiFillEdit
                className="edit-icon icons"
                id={obj._id}
                onClick={handleEdit}
              /> */}
        </div>,
      ]
    );
    return objArr;
  });

  return (
    <>
      <AssetAccessoryTable tableData={assetAccessoriesTableData} />
      <TransferAccessoryModal
        isModal={isTransferAccessoryModal}
        setIsModal={setIsTransferAccessoryModal}
        formData={transferAccessoryData}
        setFormData={setTransferAccessoryData}
        resetData={resetTransferAccessoryFormData}
      />

      {isViewSerialNo ? (
        <SerialNoList
          isModal={isViewSerialNo}
          handleModalClose={handleSerialNoModalClose}
          data={serialNos.current}
        />
      ) : null}
    </>
  );
};

const AssetAccessoryTable = ({ id, tableData }) => {
  const assetAccessoryCount = useSelector(selectAssetAccessoryCount);
  const isAssetAccessoryPagination = useSelector(
    selectIsAssetAccessoryPagination
  );
  return (
    <div className="assetAccessoriesWrapper">
      <GenericTable
        headings={["S.No", "Component Name", "Description", "Qty", "Action"]}
        data={tableData}
      />
      <CustomPagination
        totalCount={assetAccessoryCount}
        isPagination={isAssetAccessoryPagination}
        fetchData={getAssetAccessories}
        extraParams={{ id }}
      />
    </div>
  );
};

const TransferAccessoryModal = ({
  isModal,
  setIsModal,
  formData,
  setFormData,
  resetData,
}) => {
  const dispatch = useDispatch();
  const handleModalClose = () => {
    resetData();
    setIsModal(false);
  };
  const handleTransferAccessoryDataChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;
    const tempErrors = { ...formData.errors };
    const errors = validate_form({
      tempErrors,
      name,
      value,
    });
    if (name === "targetAssetId") {
      setFormData((fd) => ({
        ...fd,
        isTargetSelectValid: fd.isFormValidated ? true : null,
      }));
    }
    setFormData((fd) => ({
      ...fd,
      [name]: value,
      errors,
    }));
  };
  const handleTransferAccessoryFormSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      try {
        dispatch(transferAccessory(formData));
        handleModalClose();
      } catch (err) {}
    } else
      setFormData((fd) => ({
        ...fd,
        isFormValidated: true,
        isTargetSelectValid: !!fd.targetAssetId,
      }));
  };
  return (
    <>
      <GenericModal
        size="md"
        isModal={isModal}
        handleClose={handleModalClose}
        title="Component Transfer"
        body={addTransferAccessoryBody}
        data={formData}
        modalInputflexBasis="50%"
        fns={{
          onChange: handleTransferAccessoryDataChange,
          onSubmit: handleTransferAccessoryFormSubmit,
        }}
      />
    </>
  );
};

function addTransferAccessoryBody(data, fns) {
  return (
    <Form
      className="modal__form pRel"
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      onSubmit={fns.onSubmit}
    >
      <Form.Group className="mb-3" controlId="formQty">
        <Form.Label className="required">Enter Quantity </Form.Label>
        <Form.Control
          required
          type="number"
          // placeholder="Enter Asset Name"
          name="qty"
          min="0"
          className="transferQty"
          data-max={data.maxQty}
          max={data.maxQty}
          value={data.qty}
          onChange={fns.onChange}
        />
        <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        {data.errors.qty ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.qty}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please enter quantity
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formTargetAsset">
        <Form.Label className="required">Select Target Master Asset</Form.Label>
        <CustomSelect
          isValid={data.isTargetSelectValid}
          required
          className="modal__form__select"
          options={[
            {
              value: "",
              label: "--select target asset--",
              isDisabled: true,
            },
            ...data.targetAssetOptions,
          ]}
          name="targetAssetId"
          // defaultValue={data.tagId}
          onChange={fns.onChange}
        />
      </Form.Group>
    </Form>
  );
}

const AssetFilter = ({ formData, setFormData }) => {
  const dispatch = useDispatch();

  const handleFormChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;
    setFormData((fd) => ({ ...fd, [name]: value }));
  };
  const handleFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(getAssets({ ...initFetchParams, ...formData }));
  };
  return (
    <>
      <Form className="filterForm " onSubmit={handleFormSubmit}>
        <Form.Group className="" controlId="formTagType">
          <Form.Label className="">Search By</Form.Label>
          <CustomSelect
            className="modal__form__select"
            options={[
              {
                value: "",
                label: "--select--",
              },
              {
                value: "name",
                label: "Master Asset",
              },
              {
                value: "description",
                label: "Description",
              },
            ]}
            name="searchBy"
            value={{
              label:
                formData.searchBy === "name"
                  ? "Master Asset"
                  : formData.searchBy === "description"
                  ? "Description"
                  : "--select--",
            }}
            onChange={handleFormChange}
          />
        </Form.Group>
        <Form.Group className="filterForm__input-wrapper">
          <Form.Label>Enter Search</Form.Label>
          <Form.Control
            placeholder="type to search..."
            aria-label="Search by master asset"
            aria-describedby="basic-addon2"
            type="text"
            value={formData.search}
            name="search"
            onChange={handleFormChange}
          />
        </Form.Group>

        <button className="filterForm__submit-btn">Apply</button>
      </Form>
    </>
  );
};

const AssetTable = ({ assetTableData, filterData }) => {
  const assetCount = useSelector(selectAssetCount);
  const isAssetPagination = useSelector(selectIsAssetPagination);

  return (
    <>
      <GenericTable
        headings={[
          "S.No",
          "Master Asset Name",
          "Description",
          "Tag Display Name (Active)",
          "Tag Display Name (Passive)",
          "Barcode Display Name",
          "Status",
          "Added On",
          "Action",
        ]}
        data={assetTableData}
      />
      <CustomPagination
        totalCount={assetCount}
        isPagination={isAssetPagination}
        fetchData={getAssets}
        extraParams={filterData}
      />
    </>
  );
};

//Asset Panel

const initialAssetPanelFormData = {
  name: "",
  assetId: "",
  description: "",
  activeTagId: "",
  passiveTagId: "",
  barcodeId:"",
  isFormValidated: false,
  isActiveTagSelectValid: false,
  isPassiveTagSelectValid: false,
  isBarcodeSelectValid: false,
  availableActiveTagOptions: [],
  availablePassiveTagOptions: [],
  availableBarcodeOptions: [],
  errors: {
    name: "",
  },
};
const AssetPanel = ({ isAddAsset }) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialAssetPanelFormData);

  const [isFormModal, setIsFormModal] = useState(false);

  const handleModalOpen = () => {
    fetchAndSetTagOptions();
    setIsFormModal(true);
  };
  const handleModalClose = () => {
    setFormData(initialAssetPanelFormData);
    setIsFormModal(false);
  };
  const handleFormChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;
    const tempErrors = { ...formData.errors };
    const errors = validate_form({
      tempErrors,
      name,
      value,
    });

    if (name === "activeTagId") {
      setFormData((fd) => ({
        ...fd,
        isActiveTagSelectValid: fd.isFormValidated ? true : null,
      }));
    }
    if (name === "passiveTagId") {
      setFormData((fd) => ({
        ...fd,
        isPassiveTagSelectValid: fd.isFormValidated ? true : null,
      }));
    }
    if (name === "barcodeId") {
      setFormData((fd) => ({
        ...fd,
        isBarcodeSelectValid: fd.isFormValidated ? true : null
      }));
    }

    setFormData((fd) => ({
      ...fd,
      [name]: value,
      errors,
    }));
  };
  const handleFormSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      try {
        dispatch(addAsset(formData));
        handleModalClose();
      } catch (err) {}
    } else
      setFormData((fd) => ({
        ...fd,
        isFormValidated: true,
        isTagSelectValid: !!fd.tagId,
      }));
  };
  function fetchAndSetTagOptions() {
    dispatch(getAvailableTags("Passive")).then((res) => {
      setOptions(res.payload.data, "availablePassiveTagOptions");
    });
    dispatch(getAvailableTags("Active")).then((res) => {
      setOptions(res.payload.data, "availableActiveTagOptions");
    });
    dispatch(getAvailableTags("Barcode")).then((res) => {
      setOptions(res.payload.data, "availableBarcodeOptions");
    });
  }
  function setOptions(data, optionName) {
    let options = [];
    // console.log(optionName);
    if (data) options = getOptions(data);
    // else options = [{ label: "--no data found--" }];

    setFormData((fd) => ({
      ...fd,
      [optionName]: options,
    }));
  }
  return (
    <>
      {isAddAsset ? (
        <button
          className="panel-btn asset-settings__add-asset-btn"
          onClick={handleModalOpen}
        >
          Add Master Asset
          <AiFillPlusSquare className="icons panel__icon" />
        </button>
      ) : null}

      {isFormModal ? (
        <GenericModal
          size="md"
          isModal={isFormModal}
          handleClose={handleModalClose}
          title="Add New Master Asset"
          body={addAssetPanelForm}
          data={formData}
          modalInputflexBasis="30%"
          fns={{
            onChange: handleFormChange,
            onSubmit: handleFormSubmit,
          }}
        />
      ) : null}
    </>
  );
};

function addAssetPanelForm(data, fns) {
  return (
    <Form
      className="modal__form pRel"
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      onSubmit={fns.onSubmit}
    >
      <small className="pAbs" style={{ top: "-25px" }}>
        *You can select either one or both
      </small>
      <Form.Group className="mb-3" controlId="formActiveTag">
        <Form.Label> Select Active Tag</Form.Label>
        <CustomSelect
          isValid={data.isActiveTagSelectValid}
          className="modal__form__select"
          options={[
            {
              value: "",
              label: "--select active tag--",
              isDisabled: true,
            },
            ...data.availableActiveTagOptions,
          ]}
          name="activeTagId"
          // defaultValue={data.tagId}
          onChange={fns.onChange}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formPassiveTag">
        <Form.Label> Select Passive Tag</Form.Label>
        <CustomSelect
          isValid={data.isPassiveTagSelectValid}
          className="modal__form__select"
          options={[
            {
              value: "",
              label: "--select passive tag--",
              isDisabled: true,
            },
            ...data.availablePassiveTagOptions,
          ]}
          name="passiveTagId"
          // defaultValue={data.tagId}
          onChange={fns.onChange}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBarcode">
        <Form.Label> Select Barcode</Form.Label>
        <CustomSelect
          isValid={data.isBarcodeSelectValid}
          className="modal__form__select"
          options={[
            {
              value: "",
              label: "--select barcode--",
              isDisabled: true,
            },
            ...data.availableBarcodeOptions,
          ]}
          name="barcodeId"
          // defaultValue={data.tagId}
          onChange={fns.onChange}
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formName">
        <Form.Label className="required">Enter Master Asset Name</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Asset Name"
          name="name"
          minLength="60"
          value={data.name}
          onChange={fns.onChange}
        />
        <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        {data.errors.name ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.name}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please enter asset name
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group
        className="mb-3 addLineManager__desc"
        controlId="formDescription"
      >
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Add a description here"
          style={{ height: "100px" }}
          name="description"
          value={data.description}
          onChange={fns.onChange}
        />
      </Form.Group>
    </Form>
  );
}

// Accessory Panel

const initialAccessoryPanelFormData = {
  name: "",
  description: "",
  isFormValidated: false,
  errors: {
    name: "",
  },
};
const AccessoryPanel = ({ isAddAccessory }) => {
  const dispatch = useDispatch();

  const [formData, setFormData] = useState(initialAccessoryPanelFormData);
  const [isFormModal, setIsFormModal] = useState(false);

  const handleModalOpen = () => {
    setIsFormModal(true);
  };
  const handleModalClose = () => {
    setFormData(initialAccessoryPanelFormData);
    setIsFormModal(false);
  };

  const handleFormChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;
    const tempErrors = { ...formData.errors };
    const errors = validate_form({
      tempErrors,
      name,
      value,
    });

    setFormData((fd) => ({
      ...fd,
      [name]: value,
      errors,
    }));
  };
  const handleFormSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      try {
        dispatch(addAccessory(formData));
        handleModalClose();
      } catch (err) {}
    } else
      setFormData((fd) => ({
        ...fd,
        isFormValidated: true,
      }));
  };
  return (
    <>
      {isAddAccessory ? (
        <button
          className="panel-btn asset-settings__add-subAsset-btn"
          onClick={handleModalOpen}
        >
          Add Component
          <AiFillPlusSquare className="icons panel__icon" />
        </button>
      ) : null}
      {isFormModal ? (
        <GenericModal
          size="md"
          isModal={isFormModal}
          handleClose={handleModalClose}
          title="Add New Component"
          body={addAccessoryPanelForm}
          data={formData}
          modalInputflexBasis="50%"
          fns={{
            onChange: handleFormChange,
            onSubmit: handleFormSubmit,
          }}
        />
      ) : null}
    </>
  );
};

function addAccessoryPanelForm(data, fns) {
  return (
    <Form
      className="modal__form"
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      onSubmit={fns.onSubmit}
    >
      <Form.Group className="mb-3" controlId="formName">
        <Form.Label className="required">Enter Name</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter Component Name"
          name="name"
          minLength="60"
          value={data.name}
          onChange={fns.onChange}
        />
        <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        {data.errors.name ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.name}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please enter component name
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group
        className="mb-3 addLineManager__desc"
        controlId="formDescription"
      >
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Add a description here"
          style={{ height: "100px" }}
          name="description"
          value={data.description}
          onChange={fns.onChange}
        />
      </Form.Group>
    </Form>
  );
}

//active tag modal
const initialActiveFormData = {
  activeTagId: "",
  options: [],
  isFormValidated: false,
  isSelectValid: false,
};
const ActiveTagModal = ({ isModal, setIsModal, assetId }) => {
  // console.log("ActiveTagModal", assetId);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialActiveFormData);
  const handleModalClose = () => {
    setIsModal(false);
  };
  const handleFormChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;

    if (name === "activeTagId") {
      setFormData((fd) => ({
        ...fd,
        isSelectValid: fd.isFormValidated ? true : null,
      }));
    }

    setFormData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  };
  const handleFormSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === true) {
      try {
        dispatch(allocateTag({ ...formData, assetId }));
        handleModalClose();
      } catch (err) {}
    } else
      formData((fd) => ({
        ...fd,
        isFormValidated: true,
        isSelectValid: !!fd.activeTagId,
      }));
  };

  useEffect(() => {
    dispatch(getAvailableTags("Active")).then((res) => {
      setOptions(res.payload.data, "options", setFormData);
      //    res.payload.data?.length > 0 &&
      //      setAssetData((oldData) => ({
      //        ...oldData,
      //        availableActiveTagOptions: getOptions(res.payload.data),
      //      }));
    });
  }, []);
  return (
    <>
      <GenericModal
        size="md"
        isModal={isModal}
        handleClose={handleModalClose}
        title="Allocate Active Tag"
        body={addActiveTagForm}
        data={formData}
        modalInputflexBasis="50%"
        fns={{
          onChange: handleFormChange,
          onSubmit: handleFormSubmit,
        }}
      />
    </>
  );
};
function addActiveTagForm(data, fns) {
  return (
    <Form
      className="modal__form pRel"
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      onSubmit={fns.onSubmit}
    >
      <Form.Group className="mb-3" controlId="formActiveTag">
        <Form.Label className="required"> Select Active Tag</Form.Label>
        <CustomSelect
          isValid={data.isSelectValid}
          required
          className="modal__form__select"
          options={[
            {
              value: "",
              label: "--select active tag--",
              isDisabled: true,
            },
            ...data.options,
          ]}
          name="activeTagId"
          onChange={fns.onChange}
        />
      </Form.Group>
    </Form>
  );
}

//barcode modal
const initialBarcodeFormData = {
  barcodeId: "",
  options: [],
  isFormValidated: false,
  isSelectValid: false,
};
const BarcodeModal = ({ isModal, setIsModal, assetId }) => {
  // console.log("ActiveTagModal", assetId);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialBarcodeFormData);
  const handleModalClose = () => {
    setIsModal(false);
  };
  const handleFormChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;
    if (name === "barcodeId") {
      setFormData((fd) => ({
        ...fd,
        isSelectValid: fd.isFormValidated ? true : null,
      }));
    }

    setFormData((oldData) => ({
      ...oldData,
      [name]: value,
    }));
  };
  const handleFormSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === true) {
      try {
        dispatch(allocateTag({ ...formData, assetId }));
        handleModalClose();
      } catch (err) {}
    } else
      formData((fd) => ({
        ...fd,
        isFormValidated: true,
        isSelectValid: !!fd.barcodeId,
      }));
  };

  useEffect(() => {
    dispatch(getAvailableTags("Barcode")).then((res) => {
      setOptions(res.payload.data, "options", setFormData);
      //    res.payload.data?.length > 0 &&
      //      setAssetData((oldData) => ({
      //        ...oldData,
      //        availableActiveTagOptions: getOptions(res.payload.data),
      //      }));
    });
  }, []);
  return (
    <>
      <GenericModal
        size="md"
        isModal={isModal}
        handleClose={handleModalClose}
        title="Allocate Barcode"
        body={addBarcodeForm}
        data={formData}
        modalInputflexBasis="50%"
        fns={{
          onChange: handleFormChange,
          onSubmit: handleFormSubmit,
        }}
      />
    </>
  );
};
function addBarcodeForm(data, fns) {
  return (
    <Form
      className="modal__form pRel"
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      onSubmit={fns.onSubmit}
    >
      <Form.Group className="mb-3" controlId="formBarcode">
        <Form.Label className="required"> Select Barcode</Form.Label>
        <CustomSelect
          isValid={data.isSelectValid}
          required
          className="modal__form__select"
          options={[
            {
              value: "",
              label: "--select barcode--",
              isDisabled: true,
            },
            ...data.options,
          ]}
          name="barcodeId"
          onChange={fns.onChange}
        />
      </Form.Group>
    </Form>
  );
}

//passive tag modal
const initialPassiveTagFormData = {
  passiveTagId: "",
  options: [],
  isFormValidated: false,
  isSelectValid: false,
};
const PassiveTagModal = ({ isModal, setIsModal, assetId }) => {
  // console.log("PassiveTagModal", assetId);
  const dispatch = useDispatch();
  const [formData, setFormData] = useState(initialPassiveTagFormData);
  const handleModalClose = () => {
    setIsModal(false);
  };
  const handleFormChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;

    if (name === "passiveTagId") {
      setFormData((fd) => ({
        ...fd,
        isSelectValid: fd.isFormValidated ? true : null,
      }));
    }

    setFormData((fd) => ({
      ...fd,
      [name]: value,
    }));
  };
  const handleFormSubmit = async (e) => {
    const form = e.currentTarget;
    e.preventDefault();
    e.stopPropagation();
    if (form.checkValidity() === true) {
      try {
        dispatch(allocateTag({ ...formData, assetId }));
        handleModalClose();
      } catch (err) {}
    } else
      formData((fd) => ({
        ...fd,
        isFormValidated: true,
        isSelectValid: !!fd.passiveTagId,
      }));
  };
  useEffect(() => {
    dispatch(getAvailableTags("Passive")).then((res) => {
      setOptions(res.payload.data, "options", setFormData);
      //    res.payload.data?.length > 0 &&
      //      setAssetData((oldData) => ({
      //        ...oldData,
      //        availableActiveTagOptions: getOptions(res.payload.data),
      //      }));
    });
  }, []);
  return (
    <>
      <GenericModal
        size="md"
        isModal={isModal}
        handleClose={handleModalClose}
        title="Allocate Passive Tag"
        body={addPassiveTagForm}
        data={formData}
        modalInputflexBasis="50%"
        fns={{
          onChange: handleFormChange,
          onSubmit: handleFormSubmit,
        }}
      />
    </>
  );
};
function addPassiveTagForm(data, fns) {
  return (
    <Form
      className="modal__form pRel"
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      onSubmit={fns.onSubmit}
    >
      <Form.Group className="mb-3" controlId="formPassiveTag">
        <Form.Label className="required"> Select Passive Tag</Form.Label>
        <CustomSelect
          isValid={data.isSelectValid}
          required
          className="modal__form__select"
          options={[
            {
              value: "",
              label: "--select passive tag--",
              isDisabled: true,
            },
            ...data.options,
          ]}
          name="passiveTagId"
          onChange={fns.onChange}
        />
      </Form.Group>
    </Form>
  );
}

// assign accessory (component) modal
const initialAssignAccessoryData = {
  assetId: "",
  accessoryOptions: [],
  assignAccessories: [
    {
      id: "",
      quantity: 0,

      serialNo: [],
      isAccessorySelectValid: false,
      remarks: "",
    },
  ],
  isFormValidated: false,
  // errors: {
  //   name: "",
  // },
};
const AssignAccessoryModal = ({ isModal, setIsModal, assetId }) => {
  //   console.log("assignAcc", assetId);
  const dispatch = useDispatch();
  const [assignAccessoryData, setAssignAccessoryData] = useState(
    initialAssignAccessoryData
  );
  const handleModalClose = () => {
    setAssignAccessoryData(initialAssignAccessoryData);
    setIsModal(false);
  };

  const handleAssignAccessoryDataChange = (...args) => {
    const [e, actionMeta, index] = args;

    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;

    setAssignAccessoryData((ad) => {
      const temp = { ...ad };
      // console.log(temp.assignAccessories, name, value);
      temp.assignAccessories[index][name] = value;
      if (name === "id")
        temp.assignAccessories[index].isAccessorySelectValid =
          temp.isFormValidated ? true : null;
      return temp;
    });
  };
  const handleScan = (data, accIndex) => {
    setAssignAccessoryData((ad) => {
      const nextAssignAcessories = [...ad.assignAccessories].map((acc, idx) => {
        if (idx === accIndex) {
          const nextSerial = [...acc.serialNo, data];
          const nextAcc = {
            ...acc,
            serialNo: nextSerial,
            quantity: nextSerial.length,
          };
          console.log(nextAcc);
          return nextAcc;
        }
        return acc;
      });

      const res = {
        ...ad,
        assignAccessories: nextAssignAcessories,
      };
      return res;
    });
    // console.log({ data }, accIndex);
  };
  const handleScanItemDelete = (accIndex, serialIndex) => {
    // console.log(accIndex, serialIndex);
    setAssignAccessoryData((ad) => {
      const nextAssignAcessories = [...ad.assignAccessories].map((acc, idx) => {
        if (idx === accIndex) {
          const nextSerial = [...acc.serialNo].filter(
            (_, idx) => idx !== serialIndex
          );
          const nextAcc = {
            ...acc,
            serialNo: nextSerial,
            quantity: nextSerial.length,
          };
          // console.log(nextAcc);
          return nextAcc;
        }
        return acc;
      });

      const res = {
        ...ad,
        assignAccessories: nextAssignAcessories,
      };
      return res;
    });
  };
  const handleClearScannedItems = (accIndex) => {
    // console.log(accIndex);
    setAssignAccessoryData((ad) => {
      const nextAssignAcessories = [...ad.assignAccessories].map((acc, idx) => {
        if (idx === accIndex) {
          const nextSerial = [];
          const nextAcc = {
            ...acc,
            serialNo: nextSerial,
            quantity: nextSerial.length,
          };
          // console.log(nextAcc);
          return nextAcc;
        }
        return acc;
      });

      const res = {
        ...ad,
        assignAccessories: nextAssignAcessories,
      };
      return res;
    });
  };
  const handleAddAccessory = () => {
    setAssignAccessoryData((ad) => ({
      ...ad,
      assignAccessories: [
        ...ad.assignAccessories,
        {
          id: "",
          quantity: "",
          serialNo: [],
          isAccessorySelectValid: false,
          remarks: "",
        },
      ],
    }));
  };
  const handleDeleteAccessory = (id) => {
    setAssignAccessoryData((ad) => ({
      ...ad,
      assignAccessories: ad.assignAccessories.filter((a, ind) => ind !== id),
    }));
  };
  const handleAssignAccessoryFormSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    // console.log("submit", assignAccessoryData);
    if (form.checkValidity() === true) {
      try {
        dispatch(assignAccessory({ ...assignAccessoryData, assetId }));
        handleModalClose();
      } catch (err) {}
    } else
      setAssignAccessoryData((ad) => ({
        ...ad,
        isFormValidated: true,
      }));
  };

  useEffect(() => {
    dispatch(getAllAccessories()).then((res) => {
      setOptions(res.payload.data, "accessoryOptions", setAssignAccessoryData);
    });
  }, []);
  return (
    <>
      <GenericModal
        size="lg"
        isModal={isModal}
        handleClose={handleModalClose}
        title="Assign Component"
        body={AddAssignAccessoryBody}
        data={assignAccessoryData}
        modalInputflexBasis="100%"
        fns={{
          onChange: handleAssignAccessoryDataChange,
          onSubmit: handleAssignAccessoryFormSubmit,
          handleAddAccessory,
          handleDeleteAccessory,
          handleScan,
          handleScanItemDelete,
          handleClearScannedItems,
        }}
      />
    </>
  );
};
function AddAssignAccessoryBody(data, fns) {
  // console.log({ data });
  const [modal, setModal] = useState({});
  const handleModalClose = () => {
    setModal({});
  };
  const clearScannedItems = (id) => {
    fns.handleClearScannedItems(id);
    setModal({});
  };

  const handleModalOpen = (id) => {
    setModal({ [id]: id });
  };
  // console.log("moda", modal);
  // const handleScan = () => {};
  // const handleScanSubmit = () => {};
  return (
    <Form
      className="modal__form pRel mb-5 gap-zero"
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      onSubmit={fns.onSubmit}
    >
      {data.assignAccessories.map((acc, index) => {
        const modalId = acc.id + index;
        // console.log("id", acc);
        return (
          <div
            className="d-flex assignAccessory-wrapper "
            style={{ flexBasis: "100%" }}
            key={index}
          >
            <Form.Group className="mb-0" controlId={`formAssignAsset${index}`}>
              <Form.Label className="required"> Select Component</Form.Label>
              <CustomSelect
                isValid={acc.isAccessorySelectValid}
                // required
                className="modal__form__select"
                options={[
                  {
                    value: "",
                    label: "--select accessory--",
                    isDisabled: true,
                  },
                  ...data.accessoryOptions,
                ]}
                name="id"
                onChange={(selectedOption, actionMeta) =>
                  fns.onChange(selectedOption, actionMeta, index)
                }
              />
            </Form.Group>

            <Form.Group className="mb-0 aSelfEnd" controlId="formScanQR">
              <button
                type="button"
                className="scanAccessory-btn"
                disabled={acc.id ? false : true}
                onClick={() => handleModalOpen(modalId)}
              >
                Start Scanning
              </button>
            </Form.Group>
            <Form.Group
              className="mb-0 text-center"
              controlId="formQty"
              style={{
                flexBasis: "15%",
              }}
            >
              <Form.Label>Qty</Form.Label>
              <div
                style={{
                  border: "1px solid #ff7200",
                  height: "38px",
                  borderRadius: "4px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {acc.quantity}
              </div>
            </Form.Group>
            {/* make this permission based. */}
            {/* <Form.Group className="mb-0" controlId="formName">
              <Form.Label className="required">Enter Quantity </Form.Label>
              <Form.Control
                required
                type="number"
                // placeholder="Enter Asset Name"
                name="quantity"
                min="0"
                value={acc.quantity}
                onChange={(e) => fns.onChange(e, null, index)}
              />
              <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please enter quantity
              </Form.Control.Feedback>
            </Form.Group> */}
            <Form.Group className="mb-0" controlId="formRemarks">
              <Form.Label>Remarks</Form.Label>
              <Form.Control
                // as="textarea"
                type="text"
                placeholder="Add remarks here"
                // style={{ height: "100px" }}
                name="remarks"
                value={acc.remarks}
                onChange={(e) => fns.onChange(e, null, index)}
              />
            </Form.Group>

            <span className="aSelfEnd">
              <AiFillDelete
                className="delete-icon icons"
                onClick={() => fns.handleDeleteAccessory(index)}
              />
            </span>

            {modal[modalId] && (
              <ScanAccessoryModal
                isModal={modal[modalId] ? true : false}
                handleSubmit={handleModalClose}
                handleClose={clearScannedItems}
                handleDelete={fns.handleScanItemDelete}
                handleScan={fns.handleScan}
                data={{ accessory: acc, accIndex: index }}
              />
            )}
          </div>
        );
      })}
      <button
        type="button"
        className="pAbs addAccessory-btn "
        onClick={fns.handleAddAccessory}
      >
        Add More
      </button>
    </Form>
  );
}
/**
 *
 *
 */
function ScanAccessoryModal({
  isModal,
  handleSubmit,
  handleClose,
  handleDelete,
  handleScan,
  data,
}) {
  // console.log(data);
  const {
    accessory: { serialNo },
    accIndex,
  } = data;
  // // console.log({ serialNo }, accIndex);
  // const [isScan, setIsScan] = useState(false);
  const [code, setCode] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    // setIsScan(true);
    focusInput();
  }, []);

  // useEffect(() => {
  //   console.log(document.activeElement);
  //   if (document.activeElement !== inputRef.current) {
  //     setIsScan(false);
  //   }
  // }, [document.activeElement]);
  useEffect(() => {
    const fullCode = setTimeout(() => {
      // console.log(code);
      if (code && !serialNo.includes(code)) {
        handleScan(code, accIndex);
      }
      setCode("");
    }, 500);
    return () => clearTimeout(fullCode);
  }, [code]);

  const tableData = serialNo.map((code, index) => {
    // console.log(code);
    if (!code) return;
    let row = [];
    row.push(index + 1, code, [
      <div className="action-icons">
        <AiFillDelete
          className="delete-icon icons"
          onClick={() => handleDelete(accIndex, index)}
        />
      </div>,
    ]);
    return row;
  });

  function focusInput() {
    inputRef.current.focus();
  }
  function closeModal() {
    handleClose(accIndex);
  }
  return (
    <>
      <Modal
        show={isModal}
        onHide={closeModal}
        centered
        size="lg"
        className="generic-modal"
        id="something"
        scrollable
      >
        <Modal.Header>
          <Modal.Title>Map Components to Asset</Modal.Title>
          <span className="generic-modal__close-icon--wrapper">
            <AiFillCloseCircle
              className="generic-modal__close-icon"
              onClick={closeModal}
            />
          </span>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            ref={inputRef}
            style={{ opacity: "0", position: "absolute" }}
            onChange={(e) => setCode(e.target.value)}
            value={code}
          />
          {/* {isScan && document.activeElement === inputRef.current ? (
        "You can scan now"
      ) : ( */}
          <button
            type="button"
            className="scanAccessory-btn2"
            onClick={focusInput}
          >
            Start Scanning
          </button>
          {/* )} */}
          <div className="generic-table-wrapper mt-4 scan_accessories_table">
            <Table className="generic-table mt-0">
              <thead className="bg-accent">
                <tr>
                  <th>S.No</th>
                  <th>Unique ID</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="bg-gray-100">
                {tableData.length > 0 ? (
                  tableData.map((row, rIndex) => {
                    return (
                      <tr key={rIndex}>
                        {row.map((cell, c_index) => (
                          <td
                            key={c_index}
                            className={`${
                              c_index === row.length - 1 ? "action" : ""
                            }`}
                          >
                            {cell}
                          </td>
                        ))}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={100}>No Data Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} className="generic-modal__btn">
            Cancel
          </Button>
          <Button
            className="generic-modal__btn"
            type="button"
            form="modal__form"
            onClick={handleSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

/**
 * -> select component
 * -> click on scan
 * -> open a modal that shows a table
 * -> list the qr codes and they are scanned
 * -> show the count in the modal
 * -> also show the count in the component listing in the assign accessory modal
 */
