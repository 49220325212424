import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import { logout } from "../state/redux/auth/authSlice";
import { toast } from "react-toastify";

export default function PrivateRoute({
  component: Component,
  isPrivate,
  ...rest
}) {
  const dispatch = useDispatch();
  const token = localStorage.getItem("secretkey");
  // console.log({ token });
  // console.log("private route", { token });

  if (token) {
    const decoded = jwt_decode(token);
    const dateNow = new Date();
    if (decoded.exp * 1000 < dateNow.getTime()) {
      dispatch(logout(token));
      toast.error("Session Expired: Please Login Again", {
        position: "bottom-center",
        autoClose: 3000,
      });
    }
  }

  if (isPrivate && token === null) {
    return <Redirect to="/" />;
  }
  if (!isPrivate && token) {
    return <Redirect to="/dashboard" />;
  }
  return <Route {...rest} component={Component} />;
}

// export default function RouteWrapper({
//   component: Component,
//   isPrivate,
//   ...rest
// }) {
//   console.log(isPrivate);
//   var signed = false;
//   if (localStorage.getItem("secretkey")) {
//     signed = true;
//   }
//   /**
//    * Redirect user to SignIn page if he tries to access a private route
//    * without authentication.
//    */
//   if (isPrivate && !signed) {
//     console.log("redirect to /");
//     return <Redirect to="/" />;
//   }
//   /**
//    * Redirect user to Main page if he tries to access a non private route
//    * (SignIn or SignUp) after being authenticated.
//    */
//   if (!isPrivate && signed) {
//     console.log("redirect to dashboard");
//     return <Redirect to="/dashboard" />;
//   }
//   /**
//    * If not included on both previous cases, redirect user to the desired route.
//    */
//   return <Route {...rest} component={Component} />;
// }
// RouteWrapper.propTypes = {
//   isPrivate: PropTypes.bool,
//   component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
//     .isRequired,
// };
// RouteWrapper.defaultProps = {
//   isPrivate: false,
// };
