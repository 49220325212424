import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import history from "../../../services/history";
import { post } from "../../../services/requests";
export const login = createAsyncThunk("auth/login", async (data) => {
  const body = {
    email: data.userID,
    password: data.currentPass,
  };

  const res = await post("/user/login", body);

  return res;
});

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    token: localStorage.getItem("secretkey") || "",
    error: null,
  },

  reducers: {
    logout(state) {
      state.token = "";
      state.permissions = "";
      localStorage.removeItem("secretkey");
      history.push("/");
    },
  },

  extraReducers(builder) {
    builder.addCase(login.pending, (state) => {
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      const res = action.payload;

      if (res.error === false) {
        const token = res.token;
        localStorage.setItem("secretkey", token);
        // history.push("/dashboard");
        state.token = token;
      }
      if (res.error === true) {
        state.error = res.message;
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.error = action.error;
    });
  },
});

export const { logout } = authSlice.actions;

export const selectToken = (state) => state.auth.token;
export const selectError = (state) => state.auth.error;

export default authSlice.reducer;
