import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../../static/css/sidebar.css";
import { GetMenuItems } from "./menuItems";

const Sidebar = () => {
  const menuItems = GetMenuItems();
  const location = useLocation();
  const url = `${location.pathname}${location.search}${location.hash}`;
  // console.log({ url });
  return (
    <div className="sidebar open">
      <div className="tab-content">
        <div className="tab-pane" id="mainmenu">
          <ul className="menu-nav">
            {menuItems.map((item, index) => {
              if (!item.permission) return null;
              let isChildren = !!item.children;
              let isAnchorLink = item.url.includes("https");
              const navItem = (
                <>
                  <item.Icon
                    className={`sidebar__icons ${
                      isUrlActive(url, item.url, isChildren)
                        ? "icon__active"
                        : ""
                    }`}
                  />
                  <span className="txt">{item.title}</span>
                </>
              );
              return (
                <li
                  className={
                    isUrlActive(url, item.url, isChildren) ? "active" : ""
                  }
                  key={index}
                >
                  {isAnchorLink ? (
                    <a href={item.url} target="_blank">
                      {navItem}
                    </a>
                  ) : (
                    <Link to={item.url}>{navItem}</Link>
                  )}
                  {isChildren ? (
                    <ul className="children">
                      {item.children.map((child, cIndex) => {
                        return (
                          <li
                            key={cIndex}
                            className={url === child.url ? "active" : ""}
                          >
                            <Link to={child.url}>{child.title}</Link>
                          </li>
                        );
                      })}
                    </ul>
                  ) : null}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

function isUrlActive(url, itemUrl, flag) {
  return flag ? url.includes(itemUrl) : url === itemUrl;
}
