const ChangePassword = (props) =>{
    return(
        <section id="changePasswordModal" className="custom-modal" style={{width:"40%"}}>
            <div className="custom-modal-header">
            <h1>Change Password</h1>
            <span className="ct-close" onClick={props.closeModal}></span>
            </div>
            <div className="custom-modal-body" style={{overflowX:'auto',maxHeight:'80vh'}}>
                <div className="cust-row mb-2">
                    <div className="cust-col-10">
                        <label className="black_color font-bold text-left wdt100">Old Password</label>
                        <input className="cust-input" type="password" placeholder="Enter Old Password"/>
                    </div>
                </div>
                <div className="cust-row mb-2">
                    <div className="cust-col-10">
                        <label className="black_color font-bold text-left wdt100">New Password</label>
                        <input className="cust-input" type="password" placeholder="Enter New Password"/>
                    </div>
                </div>
                <div className="cust-row mb-2">
                    <div className="cust-col-10">
                        <label className="black_color font-bold text-left wdt100">Confirm Password</label>
                        <input className="cust-input" type="password" placeholder="Re-enter New Password"/>
                    </div>
                </div>
                <div className="cust-row">
                    <div className="cust-col-3">

                    </div>
                    <div className="cust-col-4">
                        <button className='button bti bg-grey-out m-0'>
                            <div className='btn_icon'>
                                <i className='fa fa-check'></i>
                            </div>
                            <div className='btn_txt font-lg'>Change Password</div>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ChangePassword