import { useState } from "react";
import { useDispatch } from "react-redux";
import PaginationComponent from "./PaginationComponent";

const CustomPagination = ({
  totalCount,
  isPagination,
  fetchData,
  id,
  flowSetting,
  setTableData,
  extraParams,
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const handlePageChange = (pageNumber) => {
    let pageNum =
      typeof pageNumber === "function" ? pageNumber(currentPage) : pageNumber;
    const body = { pageNum, limit: 10, id, ...extraParams };
    // console.log(body);
    if (flowSetting) {
      dispatch(fetchData(body)).then((res) => {
        setTableData(res.payload.data || []);
      });
    } else dispatch(fetchData(body));
    setCurrentPage(pageNumber);
  };

  return (
    <>
      {isPagination && (
        <div className="pagination-wrapper">
          <PaginationComponent
            itemsCount={totalCount}
            itemsPerPage="10"
            currentPage={currentPage}
            setCurrentPage={handlePageChange}
          />
        </div>
      )}
    </>
  );
};

export default CustomPagination;
