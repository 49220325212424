import { useSelector } from "react-redux";
import moment from "moment";
import {
  AiFillEye,
  AiFillEdit,
  AiFillDelete,
  AiFillPlusSquare,
} from "react-icons/ai";

import {
  selectLineManagers,
  getLineManagers, 
  selectLineManagersCount,
  selectIsLineManPagination,
} from "../../../../../state/redux/lineManager/lineManagerSlice";

import CustomPagination from "../../../../utils/CustomPagination";
import GenericTable from "../../../../utils/GenericTable";

const LineManagerTable = () => {
  const lineManagers = useSelector(selectLineManagers);
  const lineManagersCount = useSelector(selectLineManagersCount);
  const isPagination = useSelector(selectIsLineManPagination);

  const lineManagerTableData = lineManagers.map((obj, index) => {
    let tableData = [];

    tableData.push(
      index + 1,
      obj.name,
      obj.lines[0]?.name,
      obj.email,
      obj.status,
      moment(obj.addedOn).format("DD/MM/YYYY, h:mm a")
      // [
      //   <div className="action-icons">
      //     <AiFillEye className="view-icon icons" title='edit'/>
      //     {/* <AiFillEdit
      //           className="edit-icon icons"
      //           id={obj._id}
      //           onClick={handleEdit}
      //         /> */}
      //     <AiFillDelete className="delete-icon icons" />
      //   </div>,
      // ]
    );
    return tableData;
  });
  return (
    <>
      <GenericTable
        headings={[
          "S.No",
          "Name",
          "Line",
          "Email",
          "Status",
          "Added On",
          // "Action",
        ]}
        data={lineManagerTableData}
      />
      <CustomPagination
        totalCount={lineManagersCount}
        isPagination={isPagination}
        fetchData={getLineManagers}
      />
    </>
  );
};

export default LineManagerTable;
