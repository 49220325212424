import React from "react";

const Footer = (props) => (
  <div className="footer-sec">
    <p className="text-center">
      FactoryLine Lynkit.io &copy; 2022. All Rights Reserved.
    </p>
  </div>
);

export default Footer;
