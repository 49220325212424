import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../services/requests";
import { showToasts } from "../../../views/utils/toasts";
import { initFetchParams } from "../util";


export const getAnalytics = createAsyncThunk(
  "analytics/get-analytics",
  async (data) => {
    const body = {
      unit : data.unit,
      duration : data.duration
    };
    const res = await post("/lines/get-analytics", body);
    return res;
  }
);

export const getAnalyticsSlice = createSlice({
    name: "analytics",
    initialState: {
      analyticsData: [],
    },
    reducers: {},
    extraReducers(builder) {
      builder.addCase(getAnalytics.fulfilled, (state, action) => {
        state.analyticsData = action.payload.data || [];
      });
    },
  });


export const analyticsData = (state) => state.analytics.analyticsData;


export default getAnalyticsSlice.reducer;
