import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// import { Switch } from "react-router-dom";
// import Route from "./Route";
import Landing from "../components/App";
import Dashboard from "../components/Dashboard";
import PrivateRoute from "./Route";

export default function Routes() {
  return (
    <Switch>
      <PrivateRoute path="/" exact component={Landing} />
      <PrivateRoute path="/dashboard" component={Dashboard} isPrivate />
      {/* <Route path="/dashboard" component={Dashboard} isPrivate /> */}
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      {/* <Route component={Landing} /> */}
      <Redirect to="/" />;
    </Switch>
  );
}

/**
 * We need to re-implement/shift the routing logic (private routing) so that this gets executed before any of the main app components are rendered
 *
 */
