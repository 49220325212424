import { useState } from "react";
import { Form, Table } from "react-bootstrap";
import {
  AiFillEye,
  AiFillEdit,
  AiFillDelete,
  AiFillPlusCircle,
  AiFillPlusSquare,
} from "react-icons/ai";
import GenericModal from "./Modals/GenericModal";
import { useSelector } from "react-redux";
import {
  addLine,
  getAllChildren,
  getLines,
  selectAllChilren,
  selectAllChilrenCount,
  selectIsAllChilrenPagination,
  selectIsLinePagination,
  selectLineCount,
  selectLines,
  updateLine,
} from "../../state/redux/lines/linesSlice";
import { useDispatch } from "react-redux";
import moment from "moment";
import { useEffect } from "react";
import {
  addSubLine,
  selectSubLines,
} from "../../state/redux/subLines/subLinesSlice";
import CustomSelect from "../utils/CustomSelect";
import {
  BsArrowDownCircleFill,
  BsArrowUpCircleFill,
  BsTable,
} from "react-icons/bs";
import {
  addStages,
  getStages,
  selectStages,
} from "../../state/redux/stages/stagesSlice";
import {
  getTemplates,
  selectTemplateList,
} from "../../state/redux/templateSetting/templateSettingSlice";
import { getUserData, getOptions } from "../../services/functions";
import history from "../../services/history";
import { validate_form } from "../../services/validation";
import { useMemo } from "react";
import { nanoid } from "nanoid";
import { initFetchParams } from "../../state/redux/util";
import CustomPagination from "../utils/CustomPagination";

const initialLineData = {
  name: "",
  isFormValidated: false,
  errors: {
    name: "",
  },
};
const initialSubLineData = {
  lineId: "",
  //subLineName
  name: "",
  lineOptions: [],
  isFormValidated: false,
  isLineSelectValid: false,
  errors: {
    name: "",
  },
};
const initialStageData = {
  numOfStages: "1",
  stages: [],
  templateOptions: [],
  isFormValidated: false,
};

const initialEditFormData = {
  id: "",
  name: "",
  parentId: "",
  parentName: "",
  isFormValidated: false,
  errors: {
    name: "",
  },
};

const FlowSetting = () => {
  const dispatch = useDispatch();

  const templates = useSelector(selectTemplateList);
  const lines = useSelector(selectLines);
  const lineCount = useSelector(selectLineCount);
  const isLinePagination = useSelector(selectIsLinePagination);

  const childrenCount = useSelector(selectAllChilrenCount);
  const isChildrenPagination = useSelector(selectIsAllChilrenPagination);

  const [id, setId] = useState(null);
  const { permissions, userLineId } = getUserData();

  //modals
  const [isLineModal, setIsLineModal] = useState(false);
  const [isSubLineModal, setIsSubLineModal] = useState(false);
  const [isStageModal, setIsStageModal] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);

  //data
  const [lineData, setLineData] = useState(initialLineData);
  const [subLineData, setSubLineData] = useState(initialSubLineData);
  const [stageData, setStageData] = useState(initialStageData);
  const [editFormData, setEditFormData] = useState(initialEditFormData);

  const lineOptions = useMemo(() => getOptions(lines), [lines]);
  const templateOptions = useMemo(() => getOptions(templates), [templates]);

  const [tableData, setTableData] = useState([]);
  const [breadcrumbs, setBreadcrumbs] = useState([
    {
      name: <BsTable className="flow-settings__breadcrumb-home-icon" />,
      type: "line",
    },
  ]);

  //onChange handlers
  const handleLineFormChange = (e) => {
    const { name, value } = e.target;
    const tempErrors = { ...lineData.errors };
    const errors = validate_form({
      tempErrors,
      name,
      value,
    });

    setLineData((oldData) => ({ ...oldData, [name]: value, errors }));
  };

  const handleSubLineFormChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;
    const tempErrors = { ...subLineData.errors };
    const errors = validate_form({
      tempErrors,
      name,
      value,
    });

    if (name === "lineId") {
      setSubLineData((sd) => ({
        ...sd,
        isLineSelectValid: sd.isFormValidated ? true : null,
      }));
      //
    }

    setSubLineData((oldData) => ({
      ...oldData,
      [name]: value,
      errors,
    }));
  };

  const handleStageFormChange = (...args) => {
    const [e, actionMeta, id] = args;
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;

    //see if numOfStages have to be calculated here or not - and should type 'deleted' be considered or not

    const updatedStages = stageData.stages.map((stage) => {
      if (stage.id === id) {
        const tempErrors = { ...stage.errors };
        const errors = validate_form({
          tempErrors,
          name,
          value,
          htmlId: `#formName${stage.id}`,
        });
        return {
          ...stage,
          [name]: value,
          errors,
          type: stage.type === "unchanged" ? "modified" : stage.type,
        };
      }
      return stage;
    });

    setStageData((sd) => ({ ...sd, stages: updatedStages }));
  };

  const handleEdit = (e) => {
    const id = e.target.closest(".flowSetting__table-row").dataset.id;
    const parentId = e.target.closest(".flowSetting__table-row").dataset
      .parentid;
    const name = e.target.closest(".flowSetting__table-row").children[1]
      .textContent;
    const parentName = e.target.closest(".flowSetting__table-row").children[2]
      .textContent;

    setEditFormData((old) => ({ ...old, id, parentId, name, parentName }));
    setIsEditModal(true);
  };
  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    const tempErrors = { ...editFormData.errors };
    const errors = validate_form({
      tempErrors,
      name,
      value,
    });
    setEditFormData((oldData) => ({ ...oldData, [name]: value, errors }));
  };
  const handleEditFormSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      try {
        await dispatch(updateLine(editFormData));
        handleGetData(editFormData.parentId);
        setIsEditModal(false);
        setEditFormData(initialEditFormData);
      } catch (err) {}
    }
    setEditFormData((ed) => ({ ...ed, isFormValidated: true }));
  };

  const handleModalClose = () => {
    setLineData(initialLineData);
    setIsLineModal(false);
    // console.log("close");
    setSubLineData({
      ...initialSubLineData,
      lineOptions,
    });
    setIsSubLineModal(false);

    setIsStageModal(false);
    setStageData({
      ...initialStageData,
      templateOptions,
    });
  };

  const handleGetData = async (id, type) => {
    if (id)
      dispatch(getAllChildren({ ...initFetchParams, id })).then((res) => {
        setTableData(res.payload.data || []);
      });
    else
      dispatch(getLines(initFetchParams)).then((res) => {
        setTableData(res.payload.data || []);
      });
  };

  // handle add new line
  const handleLineFormSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      try {
        dispatch(addLine({ name: lineData.name }));
        handleGetData();
        handleModalClose();
      } catch (err) {}
    } else setLineData((ld) => ({ ...ld, isFormValidated: true }));
  };

  // handle add new sub line
  const handleSubLineFormSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      try {
        dispatch(
          addSubLine({
            lineId: subLineData.lineId,
            name: subLineData.name,
          })
        ).then((res) => {
          dispatch(
            getAllChildren({ ...initFetchParams, id: subLineData.lineId })
          ).then((res) => {
            setTableData(res.payload.data || []);
          });
        });

        handleModalClose();
      } catch (err) {}
    } else
      setSubLineData((sd) => ({
        ...sd,
        isFormValidated: true,
        isLineSelectValid: !!sd.lineId,
      }));
  };

  //handle add new stages
  const handleStageFormSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      try {
        dispatch(addStages(stageData));
        handleModalClose();
      } catch (err) {}
    } else setStageData((sd) => ({ ...sd, isFormValidated: true }));
  };

  const updateSequence = (data, type) => {
    let count = 1;
    return {
      ...data,
      stages: data.stages.map((stage, index) => {
        if (stage.type === type) return stage;
        else return { ...stage, order: count++ };
      }),
    };
  };
  const handleAddStage = () => {
    const availableStages = getAvailableStages(stageData);
    const newOrder =
      availableStages.length === 0 ? 1 : availableStages.length + 1;
    setStageData((oldData) => {
      const updatedStages = {
        ...oldData,
        stages: [
          ...oldData.stages,
          {
            templateId: "",
            name: "",
            order: newOrder,
            type: "new",
            id: nanoid(),
            errors: {
              name: "",
            },
          },
        ],
      };
      return updatedStages;
    });
  };

  const handleDeleteStage = (id) => {
    setStageData((oldData) => {
      const updatedStages = {
        ...oldData,
        stages: oldData.stages.map((stage, index) =>
          stage.id === id ? { ...stage, type: "deleted" } : stage
        ),
      };

      const newlySequencedStageData = updateSequence(updatedStages, "deleted");
      return newlySequencedStageData;
    });
  };

  const handleUpdateStageSequence = (index, dir, id) => {
    if (index === 0 && dir === "up") return;
    if (index === stageData.stages.length - 1 && dir === "down") return;

    const copyStages = [...stageData.stages];

    //find index of stage to be shifted
    let shiftIndex = copyStages.findIndex((stage) => stage.id === id);
    let tempShiftIndex = shiftIndex;

    //find the stage to be shifted
    const toShiftObj = copyStages[shiftIndex]; //;copyStages.splice(shiftIndex, 1);
    const toShiftObjCopy = {
      ...toShiftObj,
      type: toShiftObj.type === "unchanged" ? "modified" : toShiftObj.type,
    };

    let flag = true;
    if (dir === "up") {
      while (flag) {
        if (copyStages[tempShiftIndex - 1].type !== "deleted") {
          copyStages.splice(shiftIndex, 1);
          copyStages.splice(tempShiftIndex - 1, 0, toShiftObjCopy);
          flag = false;
        } else {
          tempShiftIndex--;
        }
      }
    } else if (dir === "down") {
      while (flag) {
        if (copyStages[tempShiftIndex + 1].type !== "deleted") {
          copyStages.splice(shiftIndex, 1);
          copyStages.splice(tempShiftIndex + 1, 0, toShiftObjCopy);
          flag = false;
        } else {
          tempShiftIndex++;
        }
      }
    }
    setStageData((oldData) => {
      const updatedStageData = {
        ...oldData,
        stages: copyStages,
      };
      const newlySequencedStageData = updateSequence(
        updatedStageData,
        "deleted"
      );
      return newlySequencedStageData;
    });
  };

  const fetchAndSetStages = async (id) => {
    dispatch(getStages(id)).then((res) =>
      setStageData((oldData) => {
        const stages = res.payload.data?.map((stage) => ({
          templateId: stage.templateId,
          name: stage.name,
          order: stage.order,
          id: stage._id,
          type: "unchanged",
          errors: {
            name: "",
          },
        }));
        const updatedStages = {
          ...oldData,
          lineId: id,
          stages:
            stages?.length > 0
              ? stages
              : [
                  {
                    templateId: "",
                    name: "",
                    order: "1",
                    type: "new",
                    id: nanoid(),
                    errors: {
                      name: "",
                    },
                  },
                ],
        };

        return updatedStages;
      })
    );
  };

  // get lines, and template list on component load
  useEffect(() => {
    const fetchData = async () => {
      dispatch(getLines(initFetchParams)).then((res) => {
        if (res.payload.data) {
          setTableData(res.payload.data);
          setSubLineData((oldData) => ({
            ...oldData,
            lineOptions: getOptions(res.payload.data),
          }));
        }
      });

      dispatch(getTemplates(initFetchParams)).then((res) => {
        setStageData((oldData) => ({
          ...oldData,
          templateOptions: getOptions(res.payload.data || []),
        }));
      });
    };
    if (permissions.getLines) fetchData();
    else history.push("/dashboard");
  }, []);

  const handleStageModalClose = () => {
    setIsStageModal(false);
  };

  const handleViewData = (name, id, type) => {
    //todo: hanlde for type: 'stage'
    handleGetData(id, type);
    setId(id);
    setBreadcrumbs((old) => [...old, { name: `${name} (${type})`, type, id }]);
  };
  const handleBreadcrumbs = (id, type, index) => {
    if (index === breadcrumbs.length - 1) return;
    // if (tableData && id === tableData[0].parentId) return;
    handleGetData(id, type);
    setBreadcrumbs((oldCrumbs) => oldCrumbs.slice(0, index + 1));
  };
  const handleViewStageModal = (id) => {
    fetchAndSetStages(id);
    setIsStageModal(true);
  };
  return (
    <>
      <div className="flow-settings outer-wrapper">
        <div className="panel">
          <div className="panel__heading">
            <h2>Flow Settings</h2>
          </div>
          <div className="panel__items">
            {permissions.addLine && (
              <button
                className="panel-btn"
                onClick={() => setIsLineModal(true)}
              >
                Add Line
                <AiFillPlusSquare className="icons panel__icon" />
              </button>
            )}

            {permissions.addSubLine && (
              <button
                className="panel-btn"
                onClick={() => setIsSubLineModal(true)}
              >
                Add Sub-Line
                <AiFillPlusSquare className="icons panel__icon" />
              </button>
            )}
          </div>
        </div>
        <div className="mt-3">
          <div className="flow-settings__breadcrumb">
            <ul>
              {breadcrumbs.map((item, index) => (
                <li
                  key={index}
                  className="cursor-pointer"
                  onClick={() => handleBreadcrumbs(item.id, item.type, index)}
                >
                  {item.name}
                  {index !== breadcrumbs.length - 1 && (
                    <span className="flow-settings__breadcrumb-arrow">
                      {">"}
                    </span>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        {isLineModal ? (
          <GenericModal
            size="md"
            isModal={isLineModal}
            handleClose={handleModalClose}
            title="Add Line"
            body={addLineBody}
            data={lineData}
            modalInputflexBasis="30%"
            fns={{
              onChange: handleLineFormChange,
              onSubmit: handleLineFormSubmit,
            }}
          />
        ) : isSubLineModal ? (
          <GenericModal
            size="md"
            isModal={isSubLineModal}
            handleClose={handleModalClose}
            title="Add Sub-Line"
            body={addSubLineBody}
            data={subLineData}
            modalInputflexBasis="50%"
            fns={{
              onChange: handleSubLineFormChange,
              onSubmit: handleSubLineFormSubmit,
            }}
          />
        ) : isStageModal ? (
          <GenericModal
            size="lg"
            isModal={isStageModal}
            handleClose={handleStageModalClose}
            title="Add Stage"
            body={addStageBody}
            data={stageData}
            modalInputflexBasis="30%"
            fns={{
              onChange: handleStageFormChange,
              onSubmit: handleStageFormSubmit,
              handleAddStage,
              handleDeleteStage,
              handleUpdateStageSequence,
            }}
          />
        ) : isEditModal ? (
          <GenericModal
            size="md"
            isModal={isEditModal}
            handleClose={() => setIsEditModal(false)}
            title={`Edit ${
              editFormData.parentName === "subLine"
                ? "Sub-Line"
                : editFormData.parentName
            }`}
            body={addEditFormBody}
            data={editFormData}
            modalInputflexBasis="30%"
            fns={{
              onChange: handleEditFormChange,
              onSubmit: handleEditFormSubmit,
            }}
            isEdit
          />
        ) : null}

        <div className="generic-table-wrapper ">
          <Table className="generic-table">
            <thead className="bg-accent">
              <tr>
                <th>S. No</th>
                <th>Name</th>
                <th>Type</th>
                <th>Added On</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody className="bg-gray-100">
              {tableData && tableData.length > 0 ? (
                tableData.map((data, index) => {
                  return (
                    <tr
                      key={data._id}
                      className="flowSetting__table-row"
                      data-id={data._id}
                      data-parentid={data.parentId || ""}
                    >
                      <td>{index + 1}</td>
                      <td className="flowSetting__table-lineName">
                        {data.name}
                      </td>
                      <td>{data.lineType}</td>
                      <td>
                        {moment(data.addedOn).format("DD/MM/YYYY, h:mm a")}
                      </td>
                      <td className="action">
                        <div className="action-icons">
                          {data.lineType === "stage" ? null : (
                            <AiFillEye
                              className="view-icon icons"
                              onClick={() =>
                                handleViewData(
                                  data.name,
                                  data._id,
                                  data.lineType
                                )
                              }
                              title="view children"
                            />
                          )}
                          <AiFillEdit
                            className="edit-icon icons"
                            id={data._id}
                            onClick={handleEdit}
                            title="edit"
                          />
                          {/* <AiFillDelete className="delete-icon icons" /> */}
                          {data.lineType !== "stage" &&
                            ((userLineId && data._id === userLineId) ||
                              permissions.getLines) && (
                              <button
                                className="add_stage-btn"
                                onClick={() => handleViewStageModal(data._id)}
                              >
                                <AiFillPlusCircle /> Stage
                              </button>
                            )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={100}>No Data Found</td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>

        <CustomPagination
          totalCount={
            tableData[0]?.lineType === "line" ? lineCount : childrenCount
          }
          isPagination={
            tableData[0]?.lineType === "line"
              ? isLinePagination
              : isChildrenPagination
          }
          fetchData={
            tableData[0]?.lineType === "line" ? getLines : getAllChildren
          }
          id={id}
          flowSetting
          setTableData={setTableData}
        />
      </div>
    </>
  );
};

export default FlowSetting;

function addEditFormBody(data, fns) {
  return (
    <Form
      className="modal__form"
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      onSubmit={fns.onSubmit}
    >
      <Form.Group className="mb-3" controlId="formName">
        <Form.Label className="required">Enter Name</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter name"
          minLength="60"
          name="name"
          value={data.name}
          onChange={fns.onChange}
        />
        <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        {data.errors.name ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.name}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please enter name!
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Form>
  );
}
function addLineBody(data, fns) {
  return (
    <Form
      className="modal__form"
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      onSubmit={fns.onSubmit}
    >
      <Form.Group className="mb-3" controlId="formName">
        <Form.Label className="required">Line Name</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="enter line name"
          name="name"
          minLength="60" //only a hack for validation, will be removed when all validations pass
          value={data.name}
          onChange={fns.onChange}
        />
        <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        {data.errors.name ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.name}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please enter line name!
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Form>
  );
}

function addSubLineBody(data, fns) {
  return (
    <Form
      className="modal__form"
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      onSubmit={fns.onSubmit}
    >
      <Form.Group className="mb-3" controlId="formId">
        <Form.Label className="required">Select Line</Form.Label>
        <CustomSelect
          isValid={data.isLineSelectValid}
          required
          className="modal__form__select"
          options={[
            {
              value: "",
              label: "--select line--",
              isDisabled: true,
            },
            ...data.lineOptions,
          ]}
          name="lineId"
          // defaultValue={data.lineId}
          onChange={fns.onChange}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formName">
        <Form.Label className="required">Sub-Line Name</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter sub-line name"
          name="name"
          value={data.name}
          onChange={fns.onChange}
        />
        <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        {data.errors.name ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.name}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please enter sub-line name!
          </Form.Control.Feedback>
        )}
      </Form.Group>
    </Form>
  );
}
function addStageBody(data, fns) {
  /**
   * available stages are all stages where type!= 'deleted'. We will use this to find total length and to show stage list only when available stage length is >0.
   * HOWEVER, when mapping through, we will use original data.stages i.e the list that INCLUDES the 'deleted' stages, because we need to keep them in the data and send to server. Our handleFormChange will break if we don't do this.
   */
  const availableStages = getAvailableStages(data);

  return (
    <Form
      className="modal__form pRel"
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      onSubmit={fns.onSubmit}
    >
      <span className="stage_count">Stage Count: {availableStages.length}</span>
      <Table>
        <thead>
          <tr>
            <th>Template </th>
            <th className="required">Stage Name</th>
            <th>Stage Sequence</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {availableStages.length > 0 ? (
            availableStages.map((stage, index) => {
              const currentTemplateOption = data.templateOptions.filter(
                (option) => option.value === stage.templateId
              )[0];
              // const isTemplateSelectValid = stage.templateId && true;
              // console.log(currentTemplateOption);
              // if (stage.type !== "deleted")
              return (
                <tr key={stage.id} className="stageForm-row">
                  <td>
                    <CustomSelect
                      // required={!isTemplateSelectValid}
                      // isValid={isTemplateSelectValid}
                      className="modal__form__select"
                      options={[
                        {
                          value: "",
                          label: "-select template-",
                          isDisabled: true,
                        },
                        ...data.templateOptions,
                      ]}
                      name="templateId"
                      onChange={(selectedOption, actionMeta) =>
                        fns.onChange(
                          selectedOption,
                          actionMeta,
                          stage.id,
                          index
                        )
                      }
                      value={currentTemplateOption}
                    />
                  </td>
                  <td>
                    <Form.Group controlId={`formName${stage.id}`}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Enter stage name"
                        name="name"
                        minLength="60"
                        value={stage.name}
                        onChange={(e) => fns.onChange(e, null, stage.id, index)}
                      />
                      <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
                      {stage.errors.name ? (
                        <Form.Control.Feedback type="invalid">
                          {stage.errors.name}
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Control.Feedback type="invalid">
                          Please enter stage name!
                        </Form.Control.Feedback>
                      )}
                    </Form.Group>
                  </td>
                  <td>
                    <span>{stage.order}</span>
                  </td>
                  <td className="stage__form__icons">
                    {index === availableStages.length - 1 && (
                      <AiFillPlusCircle
                        className="icons add-icon addStage-icon"
                        onClick={fns.handleAddStage}
                      />
                    )}
                    <AiFillDelete
                      className="icons delete-icon"
                      onClick={() => fns.handleDeleteStage(stage.id)}
                    />
                    <BsArrowUpCircleFill
                      className={`icons stage_up_arrow ${
                        index === 0 && "btn-disable"
                      }`}
                      onClick={() =>
                        fns.handleUpdateStageSequence(index, "up", stage.id)
                      }
                    />
                    <BsArrowDownCircleFill
                      className={`icons stage_down_arrow ${
                        index === availableStages.length - 1 && "btn-disable"
                      }`}
                      onClick={() =>
                        fns.handleUpdateStageSequence(index, "down", stage.id)
                      }
                    />
                  </td>
                </tr>
              );
            })
          ) : (
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td className="pRel">
                <AiFillPlusCircle
                  className="icons add-icon addStage-icon"
                  onClick={fns.handleAddStage}
                />
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Form>
  );
}

function getAvailableStages(data) {
  return data.stages.filter((stg) => stg.type !== "deleted");
}
