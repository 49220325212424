import { useSelector } from "react-redux";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import moment from "moment";

import {
  getDevices,
  selectDeviceCount,
  selectDevices,
  selectIsDevicePagination,
} from "../../../../../../../state/redux/assetSetting/assetSettingSlice";

import GenericTable from "../../../../../../utils/GenericTable";
import CustomPagination from "../../../../../../utils/CustomPagination";

const DeviceTable = ({ deviceFilterData }) => {
  const devices = useSelector(selectDevices);
  const deviceCount = useSelector(selectDeviceCount);
  const isDevicePagination = useSelector(selectIsDevicePagination);

  const deviceTableData = devices.map((obj, index) => {
    let objArr = [];
    objArr.push(
      index + 1,
      obj.deviceType=='Barcode'?'Barcode Reader':(obj.deviceId || "NA"),
      obj.lines[0].lineType === "line" ? obj.lines[0].name : "NA", //line name
      obj.lines[1].lineType === "subLine" ? obj.lines[1].name : "NA", // subline name
      obj.lines[2]?.lineType === "stage"
        ? obj.lines[2].name
        : obj.lines[1].lineType === "stage"
        ? obj.lines[1].name
        : "NA", //stage name
      moment(obj.addedOn).format("DD/MM/YYYY, h:mm a")
      // action(handleDeviceEdit, handleDeviceDelete)
    );
    return objArr;
  });

  function action(handleEdit, handleDelete) {
    return (
      <div className="action-icons">
        <AiFillEye className="view-icon icons" onClick={handleEdit} />
        {/* <AiFillEdit
                className="edit-icon icons"
                id={obj._id}
                onClick={handleEdit}
              /> */}
        <AiFillDelete className="delete-icon icons" onClick={handleDelete} />
      </div>
    );
  }
  function handleDeviceEdit(e) {
    console.log("handleDeviceEdit", e.target.id);
  }
  function handleDeviceDelete(e) {
    console.log("handleDeviceDelete", e.target.id);
  }
  return (
    <>
      <GenericTable
        headings={[
          "S.No",
          "Name",
          "Line",
          "Sub-Line",
          "Stage",
          "Added On",
          // "Action",
        ]}
        data={deviceTableData}
      />
      <CustomPagination
        totalCount={deviceCount}
        isPagination={isDevicePagination}
        fetchData={getDevices}
        extraParams={deviceFilterData}
      />
    </>
  );
};

export default DeviceTable;
