import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getTags } from "../../../../../state/redux/assetSetting/assetSettingSlice";
import { initFetchParams } from "../../../../../state/redux/util";

import TagFilter from "./components/TagFilter";
import TagTable from "./components/TagTable";

const TagContent = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getTags(initFetchParams));
  }, []);
  return (
    <>
      <TagFilter />
      <TagTable />
    </>
  );
};
export default TagContent;
