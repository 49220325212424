import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../services/requests";
import { showToasts } from "../../../views/utils/toasts";

export const getStages = createAsyncThunk("stages/getStages", async (data) => {
  const body = {
    lineId: data,
    lineType: "stage",
    // limit: 1000,
  };

  const res = await post("/lines/get-lines", body);
  return res;
});
export const addStages = createAsyncThunk("stages/addStages", async (data) => {
  const body = {
    lineId: data.lineId,
    stages: data.stages,
  };
  const res = await post("/lines/add-stage", body);

  return res;
});

export const stagesSlice = createSlice({
  name: "stages",
  initialState: {
    allStages: { data: [], loading: false, error: "" },
  },
  reducers: {
    resetStages(state) {
      state.allStages.data = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(getStages.fulfilled, (state, action) => {
      const updatedData =
        action.payload.data &&
        action.payload.data.map((stage) => ({
          ...stage,
          type: "unchanged",
        }));

      state.allStages.data = updatedData || [];
    });
    builder.addCase(addStages.fulfilled, (state, action) => {
      showToasts(action.payload, {
        successMessage: "Stage Added Successfully!",
      });
    });
  },
});

export const { resetStages } = stagesSlice.actions;

export const selectStages = (state) => state.stages.allStages.data;

export default stagesSlice.reducer;
