import React from "react";
import PropTypes from "prop-types";
import { useRef } from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";

const noop = () => {
  // no operation (do nothing real quick)
};

const FixRequiredSelect = (props) => {
  const [value, setValue] = useState(props.value || "");
  let selectRef = useRef(null);
  //   const { SelectComponent, required, ...props } = props;
  //   const { isDisabled } = this.props;
  const enableRequired = !props.isDisabled;
  const setSelectRef = (ref) => {
    selectRef = ref;
  };
  const onChange = (value, actionMeta) => {
    props.onChange(value, actionMeta);
    setValue(value);
  };
  const getValue = () => {
    if (props.value != undefined) return props.value;
    return value || "";
  };
  // console.log("value", props.value, value);
  return (
    <div>
      <props.SelectComponent
        {...props}
        ref={setSelectRef}
        onChange={onChange}
      />
      {enableRequired && (
        <Form.Group className="">
          <Form.Control
            tabIndex={-1}
            autoComplete="off"
            style={{
              opacity: 0,
              width: "100%",
              height: "0px",
              position: "absolute",
            }}
            value={getValue()}
            onChange={noop}
            onFocus={() => selectRef.focus()}
            required={props.required}
          />
          {props.required ? (
            <>
              <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                Please select a value
              </Form.Control.Feedback>
            </>
          ) : null}
        </Form.Group>
      )}
    </div>
  );
};

FixRequiredSelect.defaultProps = {
  onChange: noop,
};

FixRequiredSelect.protoTypes = {
  // react-select component class (e.g. Select, Creatable, Async)
  selectComponent: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
};

export default FixRequiredSelect;
