import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../services/requests";
import { showToasts } from "../../../views/utils/toasts";

export const getSubLines = createAsyncThunk(
  "subLines/getSubLines",
  async (data) => {
    const body = {
      lineType: "subLine",
      lineId: data ? data.id : "",
      page_no: data ? data.pageNum : "",
      limit: data ? data.limit : "",
    };

    const res = await post("/lines/get-lines", body);
    return res;
  }
);
export const addSubLine = createAsyncThunk(
  "subLines/addSubLine",
  async (data) => {
    const res = await post("/lines/add-line", {
      lineId: data.lineId,
      name: data.name,
    });
    return res;
  }
);

export const subLinesSlice = createSlice({
  name: "subLines",
  initialState: {
    allSubLines: { data: [], isPagination: false, totalCount: 0 },
  },
  reducers: {
    resetSubLines(state) {
      state.allSubLines = [];
    },
  },
  extraReducers(builder) {
    builder.addCase(getSubLines.fulfilled, (state, action) => {
      state.allSubLines.data = action.payload.data || [];
      state.allSubLines.totalCount = Number(action.payload.count_all);
      state.allSubLines.isPagination = action.payload.count_all > 10;
    });
    builder.addCase(addSubLine.fulfilled, (state, action) => {
      showToasts(action.payload, {
        successMessage: "SubLine Added Successfully!",
      });
    });
  },
});

export const { resetSubLines } = subLinesSlice.actions;

export const selectSubLines = (state) => state.subLines.allSubLines.data;
export const selectSubLineCount = (state) =>
  state.subLines.allSubLines.totalCount;
export const selectIsSubLinePagination = (state) =>
  state.subLines.allSubLines.isPagination;

export default subLinesSlice.reducer;
