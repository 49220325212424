import { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";
import "../../../static/css/genericModal.css";

const GenericModal = ({
  isModal,
  handleClose,
  title,
  body,
  data,
  fns,
  size,
  modalInputflexBasis = "30%",
  noBtn,
  isEdit,
  submitBtnText,
}) => {
  useEffect(() => {
    const formInputDivs = document.querySelector(".modal__form")?.children;
    if (formInputDivs)
      for (let i = 0; i < formInputDivs.length; i++) {
        if (formInputDivs[i].className.includes("reports__date-filter")) return;

        formInputDivs[i].style.setProperty(
          "--modalInputFlexBasis",
          modalInputflexBasis
        );
      }
  }, []);
  return (
    <Modal
      show={isModal}
      onHide={handleClose}
      centered
      size={size}
      className="generic-modal"
      id="something"
      scrollable
    >
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <span className="generic-modal__close-icon--wrapper">
          <AiFillCloseCircle
            className="generic-modal__close-icon"
            onClick={handleClose}
          />
        </span>
      </Modal.Header>
      <Modal.Body>{body(data, fns)}</Modal.Body>
      <Modal.Footer>
        {noBtn ? null : (
          <>
            <Button onClick={handleClose} className="generic-modal__btn">
              Cancel
            </Button>
            <Button
              className="generic-modal__btn"
              type="submit"
              form="modal__form"
            >
              {isEdit ? "Update" : submitBtnText ? submitBtnText : "Save"}
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default GenericModal;
