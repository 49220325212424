import React, { Component, useState } from "react";
import Footer from "../../views/Landing/Footer";
import Navbar from "../../views/Landing/Navbar";
import Login from "../../views/Landing/Login";
import "./../../static/css/style.css";
import logo from "./../../static/images/logo.png";

import history from "../../services/history";
import { post } from "../../services/requests";
import { useDispatch, useSelector } from "react-redux";
import {
  login,
  selectError,
  selectToken,
} from "../../state/redux/auth/authSlice";

const App = () => {
  const dispatch = useDispatch();

  async function _onLogin(passState) {
    dispatch(login(passState)).then((res) => {
      history.push("/dashboard");
    });
  }
  return (
    <div>
      <Navbar logo={logo} />
      <Login _onLogin={_onLogin} />
      <Footer />
    </div>
  );
};

export default App;
