export const valid_mobile = (mobile) => {
  var phoneno = /^\d{10}$/;
  if (!mobile.match(phoneno)) {
    return false;
  } else {
    return true;
  }
};

export const valid_email = (email) => {
  if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/.test(email)) {
    return true;
  } else {
    return false;
  }
};

export const valid_gst = (gst) => {
  var regexGST =
    /^[0-9]{2}[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[0-9]{1}Z[a-zA-Z\d]{1}?$/;
  return regexGST.test(gst);
};

export const valid_pan = (pan) => {
  var regexPAN = /^[a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}?$/;
  if (!pan.match(regexPAN) || pan.length !== 10) {
    return false;
  } else {
    return true;
  }
};

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
export const validate_form = ({
  name,
  value,
  tempErrors: errors,
  curr_pass = "",
  htmlId,
}) => {
  switch (name) {
    case "name":
      errors.name =
        value.length === 0
          ? "value cannot be empty"
          : !/^[^\s]/.test(value)
          ? "Remove blank spaces at the start"
          : !/[^\s]$/.test(value)
          ? "Remove extra spaces at the end"
          : !/^([a-zA-Z0-9]+\s)*[a-zA-Z0-9]+$/i.test(value)
          ? "Please enter only underscore and alpha-numeric characters with no extra spaces"
          : value.length < 3
          ? "enter at least 3 characters"
          : "";
      // console.log(errors.name);
      setAttrValidation({
        error: errors.name,
        value: "",
        id: htmlId ? htmlId : "#formName",
      });
      break;
    case "email":
      errors.email = valid_email(value) ? "" : "Email is not valid";
      // console.log(errors.email);

      setAttrValidation({
        error: errors.email,
        value: "",
        id: "#formEmail",
      });
      break;
    case "password":
    case "confirmPassword":
      const pArr = value.split("");
      errors.password = !/^\S*$/.test(value)
        ? "Password must not have any spaces"
        : value.length < 8
        ? "Password must have minimum 8 character length"
        : !pArr.some((l) => /[a-z]/.test(l))
        ? "Password must have atleast one lowercase character"
        : !pArr.some((l) => /[A-Z]/.test(l))
        ? "Password must have atleast one uppercase character"
        : !pArr.some((l) => /[!@#$%^&*]/.test(l))
        ? "Password must have atleast one special(!@#$%^&*) character"
        : !pArr.some((l) => /[0-9]/.test(l))
        ? "Password must have atleast one numeric digit"
        : "";

      setAttrValidation({
        error: curr_pass,
        value,
        id: "#formConfirmPassword",
      });
      errors.confirmPassword =
        curr_pass === value ? "" : "Passwords do not match";
      break;
    case "id":
      errors.id = !/^[^\s]/.test(value)
        ? "Remove blank spaces at the start"
        : !/[^\s]$/.test(value)
        ? "Remove extra spaces at the end"
        : value.length < 3
        ? "enter at least 3 characters"
        : "";
      setAttrValidation({ error: errors.id, value: "", id: "#formId" });
      break;
    case "qty":
      errors.qty = checkMaxQty(value)
        ? "Quantity cannot be more than the maximum value"
        : "";
      break;
    default:
      break;
  }

  return errors;
};

function setAttrValidation({ error, value, id }) {
  // console.log(error, value, id);
  if (error === value) document.querySelector(id).removeAttribute("minlength");
  else document.querySelector(id).setAttribute("minlength", "60");
}
function checkMaxQty(val) {
  const maxVal = document.querySelector(".transferQty").dataset.max;
  return val > maxVal;
}
