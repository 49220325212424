import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../services/requests";
import { showToasts } from "../../../views/utils/toasts";
import { initFetchParams } from "../util";

export const addStageManager = createAsyncThunk(
  "stageManager/addStageManager",
  async (data, { dispatch }) => {
    const body = {
      name: data.name,
      email: data.email,
      password: data.password,
      confirmPassword: data.confirmPassword,
      userType: "stageManager",
      lineId: data.stageId,
    };

    const res = await post("/user/add-user", body);
    if (!res.error) dispatch(getStageManagers(initFetchParams));
    return res;
  }
);
export const getStageManagers = createAsyncThunk(
  "stageManager/getStageManagers",
  async (data) => {
    const body = {
      userType: "stageManager",
      page_no: data.pageNum,
      limit: data.limit,
    };
    // console.log("body", body);
    const res = await post("/user/get-users", body);

    return res;
  }
);

export const stageManagerSlice = createSlice({
  name: "stageManager",
  initialState: {
    stageManagersData: [],
    totalCount: 0,
    isPagination: false,
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(addStageManager.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
    builder.addCase(getStageManagers.fulfilled, (state, action) => {
      state.stageManagersData = action.payload.data || [];
      state.totalCount = Number(action.payload.count_all);
      state.isPagination = action.payload.count_all > 10;
    });
  },
});

export const selectStageManagers = (state) =>
  state.stageManager.stageManagersData;
export const selectStageManagersCount = (state) =>
  state.stageManager.totalCount;
export const selectIsStageManPagination = (state) =>
  state.stageManager.isPagination;

export default stageManagerSlice.reducer;
