import { useState } from "react";
import { Form, Table } from "react-bootstrap";
import Select from "react-select";
import {
  AiFillEye,
  AiFillEdit,
  AiOutlineLink,
  AiFillDelete,
  AiFillPlusSquare,
} from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import moment from "moment";

import { useRef } from "react";
import {
  getTemplates,
  saveTemplate,
  selectIsTemplateListPagination,
  selectTemplateList,
  selectTemplateListCount,
} from "../../state/redux/templateSetting/templateSettingSlice";
import { getUserData } from "../../services/functions";
import history from "../../services/history";
import { toast } from "react-toastify";
import { initFetchParams } from "../../state/redux/util";
import CustomPagination from "../utils/CustomPagination";

const TemplateSettings = () => {
  const dispatch = useDispatch();

  const templates = useSelector(selectTemplateList);
  const templateCount = useSelector(selectTemplateListCount);
  const isTemplatePagination = useSelector(selectIsTemplateListPagination);

  const createTemplateRef = useRef(null);

  const { permissions } = getUserData();
  const [isCreateTemplate, setIsCreateTemplate] = useState(false);

  const handleEdit = (e) => {
    console.log("handleEdit", e.target.id);
  };
  const handleViewData = () => {};

  // templates on component load
  useEffect(() => {
    const fetchData = async () => {
      dispatch(getTemplates(initFetchParams));
    };
    if (permissions.getTemplates) fetchData();
    else history.push("/dashboard");
  }, []);

  useEffect(() => {
    const template = createTemplateRef.current;
    if (isCreateTemplate) {
      const handleCancel = () => setIsCreateTemplate(false);
      const handleSubmit = (e) => {
        const data = e.detail.data;
        const reset = e.detail.resetData;
        dispatch(saveTemplate(data));
        reset();
        setIsCreateTemplate(false);
      };
      const handleError = (e) => {
        toast.error(e.detail.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      };

      template.addEventListener("cancelTemplate", handleCancel);
      template.addEventListener("errorTemplate", handleError);
      template.addEventListener("templateDataSubmit", handleSubmit);
    }
  }, [isCreateTemplate]);
  return (
    <>
      <div className="template-settings outer-wrapper">
        <div className="panel">
          <div className="panel__heading">
            <h2>Template Settings</h2>
          </div>
          <div className="panel__items">
            {permissions.addTemplate && isCreateTemplate ? null : (
              <button
                className="panel-btn template-settings__create-template-btn"
                onClick={() => {
                  setIsCreateTemplate(true);
                }}
              >
                Create Template
                <AiFillPlusSquare className="icons panel__icon" />
              </button>
            )}
          </div>
        </div>

        {isCreateTemplate ? (
          <div className="create-template">
            <create-template ref={createTemplateRef}></create-template>
          </div>
        ) : (
          <>
            <div className="generic-table-wrapper ">
              <Table className="generic-table">
                <thead className="bg-accent">
                  <tr>
                    <th>S. No</th>
                    <th>Name</th>
                    <th>Description</th>
                    <th>No. Of Fields</th>
                    <th>Added On</th>
                    {/* <th>Action</th> */}
                  </tr>
                </thead>
                <tbody className="bg-gray-100">
                  {templates && templates.length > 0 ? (
                    templates.map((template, index) => {
                      return (
                        <tr key={template._id}>
                          <td>{index + 1}</td>
                          <td>{template.name}</td>
                          <td>{template.description}</td>
                          <td>{template.attributes.length}</td>
                          <td>
                            {moment(template.creation_date).format(
                              "DD/MM/YYYY, h:mm a"
                            )}
                          </td>
                          {/* <td className="action">
                          <div className="action-icons">
                            <AiFillEye
                              className="view-icon icons"
                              onClick={() =>
                                handleViewData(
                                  template.name,
                                  template._id,
                                  template.lineType
                                )
                              }
                            />
                            <AiFillEdit
                              className="edit-icon icons"
                              id={template._id}
                              onClick={handleEdit}
                            />
                            <AiFillDelete className="delete-icon icons" />
                          </div>
                        </td> */}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={100}>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            <CustomPagination
              totalCount={templateCount}
              isPagination={isTemplatePagination}
              fetchData={getTemplates}
            />
          </>
        )}
      </div>
    </>
  );
};

export default TemplateSettings;
