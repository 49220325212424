import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../services/requests";
import { showToasts } from "../../../views/utils/toasts";
// import { initFetchParams } from "../util";

export const getAssetMaster = createAsyncThunk(
  "assetMaster/getAssetMaster",
  async (data) => {
    const body = {
      // downloadType: "excel"
    };
    // console.log("here");

    const res = await post("/assetmaster/get-asset-master", body);
    // console.log(res);
    return res;
  }
);

export const getAssetMasterBase64 = createAsyncThunk(
  "assetMaster/getAssetMasterBase64",
  async (data) => {
    const body = {
      downloadType: "excel",
      // startdate: data.startDate,
      // enddate: data.endDate,
    };

    const res = await post("/assetmaster/get-asset-master", body);
    // console.log(res);

    return res;
  }
);

// export const getAsset = createAsyncThunk(
//   "assetmaster/getAsset",
//   async(data)=>{
//     const body ={}
//     const res = await post("/assetmaster/get-master",body);
//     return res;
//   }

// );
// export const addAsset = createAsyncThunk(
//   "assetmaster/addAsset",
//   async(data)=>{
//     const body = {
//       types:data.types
//     };
//     const res = await post("/assetmaster/add-master",body);
//     return res;
//   }
// );
//  export const addAssetMaster = (
//   "assetmaster/addAssetMaster",
//   async(data)=>{
//     const body = {
//             asset_Type:data.asset_Type,
//             asset_Primary_Code:data.asset_Primary_Code,
//             asset_Id:data.asset_Id,
//             asset_Finance:data.asset_Finance,
//             sbu:data.sbu,
//             asset_Class_1:data.asset_Class_1,
//             asset_Class_2:data.asset_Class_2,
//             asset_Sub_Class:data.asset_Sub_Class,
//             department:data.department,
//             plant:data.plant,
//             oem:data.oem,
//             model:data.model,
//             status:data.status,
//             device_Number:data.device_Number,
//             location:data.location,
//             vendor:data.vendor,
//             quantity:data.quantity,
//             asset_It_Code:data.asset_It_Code,
//             cost_Centre:data.cost_Centre,
//             userName:data.userName,
//             description:data.description,
//             po_Number:data.body.po_Number,
//             asset_Status:data.asset_Status,
//             purchase_Date:new Date(data.purchase_Date),
//             capitalized_Date:new Date(data.capitalized_Date)
//     };
//     const res = await post("/assetmaster/add-asset-master",body);
//     return res;

//   }
//  )

export const assetMasterSlice = createSlice({
  name: "assetMasterManagement",
  initialState: {
    assetMasterData: { data: [], totalCount: 0, isPagination: false },
    reportBase64: "",
    assetData: { data: [] },
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAssetMaster.fulfilled, (state, action) => {
      state.assetMasterData.data = action.payload.data || [];
      state.assetMasterData.totalCount = Number(action.payload.data.length);
      state.assetMasterData.isPagination = action.payload.data.length > 10;
      if (action.payload.error) {
        showToasts(action.payload);
      }
    });
    builder.addCase(getAssetMasterBase64.fulfilled, (state, action) => {
      state.reportBase64 = action.payload.data || [];
      if (action.payload.error) {
        showToasts(action.payload);
      } else {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${action.payload.data}`;
        const downloadLink = document.createElement("a");
        // console.log(linkSource);
        downloadLink.href = linkSource;
        downloadLink.target = "_blank";
        downloadLink.click();

        showToasts(action.payload, {
          successMessage: "Report Downloaded Successfully!",
        });
      }
    });
    // builder.addCase(getAsset.fulfilled,(state,action)=>{
    //   state.assetData.data = action.payload.data || [];
    //   if(action.payload.error){
    //     showToasts(action.payload)
    //   }else{
    //     showToasts(action.payload,{
    //       successMessage: "Fetched all assets"
    //     })
    //   }
    // });
    // builder.addCase(addAsset.fulfilled,(state,action)=>{
    //   state.assetData.data = action.payload.data || [];
    //   if(action.payload.error){
    //     showToasts(action.payload)
    //   }else{
    //     showToasts(action.payload,{
    //       successMessage:"Added asset master successfully"
    //     })
    //   }

    // });
    // builder.addCase(addAssetMaster.fulfilled,(state,action)=>{
    //   state.assetMasterData.data = action.payload.data || [];
    //   state.assetMasterData.totalCount = Number(action.payload.data.length);
    //   state.assetMasterData.isPagination = action.payload.data.length > 10;
    //   if(action.payload.error){
    //     showToasts(action.payload)
    //   }

    // });
  },

  // }
});

export const selectAssetMasterData = (state) =>
  state.assetMasterManagement.assetMasterData.data;

export default assetMasterSlice.reducer;
