import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../services/requests";
import { showToasts } from "../../../views/utils/toasts";
import { initFetchParams } from "../util";

export const addAsset = createAsyncThunk(
  "assetAllocation/addAsset",
  async (data, { dispatch }) => {
    const body = {
      name: data.name,
      description: data.description,
      tagId: data.passiveTagId,
      activeTagId: data.activeTagId,
      barcodeId: data.barcodeId
    };

    const res = await post("/assets/add-asset", body);
    if (!res.error) dispatch(getAssets(initFetchParams));

    return res;
  }
);
export const getAssets = createAsyncThunk(
  "assetAllocation/getAssets",
  async (data) => {
    const body = {
      page_no: data.pageNum || "",
      limit: data.limit || "",
      search: data.search || "",
      searchBy: data.searchBy || "",
    };
    // console.log(body, data);
    const res = await post("/assets/get-assets", body);
    return res;
  }
);
export const allocateTag = createAsyncThunk(
  "assetAllocation/allocateTag",
  async (data, { dispatch }) => {
    const body = {
      assetId: data.assetId,
      tagId: data.passiveTagId,
      barcodeId: data.barcodeId,
      activeTagId: data.activeTagId,
    };

    const res = await post("/assets/allocate-tag", body);
    if (!res.error) dispatch(getAssets(initFetchParams));
    return res;
  }
);
export const deallocateTag = createAsyncThunk(
  "assetAllocation/deallocateTag",
  async (data, { dispatch }) => {
    const body = {
      assetId: data.assetId,
      tagType: data.tagType,
    };

    const res = await post("/assets/deallocate-tag", body);
    if (!res.error) dispatch(getAssets(initFetchParams));
    return res;
  }
);
export const getAvailableTags = createAsyncThunk(
  "assetAllocation/getAvailableTags",
  async (data) => {
    const body = {
      status: "Pending",
      tagType: data,
    };
    //page and limit not required here. check use of this func to understand why.
    const res = await post("/tags/get-tags", body);

    return res;
  }
);
export const addAccessory = createAsyncThunk(
  "assetAllocation/addAccessory",
  async (data, { dispatch }) => {
    const body = {
      name: data.name,
      description: data.description,
    };
    const res = await post("/assets/add-accessory", body);

    if (!res.error) {
      dispatch(getAccessories(initFetchParams));
      dispatch(getAllAccessories());
    }

    return res;
  }
);
export const getAccessories = createAsyncThunk(
  "assetAllocation/getAccessories",
  async (data) => {
    const body = {
      page_no: data ? data.pageNum : "",
      limit: data ? data.limit : "",
      search: data.search || "",
      searchBy: data.searchBy || "",
    };

    const res = await post("/assets/get-accessories", body);
    return res;
  }
);
export const getAllAccessories = createAsyncThunk(
  "assetAllocation/getAllAccessories",
  async () => {
    const res = await post("/assets/get-accessories");
    return res;
  }
);
export const assignAccessory = createAsyncThunk(
  "assetAllocation/assignAccessory",
  async (data, { dispatch }) => {
    const body = {
      assetId: data.assetId,
      accessories: data.assignAccessories,
    };

    const res = await post("/assets/map-accessories", body);

    return res;
  }
);
export const getAssetAccessories = createAsyncThunk(
  "assetAllocation/getAssetAccessories",
  async (data, { dispatch }) => {
    const body = {
      assetId: data.id,
      page_no: data.pageNum,
      limit: data.limit,
    };

    const res = await post("/assets/get-asset-accessories", body);
    return res;
  }
);
export const getAccessoryHistory = createAsyncThunk(
  "assetAllocation/getAccessoryHistory",
  async (data, { dispatch }) => {
    const body = {
      accessoryId: data.id,
      page_no: data.pageNum,
      limit: data.limit,
    };

    const res = await post("/assets/accessory-history", body);
    return res;
  }
);
export const transferAccessory = createAsyncThunk(
  "assetAllocation/transferAccessory",
  async (data, { dispatch }) => {
    const body = {
      oldAssetId: data.assetId,
      newAssetId: data.targetAssetId,
      accessories: [{ id: data.accessoryId, quantity: Number(data.qty) }],
    };
    const res = await post("/assets/transfer-accessories", body);
    if (!res.error)
      dispatch(getAssetAccessories({ ...initFetchParams, id: data.assetId }));
    return res;
  }
);
export const resetAsset = createAsyncThunk(
  "assetAllocation/resetAsset",
  async (data, { dispatch }) => {
    const body = {
      assetId: data,
    };
    const res = await post("/assets/asset-reset", body);
    if (!res.error)
      dispatch(getAssetAccessories({ ...initFetchParams, id: data }));
    return res;
  }
);
export const getAccessoriesStageStatus = createAsyncThunk(
  "assetAllocation/getAccessoriesStageStatus",
  async (data) => {
    const body = {
      assetId: data.assetId,
      stageId: data.stageId,
    };
    const res = await post("/assets/accessory-stage-status", body);
    return res;
  }
);
export const scanAccessories = createAsyncThunk(
  "assetAllocation/scanAccessories",
  async (data) => {
    const body = {
      stageId: data.stageId,
      assetId: data.assetId,
      accessories: data.accessories,
    };
    const res = await post("/assets/scan-accessories", body);
    return res;
  }
);
export const searchBySerialNo = createAsyncThunk(
  "assetAllocation/searchBySerialNo",
  async (data) => {
    const body = {
      serialNo: data.serialNo,
      page_no: data.pageNum,
      limit: 5,
    };
    const res = await post("/assets/accessory-history-by-serialno", body);
    return res;
  }
);
/**
 *
 *
 *
 *
 *
 */

export const assetAllocationSlice = createSlice({
  name: "assetAllocation",
  initialState: {
    assets: {
      data: [],
      loading: false,
      error: "",
      totalCount: 0,
      isPagination: false,
    },
    accessories: { data: [], totalCount: 0, isPagination: false, allData: [] },
    assetAccessories: {
      data: [],
      totalData: [],
      totalCount: 0,
      isPagination: false,
    },
    accessoryHistory: { data: [], totalCount: 0, isPagination: false },
    availableTags: {
      data: [],
      loading: false,
      error: "",
      totalCount: 0,
      isPagination: false,
    },
    accessoriesStageStatus: { data: [] },
    accessoryHistorySerialNo: { data: [], totalCount: 0, isPagination: false },
  },

  reducers: {},

  extraReducers(builder) {
    builder.addCase(addAsset.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
    builder.addCase(getAssets.fulfilled, (state, action) => {
      state.assets.data = action.payload.data || [];
      state.assets.totalCount = Number(action.payload.count_all);
      state.assets.isPagination = action.payload.count_all > 10;
    });
    builder.addCase(allocateTag.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
    builder.addCase(deallocateTag.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
    builder.addCase(getAvailableTags.fulfilled, (state, action) => {
      state.availableTags.data = action.payload.data || [];
    });
    builder.addCase(addAccessory.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
    builder.addCase(getAccessories.fulfilled, (state, action) => {
      state.accessories.data = action.payload.data || [];
      state.accessories.totalCount = Number(action.payload.count_all);
      state.accessories.isPagination = action.payload.count_all > 10;
    });
    builder.addCase(getAllAccessories.fulfilled, (state, action) => {
      state.accessories.allData = action.payload.data || [];
    });
    builder.addCase(assignAccessory.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
    builder.addCase(getAssetAccessories.fulfilled, (state, action) => {
      state.assetAccessories.data = action.payload.data || [];
      state.assetAccessories.totalCount = Number(action.payload.count_all);
      state.assetAccessories.isPagination = action.payload.count_all > 10;
    });
    builder.addCase(transferAccessory.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
    builder.addCase(getAccessoryHistory.fulfilled, (state, action) => {
      state.accessoryHistory.data = action.payload.data || [];
      state.accessoryHistory.totalCount = Number(action.payload.count_all);
      state.accessoryHistory.isPagination = action.payload.count_all > 10;
    });
    builder.addCase(resetAsset.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
    builder.addCase(getAccessoriesStageStatus.fulfilled, (state, action) => {
      const { data, error, message } = action.payload;
      if (error) {
        // showToasts(action.payload);
        state.accessoriesStageStatus.data = [];
      } else {
        state.accessoriesStageStatus.data = data || [];
      }
    });
    builder.addCase(scanAccessories.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
    builder.addCase(scanAccessories.rejected, (state, action) => {
      showToasts(action.payload);
    });
    builder.addCase(searchBySerialNo.fulfilled, (state, action) => {
      // showToasts(action.payload);
      const { data, error, message, count_all } = action.payload;
      if (error) {
        showToasts(action.payload);
      } else {
        state.accessoryHistorySerialNo.data = data || [];
        state.accessoryHistorySerialNo.totalCount = Number(count_all);
        state.accessoryHistorySerialNo.isPagination = count_all > 10;
      }
    });
  },
});

// export const { setLines } = lineManagerSlice.actions;

export const selectAssets = (state) => state.assetAllocation.assets.data;
export const selectAssetCount = (state) =>
  state.assetAllocation.assets.totalCount;
export const selectIsAssetPagination = (state) =>
  state.assetAllocation.assets.isPagination;

export const selectAccessories = (state) =>
  state.assetAllocation.accessories.data;
export const selectAllAccessories = (state) =>
  state.assetAllocation.accessories.allData;
export const selectAccessoryCount = (state) =>
  state.assetAllocation.accessories.totalCount;
export const selectIsAccessoryPagination = (state) =>
  state.assetAllocation.accessories.isPagination;

export const selectAccessoryHistory = (state) =>
  state.assetAllocation.accessoryHistory.data;
export const selectAccessoryHistoryCount = (state) =>
  state.assetAllocation.accessoryHistory.totalCount;
export const selectIsAccessoryHistoryPagination = (state) =>
  state.assetAllocation.accessoryHistory.isPagination;

export const selectAssetAccessories = (state) =>
  state.assetAllocation.assetAccessories.data;
export const selectAssetAccessoryCount = (state) =>
  state.assetAllocation.assetAccessories.totalCount;
export const selectIsAssetAccessoryPagination = (state) =>
  state.assetAllocation.assetAccessories.isPagination;

export const selectAvailableTags = (state) =>
  state.assetAllocation.availableTags.data;

export const selectAccessoriesStageStatus = (state) =>
  state.assetAllocation.accessoriesStageStatus.data;

export const selectAccessoryHistorySerialNo = (state) =>
  state.assetAllocation.accessoryHistorySerialNo.data;
export const selectAccessoryHistorySerialNoCount = (state) =>
  state.assetAllocation.accessoryHistorySerialNo.totalCount;
export const selectIsAccessoryHistorySerialNoPagination = (state) =>
  state.assetAllocation.accessoryHistorySerialNo.isPagination;

export default assetAllocationSlice.reducer;
