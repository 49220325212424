import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form } from "react-bootstrap";

import { getDevices } from "../../../../../../../state/redux/assetSetting/assetSettingSlice";
import { initFetchParams } from "../../../../../../../state/redux/util";
import CustomSelect from "../../../../../../utils/CustomSelect";

const initialDeviceFilterData = {
  search: "",
  searchBy: "",
};
const DeviceFilter = () => {
  const dispatch = useDispatch();
  const [deviceFilterData, setDeviceFilterData] = useState(
    initialDeviceFilterData
  );
  const handleDeviceFilterFormChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;
    setDeviceFilterData((dd) => ({ ...dd, [name]: value }));
  };
  const handleDeviceFilterFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    dispatch(getDevices({ ...initFetchParams, ...deviceFilterData }));
  };

  return (
    <>
      <Form className="filterForm " onSubmit={handleDeviceFilterFormSubmit}>
        <Form.Group className="" controlId="formDeviceSearchType">
          <Form.Label className="">Search By</Form.Label>
          <CustomSelect
            className="modal__form__select"
            options={[
              {
                value: "",
                label: "--select--",
              },
              {
                value: "deviceId",
                label: "Device Name",
              },
            ]}
            name="searchBy"
            onChange={handleDeviceFilterFormChange}
          />
        </Form.Group>
        <Form.Group className="filterForm__input-wrapper">
          <Form.Label>Enter Search</Form.Label>
          <Form.Control
            placeholder="type to search..."
            type="text"
            value={deviceFilterData.search}
            name="search"
            onChange={handleDeviceFilterFormChange}
          />
        </Form.Group>

        <button className="filterForm__submit-btn">Apply</button>
      </Form>
    </>
  );
};

export default DeviceFilter;
