import { useState } from "react";
import { Form } from "react-bootstrap";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const ShowHidePassword = ({
  value,
  onChange,
  placeholder,
  minLength,
  name,
  top,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const onToggle = () => {
    setIsVisible(!isVisible);
  };
  return (
    <>
      <Form.Control
        required
        type={isVisible ? "text" : "password"}
        placeholder={placeholder}
        name={name}
        minLength={minLength}
        pattern="^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$"
        value={value}
        onChange={onChange}
      />
      <span
        onClick={onToggle}
        className="password-icon-wrapper"
        style={top ? { top } : null}
      >
        {isVisible ? (
          <AiFillEye className="password-icon" />
        ) : (
          <AiFillEyeInvisible className="password-icon" />
        )}
      </span>
    </>
  );
};
export default ShowHidePassword;
/**
 *   <Form.Control
          required
          type="password"
          placeholder="confrim password"
          name="confirmPassword"
          minLength="30" //this will get removed when the passwords match
          value={data.confirmPassword}
          onChange={fns.onChange}
        />
 */
