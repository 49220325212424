import agent from "superagent";

// const domain = "http://192.168.1.208:8991/api";
// const domain = "http://localhost:8991/api";
// const domain = `http://3.110.99.232:8991/api`;
const domain = `https://factorylinebeta.lynkid.io/api`;
// const domain = `http://192.168.1.99:8991/api`;

// const domain = `/api`;
export const socketDomain = `/` ;

export const post = (uri, payload) =>
  new Promise((resolve, reject) => {
    agent
      .post(domain + uri)
      .send(payload)
      .set("Accept", "application/json")
      .set("Authorization", `Bearer ${localStorage.getItem("secretkey")}`)
      .end(withPromiseCallback(resolve, reject));
  });

export const get = (uri) =>
  new Promise((resolve, reject) => {
    agent
      .get(domain + uri)
      .set("Accept", "application/json")
      .set("Authorization", localStorage.getItem("secretkey"))
      .end(withPromiseCallback(resolve, reject));
  });

export const put = (uri, payload) =>
  new Promise((resolve, reject) => {
    agent
      .put(domain + uri)
      .send(payload)
      .set("Accept", "application/json")
      .set("Authorization", localStorage.getItem("secretkey"))
      .end(withPromiseCallback(resolve, reject));
  });

export const deleteRequest = (uri, payload) =>
  new Promise((resolve, reject) => {
    agent
      .delete(domain + uri)
      .send(payload)
      .set("Accept", "application/json")
      .set("Authorization", localStorage.getItem("secretkey"))
      .end(withPromiseCallback(resolve, reject));
  });

export const withPromiseCallback = (resolve, reject) => (error, response) => {
  if (error) {
    console.error(error);
    reject({ error });
  } else {
    resolve(response.body);
  }
};
