import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../services/requests";
import { showToasts } from "../../../views/utils/toasts";
import { initFetchParams } from "../util";

export const addTag = createAsyncThunk(
  "assetSetting/addTag",
  async (data, { dispatch }) => {
    const body = {
      tagNo: data.name,
      tagId: data.id,
      tagType: data.tagType,
    };
    const res = await post("/tags/add-tag", body);
    if (!res.error) dispatch(getTags(initFetchParams));
    return res;
  }
);
export const getTags = createAsyncThunk(
  "assetSetting/getTags",
  async (data) => {
    // console.log(data);
    const body = {
      page_no: data.pageNum,
      limit: data.limit,
      status: data.status || "",
      tagType: data.tagType || "",
      search: data.search || "",
      searchBy: data.searchBy || "",
    };

    const res = await post("/tags/get-tags", body);

    return res;
  }
);

export const addDevice = createAsyncThunk(
  "assetSetting/addDevice",
  async (data, { dispatch }) => {
    const body = {
      stageId: data.stageId,
      deviceId: data.id,
      deviceType: data.deviceType,
    };

    const res = await post("/devices/add-device", body);
    if (!res.error) dispatch(getDevices(initFetchParams));

    return res;
  }
);
export const getDevices = createAsyncThunk(
  "assetSetting/getDevices",
  async (data) => {
    const body = {
      page_no: data.pageNum,
      limit: data.limit,
      search: data.search || "",
      searchBy: data.searchBy || "",
    };
    const res = await post("/devices/get-devices", body);
    return res;
  }
);

export const assetSettingSlice = createSlice({
  name: "assetSetting",
  initialState: {
    tags: {
      data: [],
      loading: false,
      error: "",
      totalCount: 0,
      isPagination: false,
    },
    devices: {
      data: [],
      loading: false,
      error: "",
      totalCount: 0,
      isPagination: false,
    },
  },

  reducers: {},

  extraReducers(builder) {
    builder.addCase(addTag.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
    builder.addCase(getTags.fulfilled, (state, action) => {
      state.tags.data = action.payload.data || [];
      state.tags.totalCount = Number(action.payload.count_all);
      state.tags.isPagination = action.payload.count_all > 10;
    });
    builder.addCase(addDevice.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
    builder.addCase(getDevices.fulfilled, (state, action) => {
      state.devices.data = action.payload.data || [];
      state.devices.totalCount = Number(action.payload.count_all);
      state.devices.isPagination = action.payload.count_all > 10;
    });
  },
});

export const selectTags = (state) => state.assetSetting.tags.data;
export const selectTagCount = (state) => state.assetSetting.tags.totalCount;
export const selectIsTagPagination = (state) =>
  state.assetSetting.tags.isPagination;
export const selectDevices = (state) => state.assetSetting.devices.data;
export const selectDeviceCount = (state) =>
  state.assetSetting.devices.totalCount;
export const selectIsDevicePagination = (state) =>
  state.assetSetting.devices.isPagination;
export default assetSettingSlice.reducer;
