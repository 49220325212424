import React, { useEffect, useState, useMemo } from "react";
import { BiReset, BiSearch } from "react-icons/bi";
import { Collapse, Form } from "react-bootstrap";
import {
  BsArrowRight,
  BsFillCaretDownFill,
  BsFillCaretUpFill,
} from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  getLinesData,
  selectLinesData,
} from "../../state/redux/adminDashboard/adminDashboardSlice";
import {
  getAssets,
  searchBySerialNo,
  selectAssets,
} from "../../state/redux/assetAllocation/assetAllocationSlice";
import { formatDates } from "../../services/functions";
import { getOptions } from "../../services/functions";
import CustomSelect from "../utils/CustomSelect";
import { initFetchParams } from "../../state/redux/util";
import { showToasts } from "../utils/toasts";

const initialFilterData = {
  startDate: "",
  endDate: "",
  search: "",
  serialNo: "",
};

const AdminDashboard = ({ handleStageCardClick }) => {
  const dispatch = useDispatch();

  let linesData = useSelector(selectLinesData);
  const assets = useSelector(selectAssets);

  const assetOptions = useMemo(() => getOptions(assets));
  const stagesInLines = linesData.map((line) => {
    if (line.subLine) {
      return line.subLine.filter((subline) => subline.lineType === "stage");
    }
  });

  // console.log("stageInLine", stagesInLines);
  const [linesToOpen, setLinesToOpen] = useState({});
  const [filterData, setFilterData] = useState(initialFilterData);
  const [highlight, setHighlight] = useState({});

  const handleLineDropdown = (e) => {
    const id =
      e.target.closest(".adminDashboard__stages-container")?.dataset.stageid ||
      e.target.closest(".adminDashboard__subline-container")?.dataset
        .sublineid ||
      e.target.closest(".adminDashboard__line-container").dataset.lineid;
    // console.log(id);

    setLinesToOpen((old) => ({ ...old, [id]: old[id] ? "" : id }));
  };

  const handleFilterFormChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;

    //this case is specific, we need 'label' name instead of 'value' i.e the id of the selected option
    // so we take e.label (instead of the usual e.value)
    const value = actionMeta ? e.label : e.target.value;
    setFilterData((fd) => ({ ...fd, [name]: value }));
  };
  const handleFilterFormSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const body = formatDates(filterData);
    // searchByMasterAsset(body.search);
    // console.log(body);
    dispatch(getLinesData(body));
  };
  // useEffect(() => {
  //   console.log("linesToOpen", linesToOpen);
  // }, [linesToOpen]);
  const handleMasterAssetSearch = (selectedOption, actionMeta) => {
    // console.log(value, actionMeta);
    const { value, label } = selectedOption;
    setHighlight({});
    searchByMasterAsset(label);
  };
  const searchByMasterAsset = (name) => {
    function getId(data, id) {
      const res = data.filter((child) => {
        return child.subLine.find((sLine) => sLine._id === id);
      })[0]?._id;
      //   console.log('res', res)
      return res;
    }
    // function isNoAssets(data) {
    //   if (data.every((c) => c.stageData)) {
    //     // console.log(data);
    //     const res = data.every((item) => {
    //       // console.log(item.stageData?.asset?.join("").length);
    //       return !item.stageData?.asset?.join("").length;
    //     });
    //     console.log(res);
    //     return res;
    //   }
    //   // console.log(res);
    // }
    function search(data, item) {
      if (item) {
        // if (isNoAssets(data)) {
        //   //show error
        //   console.log("err");
        //   showToasts(
        //     { error: true },
        //     {
        //       errorMessage: "Search Error: Asset not available on any stage",
        //     }
        //   );
        //   setLinesToOpen({});
        //   setHighlight({});
        // }
        for (let child of data) {
          // let isAsset = false;
          if (child.stageData) {
            let resName = child.stageData.asset.find(
              (assetName) => assetName.toLowerCase() === item.toLowerCase()
            );
            // console.log(child.stageData);
            // console.log(resName);
            if (resName) {
              // isAsset = true;
              const lineId = getId(linesData, child.parentId);
              const parentId = child.parentId;
              let stgOpen;
              // console.log({ lineId });
              // console.log({ parentId });
              // console.log(resName);

              if (!lineId) stgOpen = `${parentId}-stages`;
              setLinesToOpen({
                [lineId]: lineId,
                [parentId]: parentId,
                [stgOpen]: stgOpen,
              });
              setHighlight({ [child._id]: child._id });
            }
          } else if (child.subLine?.length) {
            search(child.subLine, item);
          }
          // console.log(isAsset);
        }
      }
    }
    search(linesData, name);
  };

  const handleSerialNoSearch = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const serialNo = filterData.serialNo;
    // console.log(serialNo);
    dispatch(searchBySerialNo({ serialNo, ...initFetchParams })).then((res) => {
      const { error, data, message } = res.payload;
      // console.log({ data });
      if (!error) {
        const highlightData = data.reduce((res, item) => {
          res[0] = {
            ...res[0],
            [item.parentId]: item.parentId,
            [`${item.parentId}-stages`]: `${item.parentId}-stages`,
          };
          res[1] = { ...res[1], [item.stageId]: item.stageId };
          return res;
        }, []);
        // console.log(highlightData[0], highlightData[1]);
        setLinesToOpen(highlightData[0]);
        setHighlight(highlightData[1]);
      }
    });
  };

  const fetchData = async () => {
    dispatch(getLinesData()).then((res) => {
      const firstLineId = res.payload.data[0]?._id;
      const areSubLines =
        res.payload.data[0]?.subLine.filter(
          (child) => child.lineType === "subLine"
        ).length > 0;

      const firstChildId = areSubLines
        ? res.payload.data[0]?.subLine[0]?._id
        : `${firstLineId}-stages`;

      // console.log(areSubLines, firstChildId);
      res.payload.data &&
        setLinesToOpen({
          [firstLineId]: firstLineId,
          [firstChildId]: firstChildId,
        });
    });
    dispatch(getAssets({}));
  };
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <span
        className="pAbs adminDashboard__refreshBtn"
        onClick={(e) => {
          e.stopPropagation();
          fetchData();
        }}
        title="refresh data"
      >
        <BiReset className="adminDashboard__refreshBtn-icon" />
      </span>

      <div className=" adminDashboard__filter-wrapper">
        <Form className="mt-0 filterForm " onSubmit={handleFilterFormSubmit}>
          <Form.Group className="filterForm__input-wrapper" id="formStartDate">
            <Form.Label>From</Form.Label>
            <Form.Control
              type="datetime-local"
              name="startDate"
              value={filterData.startDate}
              onChange={handleFilterFormChange}
            />
          </Form.Group>
          <Form.Group className="filterForm__input-wrapper" id="formEndDate">
            <Form.Label>To</Form.Label>
            <Form.Control
              type="datetime-local"
              name="endDate"
              value={filterData.endDate}
              onChange={handleFilterFormChange}
            />
          </Form.Group>
          <button className="filterForm__submit-btn">Apply</button>
          <Form.Group className="filterForm__input-wrapper">
            <Form.Label id="formLine">Master Asset</Form.Label>
            <CustomSelect
              className="modal__form__select"
              options={[
                {
                  value: "",
                  label: "--select--",
                  // isDisabled: true,
                },
                ...assetOptions,
              ]}
              name="search"
              onChange={handleMasterAssetSearch}
            />

            {/* <Form.Control
              placeholder="Search by master asset"
              type="text"
              name="search"
              value={filterData.search}
            /> */}
          </Form.Group>
          <Form.Group className="filterForm__input-wrapper pRel">
            <Form.Label id="formSerialNo">Serial No.</Form.Label>
            {/* <CustomSelect
              className="modal__form__select"
              options={[
                {
                  value: "",
                  label: "--select--",
                  isDisabled: true,
                },
                ...assetOptions,
              ]}
              name="search"
              onChange={handleFilterFormChange}
            /> */}

            <Form.Control
              placeholder="Search by serial no."
              type="text"
              name="serialNo"
              value={filterData.serialNo}
              onChange={handleFilterFormChange}
              className="serialNo_search_input"
            />
            <BiSearch
              style={{
                height: "20px",
                width: "20px",
                cursor: "pointer",
                position: "absolute",
                top: "35px",
                right: "6px",
              }}
              onClick={handleSerialNoSearch}
            />
          </Form.Group>
        </Form>
      </div>

      {linesData && linesData.length > 0 ? (
        linesData.map((line, index) => {
          return (
            <div
              className="adminDashboard__line-container cursor-pointer"
              key={index}
              data-lineid={line._id}
              onClick={handleLineDropdown}
            >
              <div className="adminDashboard__heading ">
                Line: <span className="dashboard__lineName">{line.name}</span>
              </div>
              {linesToOpen[line._id] ? (
                <span className="adminDashboard__dropdown-icon-wrapper">
                  <BsFillCaretUpFill
                    className="icons adminDashboard__dropdown-icon"
                    // onClick={handleLineDropdown}
                  />
                </span>
              ) : (
                <span className="adminDashboard__dropdown-icon-wrapper">
                  <BsFillCaretDownFill
                    className="icons adminDashboard__dropdown-icon"
                    // onClick={handleLineDropdown}
                  />
                </span>
              )}
              <div
                className="adminDashboard__subline-wrapper cursor-default"
                onClick={(e) => e.stopPropagation()}
              >
                <Collapse in={linesToOpen[line._id] ? true : false}>
                  <div>
                    {line.subLine &&
                      line.subLine.map((subline, index) => {
                        if (subline.lineType === "subLine")
                          return (
                            <div
                              className="adminDashboard__subline-container pRel cursor-pointer"
                              key={index}
                              onClick={(e) => {
                                handleLineDropdown(e);
                              }}
                              data-sublineid={subline._id}
                            >
                              <div className="adminDashboard__subline-heading">
                                SubLine :{" "}
                                <span className="dashboard__subLineName">
                                  {subline.name}
                                </span>
                                {linesToOpen[subline._id] ? (
                                  <span className="sublineContainer-icon-wrapper">
                                    <BsFillCaretUpFill
                                      className="icons sublineContainer__dropdown-icon"
                                      // onClick={handleLineDropdown}
                                    />
                                  </span>
                                ) : (
                                  <span className="sublineContainer-icon-wrapper">
                                    <BsFillCaretDownFill
                                      className="icons sublineContainer__dropdown-icon"
                                      // onClick={handleLineDropdown}
                                    />
                                  </span>
                                )}
                              </div>

                              <Collapse
                                in={linesToOpen[subline._id] ? true : false}
                              >
                                <div>
                                  <div
                                    className="subline__stageWrapper cursor-default"
                                    onClick={(e) => e.stopPropagation()}
                                  >
                                    <div
                                      className="stage__container"
                                      key={index}
                                    >
                                      {subline.subLine &&
                                      subline.subLine.length > 0
                                        ? subline.subLine.map(
                                            (stage, index) => {
                                              const assets = [
                                                ...new Set(
                                                  stage.stageData?.asset
                                                ),
                                              ]
                                                .filter((item) => item.length)
                                                .join(",");
                                              // console.log(assets);
                                              return (
                                                <React.Fragment key={index}>
                                                  <div
                                                    className={`adminDashboard__subline-card ${
                                                      highlight[stage._id]
                                                        ? "stageHighlight"
                                                        : ""
                                                    }`}
                                                    onClick={
                                                      handleStageCardClick
                                                    }
                                                    data-stageid={stage._id}
                                                    data-templateid={
                                                      stage.templateId
                                                    }
                                                    data-parentid={
                                                      stage.parentId
                                                    }
                                                  >
                                                    <div className="subline-card__heading">
                                                      {/* <div className="subline-card__userName">
                                                        User :
                                                        <span>
                                                          <AiOutlineUser /> Aman
                                                        </span>
                                                      </div> */}
                                                      <div className="subline-card__stageName">
                                                        Name :
                                                        <span>
                                                          {stage.name}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    {stage.stageData ? (
                                                      <div>
                                                        <span
                                                          className="subline-card__assetName "
                                                          title={assets}
                                                        >
                                                          {assets.length
                                                            ? assets
                                                            : "No Assets"}
                                                        </span>
                                                      </div>
                                                    ) : (
                                                      <span className="font-weight-bold">
                                                        No Assets Available
                                                      </span>
                                                    )}
                                                    <div className="subline-card__stats">
                                                      <div className="subline-card__completed">
                                                        Completed:
                                                        <span>
                                                          {stage.stageData
                                                            ?.completed || "0"}
                                                        </span>
                                                      </div>
                                                      <div className="subline-card__completed">
                                                        Current:
                                                        <span>
                                                          {stage.stageData?.currentCount || "0"}
                                                        </span>
                                                      </div>
                                                      {/* <div className="subline-card__pending">
                                                        Pending:
                                                        <span>
                                                          {stage.stageData
                                                            ?.pending || "0"}
                                                        </span>
                                                      </div> */}
                                                      <div className="subline-card__missed">
                                                        Previous Missed:
                                                        <span>
                                                          {stage.stageData
                                                            ?.previousMissed ||
                                                            "0"}
                                                        </span>
                                                      </div>
                                                      <div className="subline-card__missed">
                                                        Missed:
                                                        <span>
                                                          {stage.stageData?.total_missed || "0"}
                                                        </span>
                                                    </div>
                                                    </div>
                                                  </div>
                                                  {index !==
                                                    subline.subLine.length -
                                                      1 && (
                                                    <div className="right_arrow_wrapper">
                                                      <BsArrowRight className="right_arrow-icon" />
                                                    </div>
                                                  )}
                                                </React.Fragment>
                                              );
                                            }
                                          )
                                        : "There are no stages on this sub line"}
                                    </div>
                                  </div>
                                </div>
                              </Collapse>
                            </div>
                          );
                      })}
                    {stagesInLines[index] && stagesInLines[index].length ? (
                      <div
                        className="adminDashboard__stages-container pRel cursor-pointer"
                        data-stageid={`${line._id}-stages`}
                        onClick={handleLineDropdown}
                      >
                        <div className="adminDashboard__subline-heading">
                          Stages For Line : {line.name}
                          {linesToOpen[`${line._id}-stages`] ? (
                            <span className="stagesContainer-icon-wrapper">
                              <BsFillCaretUpFill
                                className="icons stagesContainer__dropdown-icon"
                                // onClick={handleLineDropdown}
                              />
                            </span>
                          ) : (
                            <span className="stagesContainer-icon-wrapper">
                              <BsFillCaretDownFill
                                className="icons stagesContainer__dropdown-icon"
                                // onClick={handleLineDropdown}
                              />
                            </span>
                          )}
                        </div>
                        <Collapse
                          in={linesToOpen[`${line._id}-stages`] ? true : false}
                        >
                          <div>
                            <div
                              className="stages_wrapper cursor-default"
                              onClick={(e) => e.stopPropagation()}
                            >
                              <div className="stage__container ">
                                {stagesInLines[index].map((stage, stgIndex) => {
                                  const assets = [
                                    ...new Set(stage.stageData?.asset),
                                  ]
                                    .filter((item) => item.length)
                                    .join(",");
                                  // console.log(assets);
                                  // console.log(stage.stageData);
                                  return (
                                    <React.Fragment key={stgIndex}>
                                      <div
                                        className={`adminDashboard__subline-card pb-2 ${
                                          highlight[stage._id]
                                            ? "stageHighlight"
                                            : ""
                                        }`}
                                        onClick={handleStageCardClick}
                                        data-stageid={stage._id}
                                        data-templateid={stage.templateId}
                                        data-parentid={stage.parentId}
                                      >
                                        <div className="subline-card__heading">
                                          {/* <div className="subline-card__userName">
                                            User :
                                            <span>
                                              <AiOutlineUser /> Aman
                                            </span>
                                          </div> */}
                                          <div className="subline-card__stageName">
                                            Name : <span>{stage.name}</span>
                                          </div>
                                        </div>
                                        {stage.stageData ? (
                                          <div>
                                            <span
                                              className="subline-card__assetName "
                                              title={assets}
                                            >
                                              {assets.length
                                                ? assets
                                                : "No Assets"}
                                            </span>
                                          </div>
                                        ) : (
                                          <span className="font-weight-bold">
                                            No Assets Available
                                          </span>
                                        )}
                                        <div className="subline-card__stats">
                                          <div className="subline-card__completed">
                                            Completed:
                                            <span>
                                              {stage.stageData?.completed ||
                                                "0"}
                                            </span>
                                          </div>
                                          
                                          <div className="subline-card__completed">
                                            Current:
                                            <span>
                                              {stage.stageData?.currentCount || "0"}
                                            </span>
                                          </div>
                                          {/* <div className="subline-card__pending">
                                                        Pending:
                                                        <span>
                                                          {stage.stageData
                                                            ?.pending || "0"}
                                                        </span>
                                                      </div> */}
                                          <div className="subline-card__missed">
                                            Previous Missed:
                                            <span>
                                              {stage.stageData?.previousMissed || "0"}
                                            </span>
                                          </div>
                                          <div className="subline-card__missed">
                                            Missed:
                                            <span>
                                              {stage.stageData?.total_missed || "0"}
                                            </span>
                                          </div>

                                        </div>
                                      </div>
                                      {stgIndex !==
                                        stagesInLines[index].length - 1 && (
                                        <div className="right_arrow_wrapper">
                                          <BsArrowRight className="right_arrow-icon" />
                                        </div>
                                      )}
                                    </React.Fragment>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </Collapse>
                      </div>
                    ) : null}
                  </div>
                </Collapse>
              </div>
            </div>
          );
        })
      ) : (
        <div className="adminDashboard__line-container">
          <div className="adminDashboard__heading">No Data to show</div>
        </div>
      )}
    </>
  );
};
export default AdminDashboard;
