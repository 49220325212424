import React, { useState } from "react";
import {
  AiFillPlusCircle,
  AiFillCloseCircle,
  AiOutlineCheck,
  AiOutlineClose,
} from "react-icons/ai";
import {
  getLinesData,
  getStageAssets,
  getTemplateDetail,
  saveTemplateData,
} from "../../state/redux/adminDashboard/adminDashboardSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import GenericModal from "./Modals/GenericModal";
import { Form, Modal, Table, Button } from "react-bootstrap";
import { useRef } from "react";
import { toast } from "react-toastify";
import { getUserData } from "../../services/functions";
import AdminDashboard from "./AdminDashboard";
import StageManagerDashboard from "./StageManagerDashboard";
import CustomSelect from "../utils/CustomSelect";
import {
  getAccessoriesStageStatus,
  selectAccessoriesStageStatus,
} from "../../state/redux/assetAllocation/assetAllocationSlice";
import moment from "moment";

const initialStageAssetData = {
  assets: [],
  templateId: "",
  stageId: "",
  assetId: "",
};
const initialTemplateFormData = {
  attributes: [],
};

const UserDashboard = () => {
  const dispatch = useDispatch();

  const [stageAssetData, setStageAssetData] = useState(initialStageAssetData); 

  const templateFormRef = useRef(null);
  const [templateFormData, setTemplateFormData] = useState(
    initialTemplateFormData
  );

  const [isStageAssetModal, setIsStageAssetModal] = useState(false);
  const [isTemplateFormModal, setIsTemplateFormModal] = useState(false);

  const { userType } = getUserData();
  const handleStageCardClick = (e) => {
    const stageId = e.target.closest(".adminDashboard__subline-card").dataset
      .stageid;
    const parentId = e.target.closest(".adminDashboard__subline-card").dataset
      .parentid;

    const templateId = e.target.closest(".adminDashboard__subline-card").dataset
      .templateid;
    fetchAndSetStageData(stageId, parentId, "current");
  };
  const handleStageCardFormChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;
    // console.log(name, value);
    //set select validations
    if (name === "status") {
      fetchAndSetStageData(
        stageAssetData.stageId,
        stageAssetData.parentId,
        value
      );
      setStageAssetData((old) => ({ ...old, [name]: e.label }));
    }

    setStageAssetData((oldData) => ({ ...oldData, [name]: value }));
  };
  function fetchAndSetStageData(stageId, parentId, status) {
    dispatch(getStageAssets({ stageId, parentId, status })).then((res) => {
      // console.log(res.payload.data);
      setStageAssetData((oldData) => ({
        ...oldData,
        assets: res.payload.data || [],
        // templateId,
        parentId,
        stageId,
        status: status === "current" ? "Active" : status,
      }));
      setIsStageAssetModal(true);
    });
  }

  const handleAddTemplateForm = (e) => {
    const assetId = e.target.closest(".stageAssets-row").dataset.assetid;
    setStageAssetData((oldData) => ({ ...oldData, assetId }));
    const templateId = stageAssetData.templateId;
    dispatch(getTemplateDetail(templateId)).then((res) => {
      setTemplateFormData(res.payload.data || []);
      setIsStageAssetModal(false);
      setIsTemplateFormModal(true);
    });
  };
  useEffect(() => {
    const templateForm = templateFormRef.current;
    if (isTemplateFormModal) {
      const handleCancel = () => setIsTemplateFormModal(false);
      const handleSubmit = async (e) => {
        const data = e.detail;
        const body = {
          template_id: stageAssetData.templateId,
          assetId: stageAssetData.assetId,
          stageId: stageAssetData.stageId,
          data,
        };

        await dispatch(saveTemplateData(body));
        setIsTemplateFormModal(false);
      };
      const handleError = (e) => {
        toast.error(e.detail.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      };
      templateForm.addEventListener("cancelForm", handleCancel);
      templateForm.addEventListener("errorTemplate", handleError);
      templateForm.addEventListener("formSubmit", handleSubmit);
    }
  }, [isTemplateFormModal]);

  return (
    <div className="adminDashboard outer-wrapper">
      {isTemplateFormModal ? (
        <>
          <Modal
            show={isTemplateFormModal}
            onHide={() => setIsTemplateFormModal(false)}
            centered
            className="generic-modal"
            scrollable
          >
            <Modal.Header>
              <Modal.Title>Template Form</Modal.Title>
              <span className="generic-modal__close-icon--wrapper">
                <AiFillCloseCircle
                  className="generic-modal__close-icon"
                  onClick={() => setIsTemplateFormModal(false)}
                />
              </span>
            </Modal.Header>
            <Modal.Body>
              {templateFormData.attributes &&
              templateFormData.attributes.length ? (
                <template-form
                  ref={templateFormRef}
                  attributes={JSON.stringify(templateFormData.attributes)}
                ></template-form>
              ) : null}
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </>
      ) : null}
      {isStageAssetModal ? (
        <GenericModal
          size="lg"
          isModal={isStageAssetModal}
          handleClose={() => setIsStageAssetModal(false)}
          title="Asset List"
          body={AddStageAssetBody}
          data={stageAssetData}
          modalInputflexBasis="30%"
          fns={{
            onChange: handleStageCardFormChange,
            onSubmit: () => {},
            handleAddTemplateForm,
          }}
          noBtn
        />
      ) : null}

      {userType === "StageManager" ? (
        <StageManagerDashboard handleAddTemplateForm={handleAddTemplateForm} />
      ) : (
        <AdminDashboard handleStageCardClick={handleStageCardClick} />
      )}
    </div>
  );
};

export default UserDashboard;

function AddStageAssetBody(data, fns) {
  const dispatch = useDispatch();
  const [modal, setModal] = useState({});

  const handleModalClose = () => {
    setModal({});
  };
  const handleModalOpen = (id) => {
    // console.log(data);
    dispatch(
      getAccessoriesStageStatus({ assetId: id, stageId: data.stageId })
    ).then((res) => {
      setModal({ [id]: id });
    });
  };
  return (
    <Form
      className="modal__form pRel m-0"
      noValidate
      id="modal__form"
      onSubmit={fns.onSubmit}
      style={{ gap: "1em" }}
    >
      <Form.Group
        className=""
        controlId="formStatus"
        style={{
          flexGrow: "0",
          marginLeft: "auto",
        }}
      >
        <Form.Label>Status</Form.Label>
        <CustomSelect
          className="modal__form__select"
          options={[
            {
              value: "",
              label: "--select status--",
              isDisabled: true,
            },
            {
              value: "current",
              label: "Active",
            },
            {
              value: "completed",
              label: "Completed",
            },
            {
              value: "missed",
              label: "Missed",
            },
          ]}
          name="status"
          // value={{label: data.status, value: data.status}}
          defaultValue={{
            value: "current",
            label: "Active",
          }}
          onChange={fns.onChange}
        />
      </Form.Group>
      <Table>
        <thead>
          <tr className="text-center">
            <th>#</th>
            <th>Master Asset</th>
            <th>Description</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.assets?.length > 0 ? (
            data.assets.map((asset, index) => {
              // console.log(data);
              // console.log(asset);
              return (
                <tr
                  key={index}
                  className="stageAssets-row pRel"
                  data-assetid={asset._id}
                  style={
                    data.status === "Active" && !asset.previousStage
                      ? {
                          backgroundColor: "rgb(238, 50, 50)",
                          color: "#fff",
                          textAlign: "center",
                        }
                      : { textAlign: "center" }
                  }
                >
                  <td>{index + 1}</td>
                  <td>{asset.name}</td>
                  <td>{asset.description}</td>
                  <td>
                    {/* {asset.previousStage ? (
                      <span className="stageAssets__prevStage-passed">
                        Passed
                      </span>
                    ) : (
                      <span className="stageAssets__prevStage-missed">
                        Missed
                      </span>
                    )} */}
                    {/* {asset.status} */}
                    {data.status[0]
                      .toUpperCase()
                      .concat(data.status.split("").slice(1).join(""))}
                  </td>
                  <td>
                    <button
                      type="button"
                      className="view_components_btn"
                      onClick={() => handleModalOpen(asset._id)}
                    >
                      View Components
                    </button>
                  </td>
                  {modal[asset._id] && (
                    <ScanAccessoryModal
                      isModal={modal[asset._id] ? true : false}
                      handleClose={handleModalClose}
                    />
                  )}
                  {/* <td>
                    {asset.dataSubmitted ? (
                      <span className="stageAssets__status-completed">
                        Completed
                      </span>
                    ) : (
                      <span className="stageAssets__status-pending">
                        Pending
                        <AiFillPlusCircle
                          className="icons addForm-icon"
                          onClick={fns.handleAddTemplateForm}
                        />
                      </span>
                    )}
                    'NA'
                  </td> */}
                </tr>
              );
            })
          ) : (
            <tr className="text-center">
              <td colSpan={100}>No Assets Found</td>
            </tr>
          )}
        </tbody>
      </Table>
    </Form>
  );
}

function ScanAccessoryModal({ isModal, handleClose }) {
  const tableData = useSelector(selectAccessoriesStageStatus);
  // console.log(tableData);

  function closeModal() {
    handleClose();
  }
  return (
    <>
      <Modal
        show={isModal}
        onHide={closeModal}
        centered
        size="lg"
        className="generic-modal"
        id="something"
        scrollable
      >
        <Modal.Header>
          <Modal.Title>Map Components to Asset</Modal.Title>
          <span className="generic-modal__close-icon--wrapper">
            <AiFillCloseCircle
              className="generic-modal__close-icon"
              onClick={closeModal}
            />
          </span>
        </Modal.Header>
        <Modal.Body>
          {/* <input
            type="text"
            ref={inputRef}
            style={{ opacity: "0", position: "absolute" }}
            onChange={(e) => setCode(e.target.value)}
            value={code}
          /> */}

          {/* <button
            type="button"
            className="scanAccessory-btn2"
            onClick={focusInput}
          >
            Start Scanning
          </button> */}

          <div className="generic-table-wrapper mt-4 scan_accessories_table">
            <Table className="generic-table mt-0">
              <thead className="bg-accent">
                <tr className="text-center">
                  <th>S.No</th>
                  <th>Component</th>
                  <th>Unique ID</th>
                  <th>Status</th>
                  <th>Scan Time</th>
                </tr>
              </thead>
              <tbody className="bg-gray-100">
                {tableData.length > 0 ? (
                  tableData.map((item, index) => {
                    return (
                      <tr key={index} className="text-center">
                        <td>{index + 1}</td>
                        <td>{item.accessoryName}</td>
                        <td>{item.serialNo}</td>
                        <td>
                          <span className="accessory_status">
                            {item.status ? (
                              <AiOutlineCheck className="accessory_status-icon scan_green" />
                            ) : (
                              <AiOutlineClose className="accessory_status-icon scan_red" />
                            )}
                          </span>
                        </td>
                        <td>
                          {item.scanTime
                            ? moment(item.scanTime).format(
                                "DD/MM/YYYY, h:mm:ss a"
                              )
                            : "NA"}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="text-center">
                    <td colSpan={100}>No Data Found</td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={closeModal} className="generic-modal__btn">
            Close
          </Button>
          {/* <Button
            className="generic-modal__btn"
            type="button"
            form="modal__form"
            onClick={handleSubmit}
          >
            Save
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
