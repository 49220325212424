import { useSelector } from "react-redux";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import moment from "moment";

import {
  getTags,
  selectIsTagPagination,
  selectTagCount,
  selectTags,
} from "../../../../../../../state/redux/assetSetting/assetSettingSlice";

import CustomPagination from "../../../../../../utils/CustomPagination";
import GenericTable from "../../../../../../utils/GenericTable";

const TagTable = ({ tagFilterData }) => {
  const tags = useSelector(selectTags);
  const tagCount = useSelector(selectTagCount);
  const isTagPagination = useSelector(selectIsTagPagination);

  const tagTableData = tags.map((obj, index) => {
    let objArr = [];
    objArr.id = obj._id;
    objArr.push(
      index + 1,
      obj.tagType,
      obj.tagNo,
      obj.status,
      moment(obj.addedOn).format("DD/MM/YYYY, h:mm a")
      // action(handleTagEdit, handleTagDelete)
    );
    return objArr;
  });

  function action(handleEdit, handleDelete) {
    return (
      <div className="action-icons">
        <AiFillEye className="view-icon icons" onClick={handleEdit} />
        {/* <AiFillEdit
                className="edit-icon icons"
                id={obj._id}
                onClick={handleEdit}
              /> */}
        <AiFillDelete className="delete-icon icons" onClick={handleDelete} />
      </div>
    );
  }
  function handleTagEdit(e) {
    console.log("handleTagEdit", e.target.id);
  }
  function handleTagDelete(e) {
    console.log("handleTagDelete", e.target.id);
  }
  return (
    <>
      <GenericTable
        headings={[
          "S.No",
          "Type",
          "Name",
          "Status",
          "Added On",
          // "Action",
        ]}
        data={tagTableData}
      />
      <CustomPagination
        totalCount={tagCount}
        isPagination={isTagPagination}
        fetchData={getTags}
        extraParams={tagFilterData}
      />
    </>
  );
};

export default TagTable;
