import { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getLineManagers } from "../../../state/redux/lineManager/lineManagerSlice"; 

import { getUserData } from "../../../services/functions";
import { initFetchParams } from "../../../state/redux/util";
import history from "../../../services/history";

import LineManagerPanel from "./components/LineManagerPanel/index";
import LineManagerTable from "./components/LineManagerTable/index";

const LineManager = () => {
  const dispatch = useDispatch();
  const { permissions } = getUserData();

  // get lines and all line managers on component load
  useEffect(() => {
    const fetchData = async () => {
      dispatch(getLineManagers(initFetchParams));
    };
    if (permissions.addLineManager) fetchData();
    else history.push("/dashboard");
  }, []);

  return (
    <>
      <div className="line-manager outer-wrapper">
        <LineManagerPanel isAddUser={permissions.addUser} />
        <LineManagerTable />
      </div>
    </>
  );
};

export default LineManager;
