import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../services/requests";
import { showToasts } from "../../../views/utils/toasts";

export const getAssetReport = createAsyncThunk(
  "report/getAssetReport",
  async (data) => {
    const body = {
      downloadType: "json",
      lineId: data.lineId || "",
      search: data.search || "",
      searchBy: data.searchBy || "",
      startdate: data ? data.startDate : "",
      enddate: data ? data.endDate : "",
      page_no: data.pageNum,
      limit: data.limit,
    };
    const res = await post("/assets/asset-report", body);
    return res;
  }
);
export const getAssetReportBase64 = createAsyncThunk(
  "report/getAssetReportBase64",
  async (data) => {
    const body = {
      downloadType: "excel",
      startdate: data.startDate,
      enddate: data.endDate,
    };

    const res = await post("/assets/asset-report", body);

    return res;
  }
);
export const getSerialReportBase64 = createAsyncThunk(
  "report/getSerialReportBase64",
  async (data) => {
    const body = {
      downloadType: "excel",
      serialNo: data.search,
      startdate: data.startDate,
      enddate: data.endDate,
    };
    const res = await post("/assets/accessory-history-by-serialno", body);
    return res;
  }
);

export const reportSlice = createSlice({
  name: "report",
  initialState: {
    assetReportData: { data: [], totalCount: 0, isPagination: false },
    reportBase64: "",
    serialReportBase64: "",
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getAssetReport.fulfilled, (state, action) => {
      state.assetReportData.data = action.payload.data || [];
      state.assetReportData.totalCount = Number(action.payload.count_all);
      state.assetReportData.isPagination = action.payload.count_all > 10;
      if (action.payload.error) {
        showToasts(action.payload);
      }
    });
    builder.addCase(getAssetReportBase64.fulfilled, (state, action) => {
      state.reportBase64 = action.payload.data || [];
      if (action.payload.error) {
        showToasts(action.payload);
      } else {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${action.payload.data}`;
        const downloadLink = document.createElement("a");
        // console.log(linkSource);
        downloadLink.href = linkSource;
        downloadLink.target = "_blank";
        downloadLink.click();

        showToasts(action.payload, {
          successMessage: "Report Downloaded Successfully!",
        });
      }
    });
    builder.addCase(getSerialReportBase64.fulfilled, (state, action) => {
      state.serialReportBase64 = action.payload.data || [];
      if (action.payload.error) {
        showToasts(action.payload);
      } else {
        const linkSource = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${action.payload.data}`;
        const downloadLink = document.createElement("a");
        // console.log(linkSource);
        downloadLink.href = linkSource;
        downloadLink.target = "_blank";
        downloadLink.click();

        showToasts(action.payload, {
          successMessage: "Report Downloaded Successfully!",
        });
      }
    });
  },
});

export const selectAssetReportData = (state) =>
  state.report.assetReportData.data;
export const selectAssetReportCount = (state) =>
  state.report.assetReportData.totalCount;
export const selectIsAssetReportPagination = (state) =>
  state.report.assetReportData.isPagination;

export default reportSlice.reducer;
