import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../services/requests";
import { showToasts } from "../../../views/utils/toasts";

export const getLines = createAsyncThunk("lines/getLines", async (data) => {
  const body = {
    limit: data ? data.limit : "",
    page_no: data ? data.pageNum : "",
    lineType: "line",
  };
  const res = await post("/lines/get-lines", body);
  return res;
});
export const addLine = createAsyncThunk("lines/addLine", async (data) => {
  const res = await post("/lines/add-line", { name: data.name });
  return res;
});
export const getAllChildren = createAsyncThunk(
  "lines/getAllChildren",
  async (data) => {
    const body = {
      lineId: data.id,
      limit: data.limit,
      page_no: data.pageNum,
    };
    const res = await post("/lines/get-lines", body);
    return res;
  }
);

export const updateLine = createAsyncThunk("lines/updateLine", async (data) => {
  const body = {
    lineId: data.id,
    name: data.name,
  };
  const res = await post("/lines/update-line", body);
  return res;
});

export const linesSlice = createSlice({
  name: "lines",
  initialState: {
    allLines: {
      lines: [],
      loading: false,
      error: "",
      isPagination: false,
      totalCount: 0,
    },
    allChildren: {
      children: [],
      loading: false,
      error: "",
      isPagination: false,
      totalCount: 0,
    },
  },
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getLines.fulfilled, (state, action) => {
      state.allLines.lines = action.payload.data || [];
      state.allLines.totalCount = Number(action.payload.count_all);
      state.allLines.isPagination = action.payload.count_all > 10;
    });
    builder.addCase(addLine.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
    builder.addCase(getAllChildren.fulfilled, (state, action) => {
      state.allChildren.children = action.payload.data || [];
      state.allChildren.totalCount = Number(action.payload.count_all);
      state.allChildren.isPagination = action.payload.count_all > 10;
    });
    builder.addCase(updateLine.fulfilled, (state, action) => {
      showToasts(action.payload);
    });
  },
});
export const selectLines = (state) => state.lines.allLines.lines;
export const selectLineCount = (state) => state.lines.allLines.totalCount;
export const selectIsLinePagination = (state) =>
  state.lines.allLines.isPagination;

export const selectAllChilren = (state) => state.lines.allChildren.children;
export const selectAllChilrenCount = (state) =>
  state.lines.allChildren.totalCount;
export const selectIsAllChilrenPagination = (state) =>
  state.lines.allChildren.isPagination;

export default linesSlice.reducer;
