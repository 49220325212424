import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "react-bootstrap";
import {
  AiFillEye,
  AiFillEdit,
  AiFillDelete,
  AiFillPlusSquare,
} from "react-icons/ai";

import {
  getLines,
  selectLines,
} from "../../../../../state/redux/lines/linesSlice";
import { addLineManager } from "../../../../../state/redux/lineManager/lineManagerSlice";
import { getOptions } from "../../../../../services/functions";
import { validate_form } from "../../../../../services/validation";

import GenericModal from "../../../Modals/GenericModal";
import CustomSelect from "../../../../utils/CustomSelect";
import ShowHidePassword from "../../../../utils/ShowHidePassword";

const initialFormData = {
  name: "",
  email: "",
  lineId: "",
  password: "",
  confirmPassword: "",
  status: "",
  description: "",
  lineOptions: [],
  isFormValidated: false,
  isLineSelectValid: false,
  isStatusSelectValid: false,
  errors: {
    name: "",
    email: "",
    password: "",
    confirmPassword: "",
  },
};

const LineManagerPanel = ({ isAddUser }) => {
  const dispatch = useDispatch();
  const lines = useSelector(selectLines);

  const [formData, setFormData] = useState(initialFormData);
  const [isModal, setIsModal] = useState(false);

  const lineOptions = useMemo(() => getOptions(lines), [lines]);

  const handleModalOpen = () => {
    dispatch(getLines());
    setIsModal(true);
  };
  const handleModalClose = () => {
    setFormData({
      ...initialFormData,
      lineOptions,
    });
    setIsModal(false);
  };

  const handleFormDataChange = (e, actionMeta) => {
    //if react-select input -> event becomes {value: '', label: ''})
    const name = actionMeta ? actionMeta.name : e.target.name;
    const value = actionMeta ? e.value : e.target.value;
    const tempErrors = { ...formData.errors };
    const errors = validate_form({
      tempErrors,
      name,
      value,
      curr_pass: formData.password,
    });

    if (name === "lineId") {
      setFormData((fd) => ({
        ...fd,
        isLineSelectValid: fd.isFormValidated ? true : null,
      }));
    } else if (name === "status") {
      setFormData((fd) => ({
        ...fd,
        isStatusSelectValid: fd.isFormValidated ? true : null,
      }));
    }
    setFormData((oldData) => ({
      ...oldData,
      errors,
      [name]: value,
    }));
  };

  const handleEdit = (e) => {
    console.log("handleEdit", e.target);
  };

  //handle add line manager
  const handleFormSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if (form.checkValidity() === true) {
      try {
        dispatch(addLineManager(formData));
        handleModalClose();
      } catch (err) {}
    } else
      setFormData((fd) => ({
        ...fd,
        isFormValidated: true,
        isLineSelectValid: !!fd.lineId,
        isStatusSelectValid: !!fd.status,
      }));
  };

  useEffect(() => {
    setFormData((fd) => ({ ...fd, lineOptions }));
  }, [lines]);

  return (
    <>
      <div className="panel">
        <div className="panel__heading">
          <h2>Line Manager</h2>
        </div>
        <div className="panel__items">
          {isAddUser ? (
            <button
              className="panel-btn line-manager__add-btn "
              onClick={handleModalOpen}
            >
              Add Manager
              <AiFillPlusSquare className="icons panel__icon" />
            </button>
          ) : null}
        </div>
      </div>

      {isModal ? (
        <GenericModal
          size="lg"
          isModal={isModal}
          handleClose={handleModalClose}
          title="Add New Line Manager"
          body={addLineManagerBody}
          data={formData}
          fns={{
            onChange: handleFormDataChange,
            onSubmit: handleFormSubmit,
          }}
        />
      ) : null}
    </>
  );
};

export default LineManagerPanel;

function addLineManagerBody(data, fns) {
  //   console.log("addManagerModal");
  return (
    <Form
      noValidate
      validated={data.isFormValidated}
      id="modal__form"
      className="modal__form"
      onSubmit={fns.onSubmit}
    >
      <Form.Group className="mb-3" controlId="formName">
        <Form.Label className="required">Name</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="Enter name"
          minLength="60"
          name="name"
          value={data.name}
          onChange={fns.onChange}
        />
        <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        {data.errors.name ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.name}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please enter name
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Label className="required">Email</Form.Label>
        <Form.Control
          required
          type="email"
          placeholder="Enter email"
          name="email"
          minLength="60"
          value={data.email}
          onChange={fns.onChange}
        />
        <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        {data.errors.email ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.email}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please enter email
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formLine">
        <Form.Label className="required"> Select Line</Form.Label>
        <CustomSelect
          isValid={data.isLineSelectValid}
          required
          className="modal__form__select"
          options={[
            {
              value: "",
              label: "--select line--",
              isDisabled: true,
            },
            ...data.lineOptions,
          ]}
          name="lineId"
          // defaultValue={{ label: data.lineId }}
          onChange={fns.onChange}
        />
      </Form.Group>

      <Form.Group className="mb-3 pRel" controlId="formPassword">
        <Form.Label className="required">Password</Form.Label>
        <ShowHidePassword
          value={data.password}
          name="password"
          onChange={fns.onChange}
          placeholder="Enter password"
          minLength="8"
        />

        {data.errors.password ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.password}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please enter password
          </Form.Control.Feedback>
        )}
        {data.errors.password === "" && (
          <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3 pRel" controlId="formConfirmPassword">
        <Form.Label className="required">Confirm Password</Form.Label>
        <ShowHidePassword
          value={data.confirmPassword}
          name="confirmPassword"
          onChange={fns.onChange}
          placeholder="confirm password"
          minLength="30"
        />

        {data.errors.confirmPassword === "" && (
          <Form.Control.Feedback>Good to go!</Form.Control.Feedback>
        )}

        {data.errors.confirmPassword ? (
          <Form.Control.Feedback type="invalid">
            {data.errors.confirmPassword}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            Please confirm the entered password
          </Form.Control.Feedback>
        )}
      </Form.Group>
      <Form.Group className="mb-3" controlId="formStatus">
        <Form.Label className="required">Status</Form.Label>
        <CustomSelect
          isValid={data.isStatusSelectValid}
          required
          className="modal__form__select"
          // styles={genericSelectStyles}
          options={[
            {
              value: "",
              label: "--select status--",
              isDisabled: true,
            },
            {
              value: "Active",
              label: "Active",
            },
            {
              value: "In-Active",
              label: "In-Active",
            },
          ]}
          name="status"
          // value={
          //   data.status && {
          //     value: data.status,
          //     label: data.status,
          //   }
          // }
          onChange={fns.onChange}
        />
      </Form.Group>

      <Form.Group
        className="mb-3 addLineManager__desc"
        controlId="formDescription"
      >
        <Form.Label>Description</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="Add a description here"
          style={{ height: "100px" }}
          name="description"
          value={data.description}
          onChange={fns.onChange}
        />
      </Form.Group>
    </Form>
  );
}
