import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { post } from "../../../services/requests";
import { showToasts } from "../../../views/utils/toasts";
import { initFetchParams } from "../util";


export const getGoalAnalytics = createAsyncThunk(
  "goalAnalytics/get-goal-analytics",
  async (data) => {
    const body = {
    //   unit : data.unit,
    //   duration : data.duration
    };
    const res = await post("/lines/get-goal-analytics", body);
    return res;
  }
);

export const getGoalAnalyticsSlice = createSlice({
    name: "goalAnalytics",
    initialState: {
      goalAnalyticsData: [],
    },
    reducers: {},
    extraReducers(builder) {
      builder.addCase(getGoalAnalytics.fulfilled, (state, action) => {
        state.goalAnalyticsData = action.payload.data || [];
      });
    },
  });


export const goalAnalyticsData = (state) => state.goalAnalytics.goalAnalyticsData;


export default getGoalAnalyticsSlice.reducer;
